import React, { Component } from "react";
import { Row, Col, Skeleton } from "antd";

export class ReportMediaSkeleton extends Component {
  state = {
    active: false,
    size: "default",
    buttonShape: "default",
    avatarShape: "circle",
    colArr: ["1", "2", "3", "4"],
  };

  render() {
    const { buttonShape, colArr } = this.state;
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        {colArr.length > 0 &&
          colArr.map((book) => (
            <Col xs={24} sm={12} lg={8} xl={6} key={book}>
              <div className="prowrap">
                <Skeleton.Image className="skeleton-img" />
                <Skeleton.Button
                  active
                  className="skeleton20"
                  shape={buttonShape}
                ></Skeleton.Button>
                <Skeleton.Button
                  active
                  className="skeleton30"
                  shape={buttonShape}
                ></Skeleton.Button>
              </div>
            </Col>
          ))}
      </Row>
    );
  }
}

export default ReportMediaSkeleton;

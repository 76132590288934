import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Col, Row, Skeleton, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import productDefaultImg from "../../images/noimg-product.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";
import { PRODUCT_LIST_QUERY } from "../brand/product/ProductQuery";

const FavouriteProductSkeleton = () => {
  return (
    <div className="prowrap">
      <Skeleton.Button className="skeleton100" shape="default" />
    </div>
  );
};
export class FavouriteProduct extends Component {
  state = {
    loading: false,
    orderBy: "-product_id",
    data: [],
  };

  componentDidMount() {
    let favProductsArr =
      localStorage.getItem("favouriteProducts") !== null
        ? JSON.parse(localStorage.getItem("favouriteProducts"))
        : [];

    if (favProductsArr.length > 0) {
      this.fetchFavouriteProducts({
        deleted: 0,
        status: true,
        orderBy: this.state.orderBy,
        productlist: favProductsArr,
      });
    }
  }

  fetchFavouriteProducts = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allProduct.edges)
              : result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  goToProduct(productData) {
    window.open("/product-detail/" + productData.node.slug, "_blank");
  }

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  removeFavouriteProduct = (productid) => {
    let productId = parseInt(productid);
    let favProductsArr =
      localStorage.getItem("favouriteProducts") !== null
        ? JSON.parse(localStorage.getItem("favouriteProducts"))
        : [];

    let productIdIndex = favProductsArr.indexOf(productId);
    productIdIndex !== -1
      ? favProductsArr.splice(productIdIndex, 1)
      : favProductsArr.push(productId);
    localStorage.setItem("favouriteProducts", JSON.stringify(favProductsArr));
    SuccessNotificationMsg("Success", "Product removed from favourite list.");
    this.setState({
      data: this.state.data.filter(
        (s) => productId !== parseInt(s.node.productId)
      ),
    });
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div className="container">
        <div className="faqpage">
          <h1>My Favourite Products</h1>

          {data.length > 0 ? (
            data.map((product, index) => {
              return (
                <Row
                  gutter={[0]}
                  className="favprolist"
                  key={product.node.productId}
                >
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div
                      className="thumbimg"
                      onClick={() => this.goToProduct(product)}
                    >
                     <LazyLoadImage
                        width="100%"
                        height="auto"
                        className="prod1"
                        alt="product-pic"
                        src={this.getProductCoverImage(
                          product.node.productfilesmodelSet
                        )}
                      />
                    </div>
                    <div className="discppro">
                      <Popconfirm
                        className="dltbtn"
                        title="Are you sure to remove ?"
                        onConfirm={(id) =>
                          this.removeFavouriteProduct(product.node.productId)
                        }
                        okText="Yes"
                        placement="left"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                      <h3>
                        <span onClick={() => this.goToProduct(product)}>
                          {product.node.productName}
                        </span>
                      </h3>
                      <p>{product.node.shortDescription}</p>
                      <div className="valuerow">
                        <label>Brand: </label>{" "}
                        {
                          product.node.userId.onboardingmodelSet.edges[0].node
                            .company
                        }
                      </div>
                      <div className="valuerow">
                        <label>Price: </label>€ {product.node.price}
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : data.length === 0 && loading === false ? (
            <div className="not-found">
              You have no products in your favourite list.
            </div>
          ) : (
            <FavouriteProductSkeleton />
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(FavouriteProduct);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Checkbox, Form, Button, Drawer, Radio } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import qs from "query-string";
import FilterBrandList from "./FilterBrandList";
import FilterSizeCategoryList from "./FilterSizeCategoryList";

export class ProductFilter extends Component {
  state = {
    visible: false,
    materialList: [],
    price: "",
    companyList: [],
    categoryList: [],
    genderList: [],
    sizeList: [],
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    // rest of your code
  }

  componentDidMount() {
    this._isMounted = true;
    let searchString = qs.parse(this.props.history.location.search);
    this._isMounted && this.ShowFilterSelected(searchString);

    this.props.history.listen((location) => {
      let searchStringUpdated = qs.parse(this.props.history.location.search);
      this._isMounted && this.ShowFilterSelected(searchStringUpdated);
    });
  }

  ShowFilterSelected = (searchString) => {
    let queryStringGender =
      searchString.gender !== undefined ? searchString.gender.split(",") : [];

    let orderByVal =
      searchString.orderBy !== undefined ? searchString.orderBy : "";

    let priceByVal = searchString.price !== undefined ? searchString.price : "";

    let queryStringCategories =
      searchString.category !== undefined
        ? searchString.category.split(",")
        : [];

    let queryStringBrands =
      searchString.brand !== undefined ? searchString.brand.split(",") : [];

    let queryStringSize =
      searchString.size !== undefined ? searchString.size.split(",") : [];

    let queryStringMaterial =
      searchString.material !== undefined
        ? searchString.material.split(",")
        : [];

    this.setState({
      materialList: queryStringMaterial,
      companyList: queryStringBrands,
      categoryList: queryStringCategories,
      genderList: queryStringGender,
      sizeList: queryStringSize,
      price: priceByVal,
      orderBy: orderByVal,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleGenderFilter = (checkedValues) => {
    let searchString = qs.parse(this.props.history.location.search);

    let updatedSearchString = {
      ...searchString,
      gender: checkedValues.toString(),
    };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handleCategoryFilter = (checkedValues) => {
    let searchString = qs.parse(this.props.history.location.search);

    let updatedSearchString = {
      ...searchString,
      category: checkedValues.toString(),
    };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handleBrandFilter = (checkedValues) => {
    let searchString = qs.parse(this.props.history.location.search);

    let updatedSearchString = { ...searchString, brand: checkedValues };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handleSizeFilter = (checkedValues) => {
    let searchString = qs.parse(this.props.history.location.search);

    let updatedSearchString = {
      ...searchString,
      size: checkedValues.toString(),
    };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handleMaterialChange = (checkedValues) => {
    let searchString = qs.parse(this.props.history.location.search);

    let updatedSearchString = {
      ...searchString,
      material: checkedValues.toString(),
    };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handleImpactSizeChange = (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let impactValue =
      searchString.impact !== undefined ? searchString.impact : "";
    let impactObj = {};

    if (event.target.value) {
      impactObj.orderBy = impactValue !== "" ? impactValue : event.target.value;
      this.setState({ orderBy: event.target.value });
    }

    let updatedSearchString = { ...searchString, ...impactObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  handlePriceChange = (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let priceValue = searchString.price !== undefined ? searchString.price : "";
    let priceObj = {};

    if (event.target.value) {
      priceObj.price =
        event.target.value !== "" ? event.target.value : priceValue;
      this.setState({ price: event.target.value });
    }

    let updatedSearchString = { ...searchString, ...priceObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  renderGender() {
    const genderOptions = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      { label: "Unisex", value: "Unisex" },
    ];

    return (
      <Form>
        <Form.Item>
          <Checkbox.Group
            options={genderOptions}
            onChange={this.handleGenderFilter}
            value={this.state.genderList}
          />
        </Form.Item>
      </Form>
    );
  }

  renderBrand() {
    return (
      <FilterBrandList
        handleBrandFilter={(e) => this.handleBrandFilter(e)}
        stateValue={this.state}
      />
    );
  }

  renderMaterials() {
    const materialOptions = [
      { label: "Bio based material", value: "biobasedMaterial" },
      { label: "Long Lifetime material", value: "refurbishedMaterial" },
      { label: "Recycled material", value: "recycledMaterial" },
      { label: "Vegan fibers", value: "vegan" },
      { label: "Organic fiber", value: "organicFiber" },
      { label: "Product as a service", value: "endOfLifeProductAsAService" },
    ];
    return (
      <Form>
        <Form.Item>
          <Checkbox.Group
            options={materialOptions}
            onChange={this.handleMaterialChange}
            value={this.state.materialList}
          />
        </Form.Item>
      </Form>
    );
  }

  renderPriceFilter() {
    const priceOptions = [
      { label: "Under € 50", value: "<50" },
      { label: "€ 50 - € 100", value: "50-100" },
      { label: "€ 100 - € 150", value: "100-150" },
      { label: "€ 150 - € 200", value: "150-200" },
      { label: "Over € 200", value: ">200" },
    ];
    return (
      <div className="impact-filter-div">
        <Radio.Group
          options={priceOptions}
          onChange={this.handlePriceChange}
          value={this.state.price}
        />
      </div>
    );
  }

  renderImpactSize() {
    const impactSizeOptions = [
      { label: "CO2 emissions (kg)", value: "kgco2" },
      { label: "Water use (L)", value: "liter" },
      { label: "Distance (km)", value: "kmtravel" },
    ];
    return (
      <div className="impact-filter-div">
        <Radio.Group
          options={impactSizeOptions}
          onChange={this.handleImpactSizeChange}
          value={this.state.orderBy}
        />
      </div>
    );
  }

  removeSorting = () => {
    let searchString = qs.parse(this.props.history.location.search);
    let impactValue =
      searchString.impact !== undefined ? searchString.impact : "";
    let impactObj = {};

    impactObj.orderBy = impactValue;
    this.setState({ orderBy: null });

    let updatedSearchString = { ...searchString, ...impactObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  removePriceSorting = () => {
    let searchString = qs.parse(this.props.history.location.search);
    let priceValue = "";
    let priceObj = {};

    priceObj.price = priceValue;
    this.setState({ price: null });

    let updatedSearchString = { ...searchString, ...priceObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this._isMounted && this.props.onFilter(this.state);
  };

  render() {
    return (
      <React.Fragment>
        <Col className="filter_left">
          <div className="category_one">
            <h3>Gender</h3>
            {this.renderGender()}
          </div>
          <div className="category_one">
            <h3>Price</h3>
            {this.renderPriceFilter()}
            <div align="end">
              <Button type="link" onClick={() => this.removePriceSorting()}>
                Remove
              </Button>
            </div>
          </div>
          <div className="category_one">
            <h3>Impact Indicators</h3>
            {this.renderImpactSize()}
            <div align="end">
              <Button type="link" onClick={() => this.removeSorting()}>
                Remove
              </Button>
            </div>
          </div>
          <FilterSizeCategoryList
            handleCategoryFilter={(e) => this.handleCategoryFilter(e)}
            handleSizeFilter={(e) => this.handleSizeFilter(e)}
            stateValue={this.state}
          />
          <div className="category_one">
            <h3>Filters</h3>
            {this.renderMaterials()}
          </div>
          <div className="category_one">
            <h3>Brands</h3>
            {this.renderBrand()}
          </div>
        </Col>
        <Col className="filter_left_mob">
          <div className="site-drawer-render-in-current-wrapper ">
            <Button className="filterbtn" type="link" onClick={this.showDrawer}>
              <FilterOutlined />
            </Button>

            <Drawer
              placement="left"
              mask={false}
              onClose={this.onClose}
              visible={this.state.visible}
              getContainer={false}
            >
              <div className="category_one">
                <h3>Gender</h3>
                {this.renderGender()}
              </div>
              <div className="category_one">
                <h3>Impact Indicators</h3>
                {this.renderImpactSize()}
                <div align="end">
                  <Button type="link" onClick={() => this.removeSorting()}>
                    Remove
                  </Button>
                </div>
              </div>
              <FilterSizeCategoryList
                handleCategoryFilter={(e) => this.handleCategoryFilter(e)}
                handleSizeFilter={(e) => this.handleSizeFilter(e)}
                stateValue={this.state}
              />
              <div className="category_one">
                <h3>Filters</h3>
                {this.renderMaterials()}
              </div>
              <div className="category_one">
                <h3>Brands</h3>
                {this.renderBrand()}
              </div>
            </Drawer>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(ProductFilter);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Checkbox, Form, Button } from "antd";
import { BRAND_LIST_QUERY } from "../../admin/manageBrand/BrandQuery";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";

export class FilterBrandList extends Component {
  state = {
    loading: false,
    orderBy: "-appusers_id",
    data: [],
    compareProductArr: [],
    perPageRecord: 6,
    currentPageNo: 1,
  };

  componentDidMount() {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      brandNa: true,
    });
  }

  fetchBrandList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: BRAND_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allAppuser.edges)
              : result.data.allAppuser.edges,
          nextPageId: result.data.allAppuser.pageInfo.endCursor,
          previousPageId: result.data.allAppuser.pageInfo.startCursor,
          totalRecords: result.data.allAppuser.filterCount,
          totalPage: Math.ceil(
            result.data.allAppuser.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  loadMoreProducts = () => {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
      brandNa: true,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  render() {
    const { data, loading, currentPageNo, totalPage } = this.state;
    const brandsOptions =
      data.length > 0 &&
      data.map((brand) => {
        return {
          label: brand.node.onboardingmodelSet.edges[0].node.company,
          value: brand.node.onboardingmodelSet.edges[0].node.company,
        };
      });
    return (
      <React.Fragment>
        <Form>
          <Form.Item>
            <Checkbox.Group
              options={brandsOptions}
              onChange={this.props.handleBrandFilter}
              value={this.props.stateValue.companyList}
            />
          </Form.Item>
        </Form>
        <div align="end">
          {currentPageNo !== totalPage ? (
            <Button
              type="link"
              loading={loading}
              onClick={() => this.loadMoreProducts()}
            >
              View All
            </Button>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(FilterBrandList);

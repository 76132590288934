import React, { Component } from "react";
import { Row, Col } from "antd";

import Vincenzo from "../../../images/Vincenzo-Fornoni.jpg";
import Vikas from "../../../images/Vikas-pande-1.png";
import Jasper from "../../../images/jasper.webp";
import Nirav from "../../../images/Nirav-Kukadiya.png";
import Wouter from "../../../images/Wouter.jpg";
export class Team extends Component {
  render() {
    return (
      <div className="container">
        <div className="meetteam">
          <div className="pagename">Meet the Team</div>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="teamwrap">
                <div className="imgdiv">
                  <img alt="Vincenzo" src={Vincenzo}></img>
                </div>
                <h3>Vincenzo Fornoni</h3>
                <h5>Founder/CEO</h5>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="teamwrap">
                <div className="imgdiv">
                  <img alt="Vikas" src={Vikas}></img>
                </div>
                <h3>Vikas Pandey</h3>
                <h5>CTO</h5>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="teamwrap">
                <div className="imgdiv">
                  <img alt="Jasper" src={Jasper}></img>
                </div>
                <h3>Jasper Roosendaal</h3>
                <h5>LCA Expert</h5>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="teamwrap">
                <div className="imgdiv">
                  <img alt="Nirav" src={Nirav}></img>
                </div>
                <h3>Nirav Kukadiya</h3>
                <h5>Tech Lead</h5>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="teamwrap">
                <div className="imgdiv">
                  <img alt="Wouter" src={Wouter}></img>
                </div>
                <h3>Wouter van Veen</h3>
                <h5>CMO</h5>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Team;

import React, { Component } from "react";
import { Col, Row, Layout } from "antd";
import AdminSidebar from "../common/AdminSidebar";
import ProductClickCountChart from "../../brand/dashboard/ProductClickCountChart";
import ProductCountSummary from "../../brand/dashboard/ProductCountSummary";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;
export default class Dashboard extends Component {
  state = {
    chartParams: {
      deleted: 0,
      status: true,
      orderBy: "-click_count",
      first: 5,
    },
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">Dashboard</div>
              <div className="content_wraper dashboardPage">
                <ProductCountSummary />
                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={24}>
                    <div className="chart-wrapper">
                      <ProductClickCountChart
                        chartParams={this.state.chartParams}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

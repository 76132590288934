import gql from "graphql-tag";

export const GET_IMPACT_SIZE_DETAIL_QUERY = gql`
  query allImpactSize($deleted: Int, $productId: Int) {
    allImpactSize(deleted: $deleted, productId: $productId) {
      edges {
        node {
          impactsizeId
          attributeValue {
            attributeValueId
          }
          product {
            productId
            productName
          }
          weight
          kgco2
          liter
          kmtravel
          id
        }
      }
    }
  }
`;

export const UPDATE_IMPACT_SIZE_QUERY = gql`
  mutation updateImpactSize($impactSizeArr: [ImpactSizeInput]!) {
    updateImpactSize(input: { data: $impactSizeArr }) {
      impactsize {
        impactsizeId
        weight
        kgco2
        liter
        kmtravel
        product {
          productId
          productName
        }
      }
    }
  }
`;

export const DELETE_IMPACT_SIZE_QUERY = gql`
  mutation deleteImpactSize($impactsizeId: Int!) {
    deleteImpactSize(input: { impactsizeId: $impactsizeId }) {
      impactsizeId
    }
  }
`;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Breadcrumb } from "antd";

import AdminSidebar from "./AdminSidebar";
import EditProfile from "../../brand/common/EditProfile";
import ChangePassword from "../../brand/common/ChangePassword";

const { Content } = Layout;
export default class MyAccount extends Component {

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                My Account
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>My Account</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="myaccount">
                  <EditProfile history={this.props.history} />
                </div>
                <div className="changepass">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

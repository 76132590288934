import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Steps, Breadcrumb } from "antd";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";

import Sidebar from "../common/Sidebar";
import ProductDetail from "./productSteps/ProductDetail";
import SupplyChain from "./productSteps/SupplyChain";
import FormSubmit from "./productSteps/FormSubmit";
import "./productSteps/Onboarding.scss";

import {
  PRODUCT_COUNT_QUERY,
  ADD_PRODUCT_QUERY,
  UPDATE_PRODUCT_QUERY,
  GET_PRODUCT_DETAIL_QUERY,
} from "./ProductQuery";
import {
  UPDATE_COMPOSITION_QUERY,
  GET_COMPOSITION_DETAIL_QUERY,
  DELETE_COMPOSITION_QUERY,
} from "./CompositionQuery";
import {
  UPDATE_WEIGHT_SIZE_QUERY,
  GET_WEIGHT_SIZE_DETAIL_QUERY,
  DELETE_WEIGHT_SIZE_QUERY,
} from "./WeightSizeQuery";

import { GET_ATTRIBUTES_WITH_VALUES } from "../../common/AttributeQuery";

const { Step } = Steps;
class AddProduct extends Component {
  state = {
    when: false,
    current: 0,
    btnLoading: false,
    brandProductCount: 0,
    productRecordId: 0,
    compositions: [{ materialName: "", percentage: "" }],
    weightSizeArr: [{ attributeValueId: "", sizeWeight: "" }],
    authUserId: authUserData().appusersId,
    currentRoute: this.props.location.pathname,
    productName: "",
    sizes: [],
    productType: [],
    endOfLifeOthers: "",
    endOfLifeBiodegradable: false,
    endOfLifeRecyclable: false,
    endOfLifeProductAsAService: false,
    endOfLifeRepairServiceBrand: false,
    gender: "Male",
    price: 0,
    productLink: "",
    linkToBrand: "",
    biobasedMaterial: false,
    refurbishedMaterial: false,
    reusedMaterial: false,
    recycledMaterial: false,
    vegan: false,
    secondLifePossible: false,
    socialImpact: "",
    colors: "",
    organicFiber: false,
    sizeArr: [],
    productTypeArr: [],
    detailDescription: "",
  };

  productFormRef = React.createRef();

  componentDidMount() {
    this.getBrandProductCount();

    let productRecordId = this.props.match.params.productId
      ? atob(this.props.match.params.productId)
      : 0;

    if (productRecordId > 0) {
      this.getProductDetail(productRecordId);
      this.getComposition(productRecordId);
      this.getWeightSize(productRecordId);
      this.setState({ productRecordId });
    }
    this.getAttributes();
  }

  renderOnBoardingFormSteps(currentStep) {
    switch (currentStep) {
      case 0:
        return (
          <ProductDetail
            stateValues={this.state}
            history={this.props.history}
            onClickBack={this.onBackStepButton}
            onClickNext={this.onNextStepButton}
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleSelectChange}
            handleCheckboxChange={this.handleCheckboxChange}
            handleMultiSelectChange={this.handleMultiSelectChange}
            handleCloneSelectChange={this.handleCloneSelectChange}
            addComposition={this.addComposition}
            handleComposition={this.handleComposition}
            removeComposition={this.removeComposition}
            addWeightSize={this.addWeightSize}
            handleWeightSize={this.handleWeightSize}
            removeWeightSize={this.removeWeightSize}
            productFormRef={this.productFormRef}
          />
        );
      case 1:
        return (
          <SupplyChain
            stateValues={this.state}
            onClickBack={this.onBackStepButton}
            onClickNext={this.onNextStepButton}
            handleInputChange={this.handleInputChange}
            handleCheckboxChange={this.handleCheckboxChange}
            history={this.props.history}
            mergeStat={this.mergeComponentStat}
          />
        );
      case 2:
        return (
          <FormSubmit
            stateValues={this.state}
            onClickBack={this.onBackStepButton}
            onClickSubmit={this.onSubmitStepButton}
            history={this.props.history}
          />
        );
      default:
        return null;
    }
  }

  mergeComponentStat = (stats) => {
    this.setState({ supplyChainId: stats.selectedSupplychain });
  };

  onStepChange = (current) => {
    this.setState({ current });
  };

  onNextStepButton = async () => {
    const { current } = this.state;
    //this.setState({ btnLoading: true });

    let response = "";

    if (this.state.productRecordId === 0) {
      response = await this.saveProduct();
    } else {
      response = await this.updateProduct();
    }

    if (!response) {
      return false;
    }

    this.setState({
      btnLoading: false,
      current: current + 1,
    });
  };

  onBackStepButton = () => {
    const { current } = this.state;

    if (current === 1 && this.state.productRecordId !== "") {
      this.getComposition(this.state.productRecordId);
      this.getWeightSize(this.state.productRecordId);
    }

    this.setState({
      ...this.state,
      current: current - 1,
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value, when: true });
  };

  handleSelectChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState({ [input]: event.target.checked });
  };

  handleMultiSelectChange = (input) => (value) => {
    this.setState({ [input]: value });
  };

  handleCloneSelectChange = (input, index) => {
    let selected = this.state.weightSizeArr;
    if (selected[index]) {
      selected[index].attributeValueId = input;
      this.setState({ weightSizeArr: selected }, () => {});
    }
  };

  onSubmitStepButton = () => {
    SuccessNotificationMsg("Success!", "Product updated successfully.");
    this.props.history.push("/seller-manage-product");
  };

  saveProduct = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: ADD_PRODUCT_QUERY,
        variables: this.state,
      })
      .then((response) => {
        this.setState({
          productRecordId: response.data.createProduct.product.productId,
        });
        return this.updateComposition(response.data.createProduct);
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Product added successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });

    return apiStatus;
  };

  updateProduct = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: UPDATE_PRODUCT_QUERY,
        variables: this.state,
      })
      .then((response) => {
        if (this.state.current === 0) {
          return this.updateComposition(response.data.updateProduct);
        } else {
          return true;
        }
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Product updated successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });

    return apiStatus;
  };

  updateComposition = async (response) => {
    const newCompositionArr = this.state.compositions.map((objItems) => {
      delete objItems.__typename;
      delete objItems.id;
      return { ...objItems, productId: response.product.productId };
    });

    this.setState({ compositions: newCompositionArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_COMPOSITION_QUERY,
        variables: {
          compositions: this.state.compositions,
        },
      })
      .then((result) => {
        return this.updateWeightSize(response.product.productId);
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  getBrandProductCount = (params) => {
    const { client } = this.props;
    client
      .query({
        query: PRODUCT_COUNT_QUERY,
        variables: { userId: this.state.authUserId },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          brandProductCount: result.data.allProduct.filterCount,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  getProductDetail = async (productRecordId) => {
    const { client } = this.props;
    await client
      .query({
        query: GET_PRODUCT_DETAIL_QUERY,
        variables: { productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let { sizes, productType } = this.prepareProductAttributeArr(
          response.data.allProduct.edges[0].node
        );

        delete response.data.allProduct.edges[0].node.productattributemodelSet;

        this.setState({
          sizes,
          productType,
          ...response.data.allProduct.edges[0].node,
        });

        this.productFormRef.current.setFieldsValue(
          response.data.allProduct.edges[0].node
        );
      })
      .catch((e) => {
        console.log(e);
        ErrorNotificationMsg(
          "Product Fetch Error",
          e.message.replace("GraphQL error:", "")
        );
        return false;
      });
  };

  getComposition = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_COMPOSITION_DETAIL_QUERY,
        variables: { deleted: 0, productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        let mcFieldObject = {};

        if (response.data.allMaterialcomposition.edges.length > 0) {
          response.data.allMaterialcomposition.edges.map(
            (objectValue, objectKey) => {
              mcStatArr.push(objectValue.node);
              mcFieldObject["materialName" + objectKey] =
                objectValue.node.materialName;
              mcFieldObject["percentage" + objectKey] =
                objectValue.node.percentage;
              return null;
            }
          );
        }
        this.setState({ compositions: mcStatArr });
        this.productFormRef.current.setFieldsValue(mcFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  addComposition = () => {
    this.setState(
      {
        compositions: [
          ...this.state.compositions,
          { materialName: "", percentage: "" },
        ],
      },
      () => {
        this.setFieldValue(this.state.compositions);
      }
    );
  };

  handleComposition = (e, index) => {
    const newCompositionList = [...this.state.compositions];
    newCompositionList[index][e.target.name] = e.target.value;
    this.setState({ compositions: newCompositionList });
  };

  removeComposition = async (index, compositionObject) => {
    if ("materialcompositionId" in compositionObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_COMPOSITION_QUERY,
          variables: {
            materialcompositionId: compositionObject.materialcompositionId,
          },
        })
        .then((result) => {
          this.setState(
            {
              compositions: this.state.compositions.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setFieldValue(this.state.compositions);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.state.compositions.splice(index, 1);
      this.setState({ compositions: this.state.compositions }, () => {
        this.setFieldValue(this.state.compositions);
      });
    }
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["materialName" + objectKey] = objectValue.materialName;
      mcFieldObject["percentage" + objectKey] = objectValue.percentage;
      return null;
    });

    this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  getAttributes = () => {
    this.props.client
      .query({
        query: GET_ATTRIBUTES_WITH_VALUES,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let sizeArr = "";
        let productTypeArr = "";

        if (response.data.allAttribute.edges.length > 0) {
          response.data.allAttribute.edges.map((attributes) => {
            if (attributes.node.attributeId === "1") {
              sizeArr = attributes.node.attributevaluemodelSet;
            }
            if (attributes.node.attributeId === "2") {
              productTypeArr = attributes.node.attributevaluemodelSet;
            }
            return null;
          });
          this.setState({ productTypeArr, sizeArr });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  prepareProductAttributeArr = (productData) => {
    let response = {
      sizes: [],
      productType: [],
    };

    if (
      productData.productattributemodelSet &&
      productData.productattributemodelSet.edges.length > 0
    ) {
      productData.productattributemodelSet.edges.map((attrObj, index) => {
        if (attrObj.node.attributeValue.attribute.attributeId === "1") {
          response.sizes.push(attrObj.node.attributeValue.attributeValueId);
        }
        if (attrObj.node.attributeValue.attribute.attributeId === "2") {
          response.productType.push(
            attrObj.node.attributeValue.attributeValueId
          );
        }
        return null;
      });
    }

    return response;
  };

  getWeightSize = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_WEIGHT_SIZE_DETAIL_QUERY,
        variables: { deleted: 0, productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mrStatArr = [];
        let mrFieldObject = {};

        if (response.data.allProductWtPSize.edges.length > 0) {
          response.data.allProductWtPSize.edges.map(
            (objectValue, objectKey) => {
              mrStatArr.push({
                attributeValueId:
                  objectValue.node.attributeValue.attributeValueId,
                sizeWeight: objectValue.node.sizeWeight,
                productwtpsizeId: objectValue.node.productwtpsizeId,
              });

              mrFieldObject["attributeValueId" + objectKey] =
                objectValue.node.attributeValue.attributeValueId;
              mrFieldObject["sizeWeight" + objectKey] =
                objectValue.node.sizeWeight;
              return null;
            }
          );
        }

        this.setState({ weightSizeArr: mrStatArr });
        this.productFormRef.current.setFieldsValue(mrFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  addWeightSize = () => {
    this.setState(
      {
        weightSizeArr: [
          ...this.state.weightSizeArr,
          { attributeValueId: "", sizeWeight: "" },
        ],
      },
      () => {
        this.setWeightSizeFieldValue(this.state.weightSizeArr);
      }
    );
  };

  setWeightSizeFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["attributeValueId" + objectKey] =
        objectValue.attributeValueId;
      mcFieldObject["sizeWeight" + objectKey] = objectValue.sizeWeight;
      return null;
    });

    this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  handleWeightSize = (e, index) => {
    const newWeightSizeList = [...this.state.weightSizeArr];
    newWeightSizeList[index][e.target.name] = e.target.value;
    this.setState({ weightSizeArr: newWeightSizeList });
  };

  removeWeightSize = async (index, weightSizeObject) => {
    if ("productwtpsizeId" in weightSizeObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_WEIGHT_SIZE_QUERY,
          variables: {
            productwtpsizeId: weightSizeObject.productwtpsizeId,
          },
        })
        .then((result) => {
          this.setState(
            {
              weightSizeArr: this.state.weightSizeArr.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setWeightSizeFieldValue(this.state.weightSizeArr);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.state.weightSizeArr.splice(index, 1);
      this.setState({ weightSizeArr: this.state.weightSizeArr }, () => {
        this.setWeightSizeFieldValue(this.state.weightSizeArr);
      });
    }
  };

  updateWeightSize = async (productId) => {
    const newWeightSizeArr = this.state.weightSizeArr.map((objItems) => {
      return {
        ...objItems,
        productId: productId,
      };
    });

    this.setState({ weightSizeArr: newWeightSizeArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_WEIGHT_SIZE_QUERY,
        variables: {
          weightSizeArr: this.state.weightSizeArr,
        },
      })
      .then((result) => {
        return true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="contentpart">
        <Sidebar />
        <div className="right_content_col">
          <div className="pagename">
            Product Registration
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/seller-dashboard">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {this.state.currentRoute === "/seller-add-product"
                  ? "Add"
                  : "Edit"}{" "}
                Product
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="content_wraper">
            <div className="stepWraper">
              <div className="onboarding-form-header">
                <Steps
                  type="navigation"
                  current={current}
                  className="site-navigation-steps"
                >
                  <Step title="Product Details" />
                  <Step title="Supply Chain Details" />
                  <Step title="Product Documentation" />
                </Steps>
              </div>
              <div className="onboarding-form-body">
                {this.renderOnBoardingFormSteps(current)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(AddProduct);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Input,
  Breadcrumb,
  Popconfirm,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";

import Sidebar from "../common/Sidebar";
import {
  SUPPLYCHAIN_LIST_QUERY,
  DELETE_SUPPLYCHAIN_QUERY,
} from "./SupplychainQuery";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class ManageSupplyChain extends Component {
  state = {
    data: [],
    loading: false,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-supplychain_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0, userId: authUserData().appusersId },
  };

  componentDidMount() {
    this.fetchSupplychainList({
      deleted: 0,
      orderBy: this.state.orderBy,
      userId: authUserData().appusersId,
      first: this.state.perPageRecord,
    });

    this.searchCallback = debounce(function (e) {
      this.searchSupplychain(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchSupplychainList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: orderBy,
      first: this.state.perPageRecord,
    });
  };

  fetchSupplychainList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: SUPPLYCHAIN_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allSupplychain.edges,
          nextPageId: result.data.allSupplychain.pageInfo.endCursor,
          previousPageId: result.data.allSupplychain.pageInfo.startCursor,
          totalRecords: result.data.allSupplychain.filterCount,
          totalPage: Math.ceil(
            result.data.allSupplychain.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchSupplychainList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchSupplychainList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchSupplychainList({
      deleted: 0,
      userId: authUserData().appusersId,
      first: e,
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchSupplychain = (searchText) => {
    if (searchText !== "") {
      this.fetchSupplychainList({
        search: searchText,
        deleted: 0,
        userId: this.state.authUserId,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText, currentPageNo: 1 });
    } else {
      this.fetchSupplychainList({
        deleted: 0,
        userId: this.state.authUserId,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText: "" });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "supply_chain_name";

    if (sortField === "supplyChainName" && sortOrder === "ascend") {
      sortingEnumKey = "supply_chain_name";
    }

    if (sortField === "supplyChainName" && sortOrder === "descend") {
      sortingEnumKey = "-supply_chain_name";
    }

    if (
      sortField === "shippingTransportationRoutesInformation" &&
      sortOrder === "ascend"
    ) {
      sortingEnumKey = "shipping_transportation_routes_information";
    }

    if (
      sortField === "shippingTransportationRoutesInformation" &&
      sortOrder === "descend"
    ) {
      sortingEnumKey = "-shipping_transportation_routes_information";
    }
    return sortingEnumKey;
  }

  confirmDelete = async (supplychainId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_SUPPLYCHAIN_QUERY,
        variables: { supplychainId: supplychainId },
      })
      .then((result) => {
        this.fetchSupplychainList({
          deleted: 0,
          orderBy: this.state.orderBy,
          userId: authUserData().appusersId,
          first: this.state.perPageRecord,
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg("Success", "Supplychain deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "Supply Chain Name",
        key: "supplyChainName",
        sorter: true,
        render: (node) => `${node.node.supplyChainName}`,
        width: "40%",
      },
      {
        title: "Shipping Transportation Routes",
        key: "shippingTransportationRoutesInformation",
        sorter: true,
        render: (node) =>
          `${node.node.shippingTransportationRoutesInformation}`,
        width: "50%",
        ellipsis: true,
      },
      {
        width: "10%",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/seller-edit-supplychain/" + btoa(node.node.supplychainId)}
              className="action"
            >
              <EditOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure delete this supplychain ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.supplychainId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>

            <Link
              to={
                "/seller-supplychain-document/" + btoa(node.node.supplychainId)
              }
              className="action"
            >
              <UploadOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Supplychain
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Supplychain</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>My Supplychain Details</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>
                          <Link to="/seller-add-supplychain">
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              icon={<PlusCircleOutlined />}
                            >
                              Add Supplychain
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 970 }}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>

                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageSupplyChain);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Steps, Breadcrumb } from "antd";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";

import AdminSidebar from "../common/AdminSidebar";
import SupplychainForm from "./supplychainSteps/SupplychainForm";
import DocumentForm from "./supplychainSteps/DocumentForm";

import {
  ADD_SUPPLYCHAIN_QUERY,
  UPDATE_SUPPLYCHAIN_QUERY,
  GET_SUPPLYCHAIN_DETAIL_QUERY,
} from "./../../brand/supplychain/SupplychainQuery";
import {
  GET_MANUFACTURER_DETAIL_QUERY,
  UPDATE_MANUFACTURER_QUERY,
  DELETE_MANUFACTURER_QUERY,
} from "./../../brand/supplychain/ManufacturerQuery";

const { Step } = Steps;
class AddSupplyChain extends Component {
  state = {
    current: 0,
    btnLoading: false,
    apiLoading: false,
    currentRoute: this.props.location.pathname,
    authUserId: 0,
    supplyChainRecordId: 0,
    manufacturers: [{ producerName: "", process: "", city: "", country: "" }],
  };

  supplychainFormRef = React.createRef();

  componentDidMount() {
    let supplyChainRecordId = this.props.match.params.supplychainId
      ? atob(this.props.match.params.supplychainId)
      : 0;

    if (supplyChainRecordId > 0) {
      this.getSupplychainDetail(supplyChainRecordId);
      this.getManufacturer(supplyChainRecordId);
      this.setState({ supplyChainRecordId });
    }
  }

  renderFormSteps(currentStep) {
    switch (currentStep) {
      case 0:
        return (
          <SupplychainForm
            stateValues={this.state}
            history={this.props.history}
            onClickBack={this.onBackStepButton}
            onClickNext={this.onNextStepButton}
            handleInputChange={this.handleInputChange}
            handleCheckboxChange={this.handleCheckboxChange}
            addManufacturer={this.addManufacturer}
            handleManufacturer={this.handleManufacturer}
            removeManufacturer={this.removeManufacturer}
            supplychainFormRef={this.supplychainFormRef}
            handleBrandSelect={this.handleBrandSelect}
          />
        );
      case 1:
        return (
          <DocumentForm
            stateValues={this.state}
            onClickBack={this.onBackStepButton}
            onClickSubmit={this.onSubmitStepButton}
          />
        );
      default:
        return null;
    }
  }

  onStepChange = (current) => {
    this.setState({ current });
  };

  onNextStepButton = async () => {
    const { current } = this.state;
    this.setState({ btnLoading: true });

    let response = "";

    if (this.state.supplyChainRecordId === 0) {
      response = await this.saveSupplychain();
    } else {
      response = await this.updateSupplychain();
    }

    if (!response) {
      return false;
    }

    this.setState({
      btnLoading: false,
      current: current + 1,
    });
  };

  onBackStepButton = () => {
    const { current } = this.state;

    if (current === 1 && this.state.supplyChainRecordId !== "") {
      this.getManufacturer(this.state.supplyChainRecordId);
    }

    this.setState({
      ...this.state,
      current: current - 1,
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState({ [input]: event.target.checked });
  };

  onSubmitStepButton = () => {
    SuccessNotificationMsg("Success!", "Suppychain updated successfully.");
    this.props.history.push("/admin-manage-supplychain");
  };

  getSupplychainDetail = (supplyChainRecordId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: {
          deleted: 0,
          supplychainId: supplyChainRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allSupplychain.edges[0].node);
        this.supplychainFormRef.current.setFieldsValue(
          response.data.allSupplychain.edges[0].node
        );
        this.setState({ apiLoading: false, authUserId: response.data.allSupplychain.edges[0].node.userId.appusersId});
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  saveSupplychain = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: ADD_SUPPLYCHAIN_QUERY,
        variables: this.state,
      })
      .then((response) => {
        this.setState({
          supplyChainRecordId:
            response.data.createSupplychain.supplychain.supplychainId,
        });
        return this.updateManufacturer(response.data.createSupplychain);
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Supplychain added successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });
    return apiStatus;
  };

  updateSupplychain = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: UPDATE_SUPPLYCHAIN_QUERY,
        variables: this.state,
      })
      .then((response) => {
        return this.updateManufacturer(response.data.updateSupplychain);
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Supplychain updated successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });

    return apiStatus;
  };

  getManufacturer = (supplyChainRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_MANUFACTURER_DETAIL_QUERY,
        variables: { deleted: 0, supplyChainId: supplyChainRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        let mcFieldObject = {};

        if (response.data.allProducermanufacturer.edges.length > 0) {
          response.data.allProducermanufacturer.edges.map(
            (objectValue, objectKey) => {
              mcStatArr.push(objectValue.node);
              mcFieldObject["producerName" + objectKey] =
                objectValue.node.producerName;
              mcFieldObject["process" + objectKey] = objectValue.node.process;
              mcFieldObject["city" + objectKey] = objectValue.node.city;
              mcFieldObject["country" + objectKey] = objectValue.node.country;
              return null;
            }
          );
        }
        this.setState({ manufacturers: mcStatArr });
        this.supplychainFormRef.current.setFieldsValue(mcFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  updateManufacturer = async (response) => {
    const newManufacturerArr = this.state.manufacturers.map((objItems) => {
      delete objItems.__typename;
      delete objItems.id;
      return { ...objItems, supplyChainId: response.supplychain.supplychainId };
    });

    this.setState({ manufacturers: newManufacturerArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_MANUFACTURER_QUERY,
        variables: {
          manufacturers: this.state.manufacturers,
        },
      })
      .then((result) => {
        return true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleBrandSelect = (value, option) => {
    this.setState({ authUserId: option.id });
  };

  addManufacturer = () => {
    this.setState(
      {
        manufacturers: [
          ...this.state.manufacturers,
          { producerName: "", process: "", city: "", country: "" },
        ],
      },
      () => {
        this.setFieldValue(this.state.manufacturers);
      }
    );
  };

  handleManufacturer = (e, index) => {
    const newManufactureList = [...this.state.manufacturers];
    newManufactureList[index][e.target.name] = e.target.value;
    this.setState({ manufacturers: newManufactureList });
  };

  removeManufacturer = async (index, manufacturerObject) => {
    if ("producermanufacturerId" in manufacturerObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_MANUFACTURER_QUERY,
          variables: {
            producermanufacturerId: manufacturerObject.producermanufacturerId,
          },
        })
        .then((result) => {
          this.setState(
            {
              manufacturers: this.state.manufacturers.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setFieldValue(this.state.manufacturers);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.setState(
        {
          manufacturers: this.state.manufacturers.filter(
            (s, sidx) => index !== sidx
          ),
        },
        () => {
          this.setFieldValue(this.state.manufacturers);
        }
      );
    }
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["producerName" + objectKey] = objectValue.producerName;
      mcFieldObject["process" + objectKey] = objectValue.process;
      mcFieldObject["city" + objectKey] = objectValue.city;
      mcFieldObject["country" + objectKey] = objectValue.country;
      return null;
    });
    this.supplychainFormRef.current.setFieldsValue(mcFieldObject);
  };

  render() {
    const { current } = this.state;

    return (
      <div className="contentpart">
        <AdminSidebar />
        <div className="right_content_col">
          <div className="pagename">
            {this.state.supplyChainRecordId > 0
              ? "Edit Supply Chain"
              : "Add Supply Chain"}
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/seller-dashboard">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {this.state.currentRoute === "/admin-edit-supplychain"
                  ? "Add"
                  : "Edit"}{" "}
                Product
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="content_wraper">
            <div className="stepWraper edit">
              <div className="onboarding-form-header">
                <Steps
                  type="navigation"
                  current={current}
                  className="site-navigation-steps"
                >
                  <Step title="Supply Chain Details" />
                  <Step title="Supply Chain Documentation" />
                </Steps>
              </div>
              <div className="onboarding-form-body">
                {this.renderFormSteps(current)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(AddSupplyChain);

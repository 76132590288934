import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { Spin, Button, Row, Col, Space } from "antd";
import { GET_IMPACT_INDICATOR_QUERY } from "../../admin/impactIndicator/ImpactIndicatorQuery";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";

const createMarkup = (data) => {
  return { __html: data };
};

export class ImpactIndicator extends Component {
  state = {
    apiLoading: true,
    data: {},
  };

  componentDidMount() {
    let impactIndicatorId = this.props.location.state
      ? this.props.location.state.impactId
      : 0;
    this.getImpactIndicator({
      deleted: 0,
      orderBy: "impact_indicator_id",
      impactIndicatorId,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.state !== undefined &&
      prevProps.location.state.impactId !== this.props.location.state.impactId
    ) {
      let impactIndicatorId = this.props.location.state
        ? this.props.location.state.impactId
        : 0;
      this.getImpactIndicator({
        deleted: 0,
        orderBy: "impact_indicator_id",
        impactIndicatorId,
      });
    }
  }

  getImpactIndicator = (params) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: GET_IMPACT_INDICATOR_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        if (result.data.allImpactIndicator.edges.length > 0) {
          this.setState({
            apiLoading: false,
            data: result.data.allImpactIndicator.edges[0].node,
          });
        } else {
          this.setState({
            apiLoading: false,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  render() {
    const { apiLoading, data } = this.state;
    return (
      <div className="container">
        <Spin spinning={apiLoading}>
          <div className="indicatorpage">
            <h1>{data.title}</h1>
            <Row gutter={[15]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <p dangerouslySetInnerHTML={createMarkup(data.description)}></p>
                <div className="bottomButtons " align="end">
                  <Space>
                    <Link to={data.shopNowUrl}>
                      <Button type="secondary" htmlType="button">
                        Shop Now
                      </Button>
                    </Link>
                    <Link to={{ pathname: data.url }} target="_blank">
                      <Button type="primary" htmlType="button">
                        Read More Articles
                      </Button>
                    </Link>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

export default withApollo(ImpactIndicator);

import gql from "graphql-tag";

export const GET_WEIGHT_SIZE_DETAIL_QUERY = gql`
  query allProductWtPSize($deleted: Int, $productId: Int) {
    allProductWtPSize(deleted: $deleted, productId: $productId) {
      edges {
        node {
          productwtpsizeId
          attributeValue {
            attributeValueId
          }
          sizeWeight
          product {
            productId
            productName
          }
          id
        }
      }
    }
  }
`;

export const UPDATE_WEIGHT_SIZE_QUERY = gql`
  mutation updateProductWtPSize($weightSizeArr: [ProductWtpSizeInput]!) {
    updateProductWtPSize(input: { data: $weightSizeArr }) {
      productwtpsize {
        productwtpsizeId
        sizeWeight
        product {
          productId
          productName
        }
      }
    }
  }
`;

export const DELETE_WEIGHT_SIZE_QUERY = gql`
  mutation deleteProductWtPSize($productwtpsizeId: Int!) {
    deleteProductWtPSize(input: { productwtpsizeId: $productwtpsizeId }) {
      productwtpsizeId
    }
  }
`;

import gql from "graphql-tag";

export const GET_COMPOSITION_DETAIL_QUERY = gql`
  query allMaterialcomposition($deleted: Int, $productId: Int) {
    allMaterialcomposition(deleted: $deleted, productId: $productId) {
      edges {
        node {         
        id
        materialcompositionId
        materialName
        percentage
        }
      }
    }
  }
`;

export const UPDATE_COMPOSITION_QUERY = gql`
  mutation updateMaterialComposition(
    $compositions: [MaterialCompositionInput]!
  ) {
    updateMaterialComposition(input: { data: $compositions }) {
      materialcomposition {
        materialcompositionId
        materialName
        percentage
      }
    }
  }
`;

export const DELETE_COMPOSITION_QUERY = gql`
  mutation deleteMaterialComposition($materialcompositionId: Int!) {
    deleteMaterialComposition(
      input: { materialcompositionId: $materialcompositionId }
    ) {
      materialcompositionId
    }
  }
`;
import gql from "graphql-tag";

export const ADD_SUPPLYCHAIN_QUERY = gql`
  mutation createSupplychain(
    $authUserId: Int
    $supplyChainName: String!
    $shippingTransportationRoutesInformation: String!
    $gatheringData: String
    $finalRemarks: String
  ) {
    createSupplychain(
      input: {
        userId: $authUserId
        supplyChainName: $supplyChainName
        shippingTransportationRoutesInformation: $shippingTransportationRoutesInformation
        gatheringData: $gatheringData
        finalRemarks: $finalRemarks
      }
    ) {
      supplychain {
        supplychainId
        supplyChainName
        shippingTransportationRoutesInformation
        gatheringData
        finalRemarks
      }
    }
  }
`;

export const UPDATE_SUPPLYCHAIN_QUERY = gql`
  mutation updateSupplychain(
    $supplyChainRecordId: Int!
    $supplyChainName: String!
    $shippingTransportationRoutesInformation: String!
    $gatheringData: String
    $finalRemarks: String
    $authUserId: Int
  ) {
    updateSupplychain(
      input: {
        supplychainId: $supplyChainRecordId
        supplyChainName: $supplyChainName
        shippingTransportationRoutesInformation: $shippingTransportationRoutesInformation
        gatheringData: $gatheringData
        finalRemarks: $finalRemarks
        userId: $authUserId
      }
    ) {
      supplychain {
        supplychainId
        supplyChainName
        shippingTransportationRoutesInformation
        gatheringData
        finalRemarks
      }
    }
  }
`;

export const GET_SUPPLYCHAIN_DETAIL_QUERY = gql`
  query allSupplychain($deleted: Int, $userId: Int, $supplychainId: Int) {
    allSupplychain(
      deleted: $deleted
      userId: $userId
      supplychainPk: $supplychainId
    ) {
      totalCount
      edges {
        node {
          id
          supplychainId
          supplyChainName
          shippingTransportationRoutesInformation
          finalRemarks
          gatheringData
          userId {
            appusersId
          }
        }
      }
    }
  }
`;

export const SUPPLYCHAIN_LIST_QUERY = gql`
  query allSupplychain(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $userId: Int
    $search: String
    $orderBy: [String]
  ) {
    allSupplychain(
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      userId: $userId
      search: $search
      orderBy: $orderBy
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          supplychainId
          supplyChainName
          shippingTransportationRoutesInformation
          finalRemarks
          userId {
            appusersId
            onboardingmodelSet {
              edges {
                node {
                  company
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_SUPPLYCHAIN_QUERY = gql`
  mutation deleteSupplychain($supplychainId: Int!) {
    deleteSupplychain(input: { supplychainId: $supplychainId }) {
      supplychainId
    }
  }
`;

import cloud from "../images/cloud.png";
import recycle from "../images/recycle.png";
import bio from "../images/bio.png";
import time from "../images/time.png";
import water from "../images/water.png";
import world from "../images/world.png";
import service from "../images/service.png";

/** Auth user token key */
export const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";

export const impacts = [
  {
    slug: "climate-change-kg-co2-equivalent",
    icon: cloud,
  },
  {
    slug: "climate-change-kg-co2-eq",
    icon: cloud,
  },
  {
    slug: "recycled-material",
    icon: recycle,
  },
  {
    slug: "biobased-materials",
    icon: bio,
  },
  {
    slug: "biobased-material",
    icon: bio,
  },
  {
    slug: "refurbished-material",
    icon: time,
  },
  {
    slug: "water-use-liters",
    icon: water,
  },
  {
    slug: "total-distance-km",
    icon: world,
  },
  {
    slug: "total-distance-km-ngu5",
    icon: world,
  },
  {
    slug: "product-as-service",
    icon: service,
  },
  {
    slug: "product-as-service-pm75",
    icon: service,
  },
];

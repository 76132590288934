import { notification } from "antd";

export const SuccessNotificationMsg = (message, desc) => {
  return notification.success({
    message: message === "" && message !== undefined ? "" : message,
    description: desc === "" && desc !== undefined ? "" : desc,
    placement: "topRight",
    duration: 3.5,
  });
};

export const ErrorNotificationMsg = (message, desc) => {
  return notification.error({
    message: message === "" && message !== undefined ? "" : message,
    description: desc === "" && desc !== undefined ? "" : desc,
    placement: "topRight",
    duration: 3.5,
  });
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import ImageGallery from "react-image-gallery";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Breadcrumb,
  Upload,
  Popconfirm,
  Spin,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  ZoomInOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import AdminSidebar from "../common/AdminSidebar";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import {
  UPLOAD_PRODUCT_DOCUMENTS,
  GET_PRODUCT_DOCUMENT_QUERY,
  DELETE_PRODUCT_DOCUMENT_QUERY,
  DOWNLOAD_PRODUCT_DOCUMENT_QUERY,
} from "../../brand/product/ProductDocumentQuery";

import "../dashboard/Dashboard.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import fileImg from "../../../images/file-icon.png";

const { Content } = Layout;
class AdminProductDocument extends Component {
  state = {
    showGallery: false,
    uploadedDocuments: [],
    uploadedImages: [],
    galleryProductImages: [],
    apiLoading: false,
    rotate: true,
  };

  componentDidMount() {
    let productRecordId = this.props.match.params.productId
      ? atob(this.props.match.params.productId)
      : 0;
    if (productRecordId > 0) {
      this.getProductDocument(productRecordId);
      this.setState({ productRecordId });
    }
  }

  uploadProductDoc = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    let fileType = "doc";
    client
      .mutate({
        mutation: UPLOAD_PRODUCT_DOCUMENTS,
        variables: {
          productId: this.state.productRecordId,
          productFiles: file,
          fileType: fileType,
        },
      })
      .then((response) => {
        let uploadDocStat = this.state.uploadedDocuments;
        uploadDocStat.push({
          id: response.data.uploadProductFile.productfile.productfilesId,
          name: response.data.uploadProductFile.productfile.productfileName,
          url: response.data.uploadProductFile.presignedUrl,
          status: "done",
        });

        this.setState({ uploadedDocuments: uploadDocStat });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  uploadProductFile = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    let fileType = "pic";
    client
      .mutate({
        mutation: UPLOAD_PRODUCT_DOCUMENTS,
        variables: {
          productId: this.state.productRecordId,
          productFiles: file,
          fileType: fileType,
        },
      })
      .then((response) => {
        let uploadImageStat = this.state.uploadedImages;
        let galleryImageStat = this.state.galleryProductImages;

        uploadImageStat.push({
          id: response.data.uploadProductFile.productfile.productfilesId,
          name: response.data.uploadProductFile.productfile.productfileName,
          url: response.data.uploadProductFile.productfile.awsFileUrl,
          status: "done",
        });

        galleryImageStat.push({
          original: response.data.uploadProductFile.presignedUrl,
          thumbnail: response.data.uploadProductFile.presignedUrl,
        });

        this.setState({
          uploadedImages: uploadImageStat,
          galleryProductImages: galleryImageStat,
        });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  getProductDocument = (productRecordId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_PRODUCT_DOCUMENT_QUERY,
        variables: {
          deleted: 0,
          productId: productRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let docArr = [];
        let imageArr = [];
        let galleryImageArr = [];

        if (response.data.allProductfiles.edges.length > 0) {
          response.data.allProductfiles.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["id"] = objectValue.node.productfilesId;
            mcFieldObject["name"] = objectValue.node.productfileName;
            mcFieldObject["url"] = objectValue.node.awsFileUrl;
            mcFieldObject["thumbUrl"] = objectValue.node.awsFileUrl;
            mcFieldObject["fileType"] = objectValue.node.fileType;
            mcFieldObject["status"] = "done";

            if (objectValue.node.fileType === "pic") {
              imageArr.push(mcFieldObject);
              galleryImageArr.push({
                original: objectValue.node.awsFileUrl,
                thumbnail: objectValue.node.awsFileUrl,
              });
            } else {
              docArr.push(mcFieldObject);
            }
            return null;
          });
          this.setState({
            apiLoading: false,
            uploadedDocuments: docArr,
            uploadedImages: imageArr,
            galleryProductImages: galleryImageArr,
          });
        } else {
          this.setState({
            apiLoading: false,
            uploadedDocuments: [],
            uploadedImages: [],
            galleryProductImages: [],
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  confirmDelete = async (documentObj) => {
    const { client } = this.props;
    await client
      .mutate({
        mutation: DELETE_PRODUCT_DOCUMENT_QUERY,
        variables: {
          productfilesId: documentObj.id,
          productFile: documentObj.url,
        },
      })
      .then(() => {
        this.getProductDocument(this.state.productRecordId);
        SuccessNotificationMsg("Success", "Document deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadDocument = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_PRODUCT_DOCUMENT_QUERY,
        variables: {
          productFile: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadProductFile.presignedUrl !== "") {
          window.open(response.data.downloadProductFile.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  showImageGallery = () => {
    this.setState({
      showGallery: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      showGallery: false,
    });
  };

  handleCloseImageGallery = (e) => {
    this.setState({
      showGallery: false,
    });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
    };
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Product Documents
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/admin-manage-product">Manage Product</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Product Documents</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="documents">
                  <h3>Product Pictures</h3>
                  <Spin spinning={this.state.apiLoading}>
                    <Row gutter={[16]}>
                      {this.state.uploadedImages.map((document, index) => {
                        return (
                          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                            <div className="imgdiv photo">
                              <div className="img_wrap">
                                <img
                                  src={document.url}
                                  alt={document.productfilesId}
                                ></img>
                              </div>
                              <h5>{document.name}</h5>
                              <div className="btnwrp">
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<ZoomInOutlined />}
                                  onClick={this.showImageGallery}
                                ></Button>

                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DownloadOutlined />}
                                  onClick={() =>
                                    this.downloadDocument(document)
                                  }
                                ></Button>

                                <Popconfirm
                                  className="action"
                                  title="Are you sure delete this document ?"
                                  onConfirm={() => this.confirmDelete(document)}
                                  okText="Yes"
                                  placement="left"
                                >
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Popconfirm>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <ImgCrop rotate={this.state.rotate} aspect={3 / 4}>
                          <Upload
                            {...uploadProps}
                            customRequest={this.uploadProductFile}
                            accept=".jpg,.png,.jpeg"
                          >
                            <Button icon={<PlusCircleOutlined />}></Button>
                          </Upload>
                        </ImgCrop>
                      </Col>
                    </Row>
                  </Spin>

                  <Modal
                    // title="Product Picture Gallery"
                    visible={this.state.showGallery}
                    onCancel={this.handleCloseImageGallery}
                    width={1000}
                    centered
                    footer={null}
                  >
                    <ImageGallery
                      items={this.state.galleryProductImages}
                      showThumbnails={false}
                      showFullscreenButton={false}
                    />
                  </Modal>
                </div>

                <div className="documents">
                  <h3>Product Files</h3>
                  <Spin spinning={this.state.apiLoading}>
                    <Row gutter={[16]}>
                      {this.state.uploadedDocuments.map((document, index) => {
                        return (
                          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                            <div className="imgdiv files">
                              <div className="img_wrap">
                                <img src={fileImg} alt=""></img>
                              </div>
                              <h5>{document.name}</h5>
                              <div className="btnwrp">
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DownloadOutlined />}
                                  onClick={() =>
                                    this.downloadDocument(document)
                                  }
                                ></Button>

                                <Popconfirm
                                  className="action"
                                  title="Are you sure delete this document ?"
                                  onConfirm={() => this.confirmDelete(document)}
                                  okText="Yes"
                                  placement="left"
                                >
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Popconfirm>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Upload
                          {...uploadProps}
                          customRequest={this.uploadProductDoc}
                          // defaultFileList={this.state.uploadedDocuments}
                          accept=".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd"
                        >
                          <Button icon={<PlusCircleOutlined />}></Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AdminProductDocument);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import ImgCrop from "antd-img-crop";
import {
  Row,
  Col,
  Upload,
  Button,
  Form,
  Divider,
  Space,
  Popconfirm,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileImg from "../../../../images/file-icon.png";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../../utils/NotificationHelper";

import {
  UPLOAD_PRODUCT_DOCUMENTS,
  GET_PRODUCT_DOCUMENT_QUERY,
  DELETE_PRODUCT_DOCUMENT_QUERY,
  DOWNLOAD_PRODUCT_DOCUMENT_QUERY,
} from "../ProductDocumentQuery";

import {
  UPLOAD_SUPPLYCHAIN_DOCUMENTS,
  GET_SUPPLYCHAIN_DOCUMENT_QUERY,
  DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
  DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
} from "../../supplychain/SupplychainDocumentQuery";

class FormSubmit extends Component {
  state = {
    uploadedDocuments: [],
    uploadedImages: [],
    uploadedProductDocuments: [],
    apiLoading: false,
  };

  componentDidMount() {
    this.getSupplychainDocument(this.props.stateValues.supplyChainId);
    this.getProductDocument(this.props.stateValues.productRecordId);
  }

  onFinish = (values) => {
    this.props.onClickSubmit();
  };

  uploadProductDocument = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    client
      .mutate({
        mutation: UPLOAD_PRODUCT_DOCUMENTS,
        variables: {
          productId: this.props.stateValues.productRecordId,
          productFiles: file,
          fileType: "doc",
        },
      })
      .then((response) => {
        let uploadedDocumentStat = this.state.uploadedProductDocuments;
        uploadedDocumentStat.push({
          id: response.data.uploadProductFile.productfile.productfilesId,
          name: response.data.uploadProductFile.productfile.productfileName,
          url: response.data.uploadProductFile.presignedUrl,
          status: "done",
        });
        this.setState({ uploadedProductDocuments: uploadedDocumentStat });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  uploadProductPicture = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    client
      .mutate({
        mutation: UPLOAD_PRODUCT_DOCUMENTS,
        variables: {
          productId: this.props.stateValues.productRecordId,
          productFiles: file,
          fileType: "pic",
        },
      })
      .then((response) => {
        let uploadedImageStat = this.state.uploadedImages;
        uploadedImageStat.push({
          id: response.data.uploadProductFile.productfile.productfilesId,
          name: response.data.uploadProductFile.productfile.productfileName,
          url: response.data.uploadProductFile.presignedUrl,
          status: "done",
        });
        this.setState({ uploadedImages: uploadedImageStat });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  uploadSupplychainDoc = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    this.setState({ ImageLoading: true });
    client
      .mutate({
        mutation: UPLOAD_SUPPLYCHAIN_DOCUMENTS,
        variables: {
          supplyChainId: this.props.stateValues.supplyChainId,
          supplychainDocuments: file,
        },
      })
      .then((response) => {
        let uploadedDocStat = this.state.uploadedDocuments;
        uploadedDocStat.push({
          id:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainfilesId,
          name:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainFilename,
          url: response.data.uploadSupplychainDocument.presignedUrl,
          status: "done",
        });

        this.setState({ uploadedDocuments: uploadedDocStat });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  getSupplychainDocument = (supplyChainRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          deleted: 0,
          supplyChainId: supplyChainRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        if (response.data.allSupplychainfiles.edges.length > 0) {
          response.data.allSupplychainfiles.edges.map(
            (objectValue, objectKey) => {
              let mcFieldObject = {};
              mcFieldObject["id"] = objectValue.node.supplychainfilesId;
              mcFieldObject["name"] = objectValue.node.supplychainFilename;
              mcFieldObject["url"] = objectValue.node.awsFileUrl;
              mcFieldObject["status"] = "done";
              mcStatArr.push(mcFieldObject);
              return null;
            }
          );
          this.setState({ uploadedDocuments: mcStatArr, supplyChainRecordId });
        } else {
          this.setState({ uploadedDocuments: [], supplyChainRecordId });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  confirmSuppDocDelete = async (documentObj) => {
    const { client } = this.props;
    await client
      .mutate({
        mutation: DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainfilesId: documentObj.id,
          supplychainDocument: documentObj.url,
        },
      })
      .then(() => {
        this.getSupplychainDocument(this.state.supplyChainRecordId);
        SuccessNotificationMsg("Success", "Document deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadSuppDocument = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainDocument: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadSupplychainDocument.presignedUrl !== "") {
          window.open(response.data.downloadSupplychainDocument.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  getProductDocument = (productRecordId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_PRODUCT_DOCUMENT_QUERY,
        variables: {
          deleted: 0,
          productId: productRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let docArr = [];
        let imageArr = [];

        if (response.data.allProductfiles.edges.length > 0) {
          response.data.allProductfiles.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["id"] = objectValue.node.productfilesId;
            mcFieldObject["name"] = objectValue.node.productfileName;
            mcFieldObject["url"] = objectValue.node.awsFileUrl;
            mcFieldObject["thumbUrl"] = objectValue.node.awsFileUrl;
            mcFieldObject["fileType"] = objectValue.node.fileType;
            mcFieldObject["status"] = "done";

            if (objectValue.node.fileType === "pic") {
              imageArr.push(mcFieldObject);
            } else {
              docArr.push(mcFieldObject);
            }
            return null;
          });
          this.setState({
            apiLoading: false,
            uploadedProductDocuments: docArr,
            uploadedImages: imageArr,
            productRecordId,
          });
        } else {
          this.setState({
            apiLoading: false,
            uploadedProductDocuments: [],
            uploadedImages: [],
            productRecordId,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  confirmDelete = async (documentObj) => {
    const { client } = this.props;
    await client
      .mutate({
        mutation: DELETE_PRODUCT_DOCUMENT_QUERY,
        variables: {
          productfilesId: documentObj.id,
          productFile: documentObj.url,
        },
      })
      .then(() => {
        this.getProductDocument(this.state.productRecordId);
        SuccessNotificationMsg("Success", "Document deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadDocument = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_PRODUCT_DOCUMENT_QUERY,
        variables: {
          productFile: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadProductFile.presignedUrl !== "") {
          window.open(response.data.downloadProductFile.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
    };
    return (
      <React.Fragment>
        <Form onFinish={this.onFinish}>
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}></Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={24} xl={24}>
              <h2>Product</h2>
              <label>Upload Product Documentation</label>
              <div className="documents supplychaindoc addfileinform step-doc">
                <Form.Item>
                  <Upload
                    {...uploadProps}
                    customRequest={this.uploadProductDocument}
                    accept=".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd"
                    // fileList={this.state.uploadedDocuments}
                  >
                    <Button size="small">
                      <PlusCircleOutlined /> Add File
                    </Button>
                  </Upload>
                </Form.Item>
                <Row gutter={[16]}>
                  {this.state.uploadedProductDocuments.map(
                    (document, index) => {
                      return (
                        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                          <div className="imgdiv files">
                            <div className="img_wrap">
                              <img src={fileImg} alt=""></img>
                            </div>
                            <h5>{document.name}</h5>
                            <div className="btnwrp">
                              <Button
                                type="primary"
                                htmlType="button"
                                size="small"
                                icon={<DownloadOutlined />}
                                onClick={() => this.downloadDocument(document)}
                              ></Button>

                              <Popconfirm
                                className="action"
                                title="Are you sure delete this document ?"
                                onConfirm={() => this.confirmDelete(document)}
                                okText="Yes"
                                placement="left"
                              >
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DeleteOutlined />}
                                ></Button>
                              </Popconfirm>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </Row>
              </div>
            </Col>

            <Col xs={24} sm={24} lg={24} xl={24}>
              <h2>Supplychain</h2>
              <Form.Item>
                <label>
                  Please upload any documents with info on your supply chain
                </label>
                <div className="documents supplychaindoc addfileinform step-doc">
                  <Upload
                    {...uploadProps}
                    customRequest={this.uploadSupplychainDoc}
                    accept=".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd"
                    // fileList={this.state.uploadedDocuments}
                  >
                    <Button size="small">
                      <PlusCircleOutlined /> Add File
                    </Button>
                  </Upload>

                  <Row gutter={[16]}>
                    {this.state.uploadedDocuments.map((document, index) => {
                      return (
                        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                          <div className="imgdiv files">
                            <div className="img_wrap">
                              <img src={fileImg} alt=""></img>
                            </div>
                            <h5>{document.name}</h5>

                            <div className="btnwrp">
                              <Button
                                type="primary"
                                htmlType="button"
                                size="small"
                                icon={<DownloadOutlined />}
                                onClick={() =>
                                  this.downloadSuppDocument(document)
                                }
                              ></Button>

                              <Popconfirm
                                className="action"
                                title="Are you sure delete this document ?"
                                onConfirm={() =>
                                  this.confirmSuppDocDelete(document)
                                }
                                okText="Yes"
                                placement="left"
                              >
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DeleteOutlined />}
                                ></Button>
                              </Popconfirm>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <p className="note">
                    Examples of relevant documentation are Production
                    certificates, LCAs, or other documents containing technical
                    composition.
                  </p>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={24} xl={24}>
              <h2>Product Pictures</h2>
              <label>Please upload picture of your product</label>
              <div className="documents supplychaindoc addfileinform step-doc">
                <Form.Item>
                  <ImgCrop rotate={this.state.rotate} aspect={3 / 4}>
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadProductPicture}
                      accept=".jpg,.png,.jpeg"
                      // fileList={this.state.uploadedDocuments}
                    >
                      <Button size="small">
                        <PlusCircleOutlined /> Add File
                      </Button>
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Row gutter={[16]}>
                  {this.state.uploadedImages.map((document, index) => {
                    return (
                      <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                        <div className="imgdiv photo">
                          <div className="img_wrap">
                            <img
                              src={document.url}
                              alt={document.productfilesId}
                            ></img>
                          </div>
                          <h5>{document.name}</h5>
                          <div className="btnwrp">
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              icon={<DownloadOutlined />}
                              onClick={() => this.downloadDocument(document)}
                            ></Button>

                            <Popconfirm
                              className="action"
                              title="Are you sure delete this document ?"
                              onConfirm={() => this.confirmDelete(document)}
                              okText="Yes"
                              placement="left"
                            >
                              <Button
                                type="primary"
                                htmlType="button"
                                size="small"
                                icon={<DeleteOutlined />}
                              ></Button>
                            </Popconfirm>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>

          <Divider />
          <Row
            gutter={[15]}
            align="end"
            justify="space-between"
            className="bottomButtons"
          >
            <Col>
              <Space>
                <Button
                  type="secondary"
                  htmlType="button"
                  size="large"
                  onClick={() => this.props.history.push("/seller-get-support")}
                >
                  ASK SUPPORT
                </Button>
              </Space>
            </Col>
            <Col>
              <Space>
                <Button
                  type="secondary"
                  htmlType="submit"
                  size="large"
                  onClick={this.props.onClickBack}
                >
                  BACK
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={this.props.onClickSubmit}
                >
                  SUBMIT
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default withApollo(FormSubmit);

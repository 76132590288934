import gql from "graphql-tag";

export const CREATE_API_CONFIG_QUERY = gql`
  mutation createApiconfig(
    $authUserId: Int
    $apiKey: String!
    $secretKey: String!
    $pwd: String!
    $apiendPoint: String!
    $platform: String!
  ) {
    createApiconfig(
      input: {
        userId: $authUserId
        apiKey: $apiKey
        secretKey: $secretKey
        pwd: $pwd
        apiendPoint: $apiendPoint
        platform: $platform
      }
    ) {
      apiconfig {
        id
        apiconfigId
        apiKey
        secretKey
        apiendPoint
        platform
        pwd
      }
    }
  }
`;

export const UPDATE_API_CONFIG_QUERY = gql`
  mutation updateApiconfig(
    $apiconfigId: Int!
    $apiKey: String!
    $secretKey: String!
    $pwd: String!
    $apiendPoint: String!
    $platform: String!
    $authUserId: Int
  ) {
    updateApiconfig(
      input: {
        apiconfigId: $apiconfigId
        apiKey: $apiKey
        secretKey: $secretKey
        pwd: $pwd
        apiendPoint: $apiendPoint
        platform: $platform
        userId: $authUserId
      }
    ) {
      apiconfig {
        id
        apiconfigId
        apiKey
        secretKey
        apiendPoint
        platform
        pwd
      }
    }
  }
`;

export const GET_API_CONFIG_DETAIL_QUERY = gql`
  query allApiConfig($deleted: Int, $apiconfigId: Int, $userId: Int) {
    allApiConfig(deleted: $deleted, apiconfigPk: $apiconfigId, userId : $userId) {
      totalCount
      edges {
        node {
          id
          apiconfigId
          apiKey
          secretKey
          apiendPoint
          platform
          pwd
          userId {
            appusersId
          }
        }
      }
    }
  }
`;



import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Button,
  Breadcrumb,
  Upload,
  Popconfirm,
  Spin,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Sidebar from "../common/Sidebar";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import {
  UPLOAD_SUPPLYCHAIN_DOCUMENTS,
  GET_SUPPLYCHAIN_DOCUMENT_QUERY,
  DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
  DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
} from "./SupplychainDocumentQuery";

import "../dashboard/Dashboard.scss";
import fileImg from "../../../images/file-icon.png";
const { Content } = Layout;

class SupplyChainDocument extends Component {
  state = {
    uploadedDocuments: [],
    apiLoading: false,
  };

  componentDidMount() {
    let supplyChainRecordId = this.props.match.params.supplychainId
      ? atob(this.props.match.params.supplychainId)
      : 0;

    if (supplyChainRecordId > 0) {
      this.getSupplychainDocument(supplyChainRecordId);
      this.setState({ supplyChainRecordId });
    }
  }

  uploadSupplychainDoc = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    client
      .mutate({
        mutation: UPLOAD_SUPPLYCHAIN_DOCUMENTS,
        variables: {
          supplyChainId: this.state.supplyChainRecordId,
          supplychainDocuments: file,
        },
      })
      .then((response) => {
        let uploadedDocumentStat = this.state.uploadedDocuments;
        uploadedDocumentStat.push({
          id:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainfilesId,
          name:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainFilename,
          url: response.data.uploadSupplychainDocument.presignedUrl,
          status: "done",
        });

        this.setState({ uploadedDocuments: uploadedDocumentStat });
        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  getSupplychainDocument = (supplyChainRecordId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          deleted: 0,
          supplyChainId: supplyChainRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allSupplychainfiles.edges.length > 0) {
          response.data.allSupplychainfiles.edges.map(
            (objectValue, objectKey) => {
              let mcFieldObject = {};
              mcFieldObject["id"] = objectValue.node.supplychainfilesId;
              mcFieldObject["name"] = objectValue.node.supplychainFilename;
              mcFieldObject["url"] = objectValue.node.awsFileUrl;
              mcFieldObject["status"] = "done";
              mcStatArr.push(mcFieldObject);
              return null;
            }
          );
          this.setState({ apiLoading: false, uploadedDocuments: mcStatArr });
        } else {
          this.setState({ apiLoading: false, uploadedDocuments: [] });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  confirmDelete = async (documentObj) => {
    const { client } = this.props;
    await client
      .mutate({
        mutation: DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainfilesId: documentObj.id,
          supplychainDocument: documentObj.url,
        },
      })
      .then(() => {
        this.getSupplychainDocument(this.state.supplyChainRecordId);
        SuccessNotificationMsg("Success", "Document deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadDocument = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainDocument: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadSupplychainDocument.presignedUrl !== "") {
          window.open(response.data.downloadSupplychainDocument.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
    };

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Supply Chain Documents
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/seller-manage-supplychain">
                      Manage Supplychain
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Supply Chain Documents</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="documents supplychaindoc">
                  <Spin spinning={this.state.apiLoading}>
                    <Row gutter={[16]}>
                      {this.state.uploadedDocuments.map((document, index) => {
                        return (
                          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                            <div className="imgdiv files">
                              <div className="img_wrap">
                                <img src={fileImg} alt=""></img>
                              </div>
                              <h5>{document.name}</h5>

                              <div className="btnwrp">
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DownloadOutlined />}
                                  onClick={() =>
                                    this.downloadDocument(document)
                                  }
                                ></Button>

                                <Popconfirm
                                  className="action"
                                  title="Are you sure delete this document ?"
                                  onConfirm={() => this.confirmDelete(document)}
                                  okText="Yes"
                                  placement="left"
                                >
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Popconfirm>
                              </div>
                            </div>
                          </Col>
                        );
                      })}

                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Upload
                          {...uploadProps}
                          customRequest={this.uploadSupplychainDoc}
                          accept=".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd"
                          // fileList={this.state.uploadedDocuments}
                        >
                          <Button icon={<PlusCircleOutlined />}></Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(SupplyChainDocument);

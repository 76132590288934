import React from "react";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form, Divider, Space, Checkbox } from "antd";
import Password from "antd/lib/input/Password";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import {
  CREATE_BRAND_USER_QUERY,
  CREATE_BRAND_ONBOARDING_QUERY,
} from "../brand/common/ProfileQuery";

const { TextArea } = Input;
class BrandRegister extends React.Component {
  state = {
    btnLoading: false,
    accept_terms_condition: false,
  };

  formRef = React.createRef();

  onFinish = (values) => {
    let { firstName, lastName, password, email } = this.state;
    let phoneNumber =
      this.state.phoneNumber.startsWith("+") === true
        ? this.state.phoneNumber
        : "+" + this.state.phoneNumber;

    this.setState({ btnLoading: true });

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${firstName} ${lastName}`,
        phone_number: phoneNumber,
      },
    })
      .then((response) => {
        this.setState({
          cognitoId: response.userSub,
          phone: this.state.phoneNumber,
          userType: "brand",
          newsletter: false,
        });
        return this.saveBrandUser();
      })
      .then((response) => {
        return this.saveBrandOnBoarding(
          response.data.createAppUser.appuser.appusersId
        );
      })
      .then((saveonBoardResponse) => {
        SuccessNotificationMsg(
          "Brand registered successfully!",
          "Please check mail and verify your account!"
        );

        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        this.props.history.push("/verify-account", { email: this.state.email });
      })
      .catch((err) => {
        //console.log("sssss", err);
        ErrorNotificationMsg("Error", err.message);
        this.setState({
          btnLoading: false,
        });
      });
  };

  saveBrandUser = () => {
    const { client } = this.props;
    return client.mutate({
      mutation: CREATE_BRAND_USER_QUERY,
      variables: this.state,
    });
  };

  saveBrandOnBoarding = (appusersId) => {
    const { client } = this.props;
    this.setState({ userId: appusersId });
    return client.mutate({
      mutation: CREATE_BRAND_ONBOARDING_QUERY,
      variables: this.state,
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState({ [input]: event.target.checked });
  };

  render() {
    return (
      <div className="authPage">
        <div className="container">
          <div className="reg_wraper">
            <div className="pagename">Brand Registration</div>

            <p>
              Hello! we are excited to work with you. please help us prepare for
              your arrival
              <br />
              by reviewing and addressing the questions below.
            </p>

            <Form
              onFinish={this.onFinish}
              ref={this.formRef}
              autoComplete="off"
            >
              <Row gutter={[15]}>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name *"
                      onChange={this.handleInputChange("firstName")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name *"
                      onChange={this.handleInputChange("lastName")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        whitespace: true,
                        message: "The input is not valid e-mail address!",
                      },
                      {
                        required: true,
                        message: "Please input e-mail address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="E-mail*"
                      onChange={this.handleInputChange("email")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="company"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input company name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Company *"
                      onChange={this.handleInputChange("company")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp("^[0-9+]{9,15}$"),
                        message: "Please input phone number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone No * (Example: +31203699863)"
                      onChange={this.handleInputChange("phoneNumber")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="website"
                    rules={[
                      {
                        required: true,
                        type: "url",
                        message:
                          "Please input website, including full HTTPS link!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Website *"
                      onChange={this.handleInputChange("website")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input country!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Country *"
                      onChange={this.handleInputChange("country")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input password!",
                      },
                      () => ({
                        validator(rule, value) {
                          if (
                            !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!_%*#?&])[A-Za-z\d@$!_%*#?&]{6,}$/.test(
                              value
                            )
                          ) {
                            return Promise.reject(
                              "Password should be minimum six characters, at least one letter and one number and one special character."
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Password
                      placeholder="Password *"
                      onChange={this.handleInputChange("password")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please input confirm password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Password
                      placeholder="Confirm Password *"
                      onChange={this.handleInputChange("confirm_password")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input city!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="City *"
                      onChange={this.handleInputChange("city")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={0} lg={16}></Col>

                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="aboutBrand"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input about brand!",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="About Brand *"
                      onChange={this.handleInputChange("aboutBrand")}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={12}>
                  <Form.Item name="remarks">
                    <TextArea
                      placeholder="Remarks"
                      onChange={this.handleInputChange("remarks")}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 0]}>
                <Col style={{ float: "left" }}>
                  <Form.Item
                    name="accept_terms_condition"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        type: "boolean",
                        message: "Please accept the terms and conditions!",
                      },
                    ]}
                  >
                    <Checkbox
                      checked={this.state.accept_terms_condition}
                      onChange={this.handleCheckboxChange(
                        "accept_terms_condition"
                      )}
                    >
                      Agree to TrueCostLabel&nbsp;
                      <a
                        href="https://tcl-terms-n-conditions.s3.amazonaws.com/TCL+Terms_%26_Conditions.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Row gutter={[15]} className="bottomButtons">
                <Col xs={24} align="end">
                  <Space>
                    <Button
                      type="secondary"
                      htmlType="button"
                      onClick={this.props.history.goBack}
                    >
                      BACK
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.btnLoading}
                    >
                      SUBMIT
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(BrandRegister);

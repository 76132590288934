import moment from "moment";
import _ from "lodash";
const jwtDecode = require("jwt-decode");

export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }

  try {
    const decodedJwt: any = jwtDecode(token);
    const isTokenValid = decodedJwt.exp >= Date.now() / 1000;

    if (!isTokenValid) {
      localStorage.clear();
      localStorage.setItem("websiteVisited", true);
    }

    return isTokenValid;
  } catch (e) {
    return false;
  }
};

export const updateQueryString = () => {};

export const logout = () => {
  localStorage.clear();
  localStorage.setItem("websiteVisited", true);
  window.location.href = "/";
};

export const authUserData = () => {
  let authData = localStorage.getItem("auth_data");
  return JSON.parse(authData);
};

export const redirectIfNotBrand = () => {
  let authData = authUserData();

  if (authData === null) {
    window.location.href = "/login";
  }

  if (authData.userType === "admin" && authData.appusersId !== "") {
    window.location.href = "/admin-dashboard";
  }

  if (authData.userType === "consumer" && authData.appusersId !== "") {
    window.location.href = "/";
  }
};

export const redirectIfNotAdmin = () => {
  let authData = authUserData();

  if (authData === null) {
    window.location.href = "/login";
  }

  if (authData.userType === "brand" && authData.appusersId !== "") {
    window.location.href = "/seller-dashboard";
  }

  if (authData.userType === "consumer" && authData.appusersId !== "") {
    window.location.href = "/";
  }
};

export const redirectIfLoggedIn = () => {
  let authData = authUserData();

  if (authData !== null && authData !== undefined) {
    if (authData.userType === "brand" && authData.appusersId !== "") {
      window.location.href = "/seller-dashboard";
    }

    if (authData.userType === "admin" && authData.appusersId !== "") {
      window.location.href = "/admin-dashboard";
    }

    if (authData.userType === "consumer" && authData.appusersId !== "") {
      window.location.href = "/";
    }
  }
};

export const ImageUploadValidation = (file) => {
  let errorMsg = "";
  let isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    errorMsg = "You can only upload JPG/PNG file!";
  }

  let isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    errorMsg = "Image must smaller than 2MB!";
  }
  return errorMsg;
};

export const ToDateTime = (dateTimeValue) => {
  return moment(dateTimeValue).format("Do MMMM YYYY, H:mm");
};

export const ShowTextByCharLength = (textInput, limit) => {
  return textInput && textInput !== undefined && textInput.length > limit
    ? textInput.substring(0, limit) + "..."
    : textInput;
};

export function cleanEdgesNode(data, columnName, sort) {
  let objects = [];
  let object = [];
  let response = {};

  if (!data) {
    return object;
  }

  Object.keys(data).forEach((key) => {
    if (key !== "edges") {
      response[key] = data[key];
    }
  });

  if (data.edges) {
    objects = data.edges;

    objects.forEach((d, i) => {
      let m = d["node"];
      object.push(m);
    });

    response["content"] = object;
  }

  if (!data.success) {
    if (data.errors) {
      objects = data.errors["nonFieldErrors"];
      object = Array.isArray(objects) ? objects[0] : objects;
      delete response.errors;
      response["errors"] = object;
    }
  }
  if (!!columnName && !!sort) {
    response["content"] = _.sortBy(response["content"], [columnName]);
  }

  return response;
}

export const GetClientOriginDetails = () => {
  return fetch("https://ip.nf/me.json").then((res) => res.json());
};

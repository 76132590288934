import React, { Component } from "react";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

import Config from "./Config";
import Routes from "./components/common/Routes";
import TagManager from "react-gtm-module";
import CookieBot from "react-cookiebot";

const tagManagerArgs = {
  gtmId: Config.GOOGLE_TAG_ID,
  events: {
    sendUserInfo: "userInfo",
  },
  dataLayerName: "PageDataLayer",
};

const apolloCache = new InMemoryCache();
const uploadLink = createUploadLink({
  uri: Config.API_URL,
  headers: {
    "keep-alive": "true",
  },
});

const client = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const history = createBrowserHistory();

if (Config.ENV === "PROD") {
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(Config.REACT_APP_GOOGLE_ANALYTICAL_CODE);

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    window.scrollTo(0, 0);
  });
} else {
  history.listen(() => {
    window.scrollTo(0, 0);
  });
}

export default class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router history={history}>
          <CookieBot domainGroupId={Config.DOMAIN_GROUP_ID_COOKIE_BOT} />
          <Routes />
        </Router>
      </ApolloProvider>
    );
  }
}

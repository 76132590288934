import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Input,
  Breadcrumb,
  Popconfirm,
  Select,
} from "antd";
import {
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData, ToDateTime } from "../../../utils/Helpers";
import Sidebar from "../common/AdminSidebar";
import { BRAND_LIST_QUERY, DELETE_BRAND_QUERY } from "./BrandQuery";

import profileImg from "../../../images/company-noimg.png";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class ManageBrand extends Component {
  state = {
    data: [],
    loading: false,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-appusers_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0, userType: "brand" },
  };

  componentDidMount() {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });

    this.searchCallback = debounce(function (e) {
      this.searchBrand(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: orderBy,
      first: this.state.perPageRecord,
      brandNa: false
    });
  };

  fetchBrandList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: BRAND_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allAppuser.edges,
          nextPageId: result.data.allAppuser.pageInfo.endCursor,
          previousPageId: result.data.allAppuser.pageInfo.startCursor,
          totalRecords: result.data.allAppuser.filterCount,
          totalPage: Math.ceil(
            result.data.allAppuser.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
      brandNa: false
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
      brandNa: false
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchBrandList({
      deleted: 0,
      userType: "brand",
      first: e,
      brandNa: false
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchBrand = (searchText) => {
    if (searchText !== "") {
      this.fetchBrandList({
        search: searchText,
        deleted: 0,
        userType: "brand",
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
        brandNa: false
      });

      this.setState({ searchText, currentPageNo: 1 });
    } else {
      this.fetchBrandList({
        deleted: 0,
        userType: "brand",
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
        brandNa: false
      });

      this.setState({ searchText: "" });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "-appusers_id";

    if (sortField === "firstName" && sortOrder === "ascend") {
      sortingEnumKey = "first_name";
    }
    if (sortField === "firstName" && sortOrder === "descend") {
      sortingEnumKey = "-first_name";
    }
    if (sortField === "lastName" && sortOrder === "ascend") {
      sortingEnumKey = "last_name";
    }
    if (sortField === "lastName" && sortOrder === "descend") {
      sortingEnumKey = "-last_name";
    }
    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = "email";
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = "-email";
    }
    if (sortField === "phone" && sortOrder === "ascend") {
      sortingEnumKey = "phone";
    }
    if (sortField === "phone" && sortOrder === "descend") {
      sortingEnumKey = "-phone";
    }
    return sortingEnumKey;
  }

  confirmDelete = async (appusersId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_BRAND_QUERY,
        variables: { appusersId: appusersId },
      })
      .then((result) => {
        this.fetchBrandList({
          deleted: 0,
          userType: "brand",
          orderBy: this.state.orderBy,
          first: this.state.perPageRecord,
          brandNa: false
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg("Success", "Brand deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "Brand",
        key: "imgurl",
        sorter: false,
        render: (node) => (
          <div className="brandimg">
            <img
              alt={
                node.node.onboardingmodelSet.edges[0]
                  ? node.node.onboardingmodelSet.edges[0].node.company
                  : ""
              }
              src={
                node.node.brandlogomodelSet &&
                node.node.brandlogomodelSet.edges.length > 0
                  ? node.node.brandlogomodelSet.edges[0].node.awsFileUrl
                  : profileImg
              }
            />
          </div>
        ),
        width: 100,
      },
      {
        title: "Brand ID",
        key: "appusersId",
        sorter: false,
        render: (node) => `${node.node.appusersId}`,
        width: 100,
      },
      {
        title: "Brand Name",
        key: "brandName",
        sorter: false,
        render: (node) =>
          `${
            node.node.onboardingmodelSet.edges[0]
              ? node.node.onboardingmodelSet.edges[0].node.company
              : ""
          }`,
        width: 230,
      },
      {
        title: "First Name",
        key: "firstName",
        sorter: true,
        render: (node) => `${node.node.firstName}`,
        width: 140,
        ellipsis: true,
      },
      {
        title: "Last Name",
        key: "lastName",
        sorter: true,
        render: (node) => `${node.node.lastName}`,
        width: 140,
        ellipsis: true,
      },
      {
        title: "Email",
        key: "email",
        sorter: true,
        render: (node) => `${node.node.email}`,
        width: 200,
        ellipsis: true,
      },
      {
        title: "Phone",
        key: "phone",
        sorter: true,
        render: (node) => `${node.node.phone}`,
        width: 160,
        ellipsis: true,
      },
      {
        title: "Created At",
        key: "createdAt",
        sorter: false,
        render: (node) => ToDateTime(node.node.createdAt),
        width: 200,
        ellipsis: true,
      },
      {
        width: 100,
        fixed: "right",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/admin-edit-brand/" + btoa(node.node.appusersId)}
              className="action"
            >
              <EditOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure delete this brand ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.appusersId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Brand
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Brands</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>Brands</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>

                          <Link to="/admin-add-brand">
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              icon={<PlusCircleOutlined />}
                            >
                              ADD Brand
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 1600 }}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>

                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageBrand);

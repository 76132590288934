import React, { Component } from "react";
import { Row, Col, Collapse, Space, Spin } from "antd";
import { withApollo } from "react-apollo";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { FAQ_LIST_QUERY } from "../../admin/faq/FaqQuery";

const { Panel } = Collapse;

export class Faq extends Component {
  state = {
    data: [],
    apiLoading: false,
  };

  componentDidMount() {
    this.fetchFAQList();
  }

  fetchFAQList = () => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: FAQ_LIST_QUERY,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          apiLoading: false,
          data: result.data.allFaq.edges,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  createMarkup(data) {
    return { __html: data };
  }

  render() {
    const { apiLoading, data } = this.state;
    return (
      <div className="container">
        <div className="faqpage">
        <h1>Frequently Asked Questions</h1>
        <Spin spinning={apiLoading}>
          <Row >
            <Col xs={24} sm={24} md={24}>
              <Space direction="vertical">
                {data.length > 0
                  ? data.map((faq, index) => {
                      return (
                        <Collapse
                          expandIconPosition="right"
                          collapsible="header"
                          defaultActiveKey={["0"]}
                        >
                          <Panel header={faq.node.question} key={index}>
                            <p
                              dangerouslySetInnerHTML={this.createMarkup(
                                faq.node.answer
                              )}
                            />
                          </Panel>
                        </Collapse>
                      );
                    })
                  : ""}
              </Space>
            </Col>
          </Row>
        </Spin>
      </div>
      </div>
    );
  }
}

export default withApollo(Faq);

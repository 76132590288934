import gql from "graphql-tag";

export const ADD_FAQ_QUERY = gql`
  mutation createFaq($question: String, $answer: String, $authUserId: Int) {
    createFaq(
      input: { question: $question, answer: $answer, userId: $authUserId }
    ) {
      faq {
        faqId
      }
    }
  }
`;

export const UPDATE_FAQ_QUERY = gql`
  mutation updateFaq(
    $question: String
    $answer: String
    $authUserId: Int
    $faqId: Int
  ) {
    updateFaq(
      input: {
        question: $question
        answer: $answer
        userId: $authUserId
        faqId: $faqId
      }
    ) {
      faqUpdate {
        faqId
      }
    }
  }
`;

export const FAQ_LIST_QUERY = gql`
  query allFaq(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $search: String
    $orderBy: [String]
    $faqId: Int
  ) {
    allFaq(
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      search: $search
      orderBy: $orderBy
      faqPk: $faqId
    ) {
      totalCount
      filterCount
      edges {
        node {
          faqId
          question
          answer
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_FAQ_QUERY = gql`
  mutation deleteFaq($faqId: Int!) {
    deleteFaq(input: { faqId: $faqId }) {
      faqId
    }
  }
`;

import React, { Component } from "react";
import { Button, Skeleton, Carousel } from "antd";
import { withApollo } from "react-apollo";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
const LatestBlogSkeleton = () => {
  return (
    <div className="prowrap">
      <Skeleton.Image className="skeleton-img" />
    </div>
  );
};

const createMarkup = (data) => {
  return { __html: data };
};
export class LatestBlogPost extends Component {
  state = {
    loading: false,
    responseData: [],
  };

  componentDidMount() {
    this.fetchBlogPost();
  }

  fetchBlogPost = () => {
    this.setState({ loading: true });
    fetch(
      "https://blog.truecostlabel.com/wp-json/wp/v2/posts?per_page=10&_embed",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            responseData: data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        ErrorNotificationMsg("Error", error.message);
        this.setState({ loading: false });
      });
  };

  goToPost = (postLink) => {
    window.open(postLink);
  };

  render() {
    const { responseData, loading } = this.state;

    return (
      <React.Fragment>
        {loading ? (
          <LatestBlogSkeleton />
        ) : (
          responseData.length > 0 && (
            <Carousel
              className="blogslide"
              autoplay
              arrows
              prevArrow={<LeftCircleOutlined />}
              nextArrow={<RightCircleOutlined />}
            >
              {responseData.map((post, i) => {
                return (
                  <div className="newsdiv" key={i} aria-hidden="true">
                    <div className="imgdiv">
                      <img
                        alt="icon"
                        src={
                          post._embedded["wp:featuredmedia"][0].media_details
                            .sizes.medium.source_url
                        }
                      ></img>
                    </div>
                    <h3
                      dangerouslySetInnerHTML={createMarkup(
                        post.title.rendered
                      )}
                    ></h3>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="blkbtnsml"
                      onClick={() => this.goToPost(post.guid.rendered)}
                    >
                      Tell me more
                    </Button>
                  </div>
                );
              })}
            </Carousel>
          )
        )}
      </React.Fragment>
    );
  }
}

export default withApollo(LatestBlogPost);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Input,
  Breadcrumb,
  Popconfirm,
  Select,
} from "antd";
import {
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";

import Sidebar from "../common/AdminSidebar";
import {
  API_CONFIG_LIST_QUERY,
  DELETE_API_CONFIG_QUERY,
} from "./APIConfigQuery";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
class ManageAPI extends Component {
  state = {
    data: [],
    loading: false,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-apiconfig_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0 },
  };

  componentDidMount() {
    this.fetchApiConfigList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });

    this.searchCallback = debounce(function (e) {
      this.searchApiConfig(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchApiConfigList({
      deleted: 0,
      orderBy: orderBy,
      first: this.state.perPageRecord,
    });
  };

  fetchApiConfigList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: API_CONFIG_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allApiConfig.edges,
          nextPageId: result.data.allApiConfig.pageInfo.endCursor,
          previousPageId: result.data.allApiConfig.pageInfo.startCursor,
          totalRecords: result.data.allApiConfig.filterCount,
          totalPage: Math.ceil(
            result.data.allApiConfig.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchApiConfigList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchApiConfigList({
      deleted: 0,
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchApiConfigList({
      deleted: 0,
      first: e,
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchApiConfig = (searchText) => {
    if (searchText !== "") {
      this.fetchApiConfigList({
        search: searchText,
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText, currentPageNo: 1 });
    } else {
      this.fetchApiConfigList({
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText: "" });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "-apiconfig_id";

    if (sortField === "platform" && sortOrder === "ascend") {
      sortingEnumKey = "platform";
    }
    if (sortField === "platform" && sortOrder === "descend") {
      sortingEnumKey = "-platform";
    }
    if (sortField === "apiconfigId" && sortOrder === "ascend") {
      sortingEnumKey = "apiconfig_id";
    }
    if (sortField === "apiconfigId" && sortOrder === "descend") {
      sortingEnumKey = "-apiconfig_id";
    }
    if (sortField === "apiendPoint" && sortOrder === "ascend") {
      sortingEnumKey = "apiend_point";
    }
    if (sortField === "apiendPoint" && sortOrder === "descend") {
      sortingEnumKey = "-apiend_point";
    }
    return sortingEnumKey;
  }

  confirmDelete = async (apiconfigId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_API_CONFIG_QUERY,
        variables: { apiconfigId: apiconfigId },
      })
      .then((result) => {
        this.fetchApiConfigList({
          deleted: 0,
          orderBy: this.state.orderBy,
          first: this.state.perPageRecord,
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg("Success", "API config deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "API Config ID",
        key: "apiconfigId",
        sorter: true,
        render: (node) => `${node.node.apiconfigId}`,
        width: "15%",
      },
      {
        title: "Brand Name",
        key: "apiendPoint",
        sorter: false,
        render: (node) =>
          `${
            node.node.userId.onboardingmodelSet.edges.length > 0
              ? node.node.userId.onboardingmodelSet.edges[0].node.company
              : "-"
          }`,
        width: "28%",
        ellipsis: true,
      },

      {
        title: "API End Point",
        key: "apiendPoint",
        sorter: true,
        render: (node) => `${node.node.apiendPoint}`,
        width: "29%",
        ellipsis: true,
      },
      {
        title: "Platform",
        key: "platform",
        sorter: true,
        render: (node) => `${node.node.platform}`,
        width: "20%",
        ellipsis: true,
      },
      {
        width: "13%",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/admin-edit-api/" + btoa(node.node.apiconfigId)}
              className="action"
            >
              <EditOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure delete ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.apiconfigId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>

            <Link
              title="Sync & Import Product"
              to={{
                pathname: "/admin-import-api-products",
                state: {
                  platform: node.node.platform,
                  user_id: node.node.userId.appusersId,
                },
              }}
            >
              <SyncOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage API Config
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage API Config</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>API Configs</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>

                          <Link to="/admin-edit-api">
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              icon={<PlusCircleOutlined />}
                            >
                              ADD API Detail
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>

                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageAPI);

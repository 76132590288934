import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

const BlogSkeleton = () => {
  return (
    <div className="prowrap">
      <Skeleton.Button
        active
        className="skeleton20"
        shape="default"
      ></Skeleton.Button>
    </div>
  );
};

const createMarkup = (data) => {
  return { __html: data };
};

function FooterBlogPost() {
  let [loading, setLoading] = useState(false);
  let [responseData, setResponseData] = useState([]);

  const fetchBlogPost = React.useCallback(() => {
    setLoading(true);
    fetch(
      "https://blog.truecostlabel.com/wp-json/wp/v2/posts?per_page=10&_embed",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setResponseData(data);
        setLoading(false);
      })
      .catch((error) => {
        ErrorNotificationMsg("Error", error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchBlogPost();
  }, [fetchBlogPost]);

  return (
    <div>
      <h3>Latest Update</h3>
      {loading ? (
        <BlogSkeleton />
      ) : (
        responseData.map((post, i) => {
          return (
            <div className="updtaes_row" key={i}>
              <Link to={{ pathname: post.guid.rendered }} target="_blank">
                <h5
                  dangerouslySetInnerHTML={createMarkup(post.title.rendered)}
                ></h5>
              </Link>
              <span className="date">
                <CalendarOutlined /> {moment(post.date).format("MMMM Do, YYYY")}
              </span>
            </div>
          );
        })
      )}
    </div>
  );
}

export default FooterBlogPost;

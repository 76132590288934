import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form, Popconfirm, Select } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

class AdminAddImpactSize extends Component {
  render() {
    return (
      <>
        <Row gutter={[10]} className="addrow">
          <Col xs={12} sm={12} lg={5}>
            <label>Impact per size</label>
          </Col>
          <Col xs={6} sm={6} lg={4}>
            <label>Weight</label>
          </Col>
          <Col xs={6} sm={6} lg={4}>
            <label>kg CO2 </label>
          </Col>
          <Col xs={6} sm={6} lg={4}>
            <label>Liter</label>
          </Col>
          <Col xs={6} sm={6} lg={3}>
            <label>Km</label>
          </Col>
        </Row>

        {this.props.impactSizeArr.map((x, index) => {
          return (
            <Row gutter={[10]} className="addrow" key={index}>
              <Col xs={12} sm={12} lg={5}>
                <Form.Item name={`size_attributeValueId` + index}>
                  <Select
                    style={{ width: "100%" }}
                    size="large"
                    placeholder="Select size"
                    onChange={(value) => {
                      this.props.handleImpactSizeSelectChange(value, index);
                    }}
                    value={
                      x.attributeValue ? x.attributeValue.attributeValueId : ""
                    }
                  >
                    {this.props.renderSizeOptions()}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={6} sm={6} lg={4}>
                <Form.Item
                  name={`weight` + index}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input weight!",
                    },
                  ]}
                >
                  <Input
                    placeholder="weight"
                    name="weight"
                    onChange={(e) => this.props.handleImpactSize(e, index)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} sm={6} lg={4}>
                <Form.Item
                  name={`kgco2` + index}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input kgco2!",
                    },
                  ]}
                >
                  <Input
                    placeholder="kg CO2"
                    name="kgco2"
                    onChange={(e) => this.props.handleImpactSize(e, index)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} sm={6} lg={4}>
                <Form.Item
                  name={`liter` + index}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input liter!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Liter"
                    name="liter"
                    onChange={(e) => this.props.handleImpactSize(e, index)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} sm={6} lg={4}>
                <Form.Item
                  name={`kmtravel` + index}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input km travel!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Km"
                    name="kmtravel"
                    onChange={(e) => this.props.handleImpactSize(e, index)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} sm={6} lg={3}>
                {index !== 0 ? (
                  <Popconfirm
                    className="action"
                    title="Are you sure delete ?"
                    onConfirm={(id) => this.props.removeImpactSize(index, x)}
                    okText="Yes"
                    placement="left"
                  >
                    <Button className="close" size="small">
                      <CloseCircleOutlined />
                    </Button>
                  </Popconfirm>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          );
        })}

        <Button size="small" onClick={(e) => this.props.addImpactSize(e)}>
          <PlusCircleOutlined /> Add Fields
        </Button>
      </>
    );
  }
}

export default withApollo(AdminAddImpactSize);

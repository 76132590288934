import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Spin } from "antd";
import CanvasJSReact from "../../../charts/canvasjs.react";

import { authUserData } from "../../../utils/Helpers";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { GET_MOST_PRODUCT_CLICK_COUNT_QUERY } from "../product/ProductQuery";

import "../dashboard/Dashboard.scss";
import chart_datat from "../../../images/chart_datat.jpg";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ProductClickCountChart extends Component {
  state = {
    authUserId: authUserData().appusersId,
    filterParamObj: this.props.chartParams,
    hasData: false,
    apiLoading: false,
    chartData: [],
  };

  componentDidMount() {
    this.getChartData();
  }

  getChartData = () => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_MOST_PRODUCT_CLICK_COUNT_QUERY,
        variables: this.state.filterParamObj,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let chartData = [];
        let hasData = false;
        response.data.allProduct.edges.map((objectValue) => {
          hasData = true;
          let myObject = {};
          myObject["label"] = objectValue.node.productName;
          myObject["y"] = objectValue.node.clickCount;
          myObject["color"] = "#44514E";
          chartData.push(myObject);
          return null;
        });
        this.setState({ chartData, hasData, apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  render() {
    const ChartDataObject = {
      animationEnabled: true,
      exportEnabled: false,
      theme: "light2",
      dataPointMaxWidth: 70,
      title: {
        text: "Top Products",
        fontFamily: "Calibri",
        fontSize: 20,
        horizontalAlign: "left",
        padding: {
          top: 1,
          right: 1,
          bottom: 30,
          left: 15,
        },
      },
      axisY: {
        includeZero: true,
      },
      data: [
        {
          type: "column",
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: this.state.chartData,
        },
      ],
    };
    const { hasData, apiLoading } = this.state;
    return (
      <div className="chart-wrapper">
        <Spin spinning={apiLoading}>
          {hasData ? (
            <CanvasJSChart options={ChartDataObject} />
          ) : (
            <img src={chart_datat} alt="No Chart Data" />
          )}
        </Spin>
      </div>
    );
  }
}

export default withApollo(ProductClickCountChart);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Input,
  Breadcrumb,
  Popconfirm,
  Select,
} from "antd";
import { DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData, ToDateTime } from "../../../utils/Helpers";

import Sidebar from "../common/AdminSidebar";
import {
  REQUEST_LIST_QUERY,
  DELETE_REQUEST_QUERY,
  CHANGE_REQUEST_STATUS_QUERY,
} from "./RequestQuoteQuery";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class ManageRequestQuote extends Component {
  state = {
    data: [],
    loading: false,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-request_quote_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0 },
  };

  componentDidMount() {
    this.fetchRequestQuoteList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });

    this.searchCallback = debounce(function (e) {
      this.searchSupport(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchRequestQuoteList({
      deleted: 0,
      orderBy: orderBy,
      first: this.state.perPageRecord,
    });
  };

  fetchRequestQuoteList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: REQUEST_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allRequestQuotes.edges,
          nextPageId: result.data.allRequestQuotes.pageInfo.endCursor,
          previousPageId: result.data.allRequestQuotes.pageInfo.startCursor,
          totalRecords: result.data.allRequestQuotes.filterCount,
          totalPage: Math.ceil(
            result.data.allRequestQuotes.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchRequestQuoteList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchRequestQuoteList({
      deleted: 0,
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchRequestQuoteList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: e,
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchSupport = (searchText) => {
    if (searchText !== "") {
      this.fetchRequestQuoteList({
        search: searchText,
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText, currentPageNo: 1 });
    } else {
      this.fetchRequestQuoteList({
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ searchText: "" });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "-request_quote_id";

    if (sortField === "requestQuoteId" && sortOrder === "ascend") {
      sortingEnumKey = "request_quote_id";
    }
    if (sortField === "requestQuoteId" && sortOrder === "descend") {
      sortingEnumKey = "-request_quote_id";
    }
    if (sortField === "quoteContent" && sortOrder === "ascend") {
      sortingEnumKey = "quote_content";
    }
    if (sortField === "quoteContent" && sortOrder === "descend") {
      sortingEnumKey = "-quote_content";
    }
    if (sortField === "createdAt" && sortOrder === "ascend") {
      sortingEnumKey = "created_at";
    }
    if (sortField === "createdAt" && sortOrder === "descend") {
      sortingEnumKey = "-created_at";
    }
    return sortingEnumKey;
  }

  handleChangeStatus = (status, record) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: CHANGE_REQUEST_STATUS_QUERY,
        variables: {
          requestQuoteId: record.node.requestQuoteId,
          status: status,
          userId: this.state.authUserId,
        },
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success",
          "Request Quote status change successfully"
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  confirmDelete = async (requestQuoteId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_REQUEST_QUERY,
        variables: { requestQuoteId: requestQuoteId },
      })
      .then((result) => {
        this.fetchRequestQuoteList({
          deleted: 0,
          orderBy: this.state.orderBy,
          first: this.state.perPageRecord,
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg(
          "Success",
          "Request Quote deleted successfully."
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "Request Quote ID",
        key: "requestQuoteId",
        sorter: true,
        render: (node) => `${node.node.requestQuoteId}`,
        width: "15%",
      },
      {
        title: "Service Request",
        key: "quoteContent",
        sorter: true,
        render: (node) => `${node.node.quoteContent}`,
        width: "40%",
        ellipsis: true,
      },
      {
        title: "Date & Time",
        key: "createdAt",
        sorter: true,
        render: (node) => ToDateTime(node.node.createdAt),
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Status",
        key: "status",
        sorter: true,
        render: (node) => {
          return (
            <Select
              defaultValue={node.node.status}
              onChange={(e) => this.handleChangeStatus(e, node)}
              style={{ width: 120 }}
              size="small"
            >
              <Option value="submitted">submitted</Option>
              <Option value="in review">in review</Option>
              <Option value="in discussion">in discussion</Option>
              <Option value="approved">approved</Option>
            </Select>
          );
        },
        width: "15%",
      },
      {
        width: "10%",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Popconfirm
              className="action"
              title="Are you sure delete this quote ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.requestQuoteId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Request a Quote
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Request a Quote</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>Quotes</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 970 }}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>

                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageRequestQuote);

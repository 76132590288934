import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Upload,
  Button,
  Form,
  Divider,
  Space,
  Popconfirm,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../../utils/NotificationHelper";
import fileImg from "../../../../images/file-icon.png";
import {
  UPLOAD_SUPPLYCHAIN_DOCUMENTS,
  GET_SUPPLYCHAIN_DOCUMENT_QUERY,
  DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
  DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
} from "../SupplychainDocumentQuery";

class DocumentForm extends Component {
  state = {
    uploadedDocuments: [],
  };

  onFinish = (values) => {
    this.props.onClickSubmit();
  };

  componentDidMount() {
    this.getSupplychainDocument(this.props.stateValues.supplyChainRecordId);
  }

  uploadSupplychainDoc = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    client
      .mutate({
        mutation: UPLOAD_SUPPLYCHAIN_DOCUMENTS,
        variables: {
          supplyChainId: this.props.stateValues.supplyChainRecordId,
          supplychainDocuments: file,
        },
      })
      .then((response) => {
        let uploadedDocumentStat = this.state.uploadedDocuments;
        uploadedDocumentStat.push({
          id:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainfilesId,
          name:
            response.data.uploadSupplychainDocument.supplyChainDocument
              .supplychainFilename,
          url: response.data.uploadSupplychainDocument.presignedUrl,
          status: "done",
        });

        this.setState({ uploadedDocuments: uploadedDocumentStat });

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  getSupplychainDocument = (supplyChainRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          deleted: 0,
          supplyChainId: supplyChainRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        if (response.data.allSupplychainfiles.edges.length > 0) {
          response.data.allSupplychainfiles.edges.map(
            (objectValue, objectKey) => {
              let mcFieldObject = {};
              mcFieldObject["id"] = objectValue.node.supplychainfilesId;
              mcFieldObject["name"] = objectValue.node.supplychainFilename;
              mcFieldObject["url"] = objectValue.node.awsFileUrl;
              mcFieldObject["thumbUrl"] = objectValue.node.awsFileUrl;
              mcFieldObject["status"] = "done";
              mcStatArr.push(mcFieldObject);
              return null;
            }
          );
          this.setState({ uploadedDocuments: mcStatArr });
        } else {
          this.setState({ uploadedDocuments: [] });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  confirmDelete = async (documentObj) => {
    const { client } = this.props;
    await client
      .mutate({
        mutation: DELETE_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainfilesId: documentObj.id,
          supplychainDocument: documentObj.url,
        },
      })
      .then(() => {
        this.getSupplychainDocument(this.props.stateValues.supplyChainRecordId);
        SuccessNotificationMsg("Success", "Document deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadDocument = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY,
        variables: {
          supplychainDocument: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadSupplychainDocument.presignedUrl !== "") {
          window.open(response.data.downloadSupplychainDocument.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
    };
    return (
      <React.Fragment>
        <Form onFinish={this.onFinish}>
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}></Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={12} xl={24}>
              <h2>Supplychain</h2>
              <Form.Item>
                <label>
                  Please upload any documents with info on your supply chain
                </label>
                <div className="documents supplychaindoc addfileinform">
                  <Upload
                    {...uploadProps}
                    customRequest={this.uploadSupplychainDoc}
                    accept=".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd"
                    // fileList={this.state.uploadedDocuments}
                  >
                    <Button size="small">
                      <PlusCircleOutlined /> Add File
                    </Button>
                  </Upload>

                  <Row gutter={[16]}>
                    {this.state.uploadedDocuments.map((document, index) => {
                      return (
                        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                          <div className="imgdiv files">
                            <div className="img_wrap">
                              <img src={fileImg} alt=""></img>
                            </div>
                            <h5>{document.name}</h5>

                            <div className="btnwrp">
                              <Button
                                type="primary"
                                htmlType="button"
                                size="small"
                                icon={<DownloadOutlined />}
                                onClick={() => this.downloadDocument(document)}
                              ></Button>

                              <Popconfirm
                                className="action"
                                title="Are you sure delete this document ?"
                                onConfirm={() => this.confirmDelete(document)}
                                okText="Yes"
                                placement="left"
                              >
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DeleteOutlined />}
                                ></Button>
                              </Popconfirm>
                            </div>
                          </div>
                        </Col>
                      );
                    })}

                    <Col xs={24} sm={12} md={8} lg={6} xl={4}></Col>
                  </Row>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row
            gutter={[15]}
            align="end"
            justify="space-between"
            className="bottomButtons"
          >
            <Col>
              <Space>
                <Button
                  type="secondary"
                  htmlType="button"
                  size="large"
                  onClick={() => this.props.history.push("/seller-get-support")}
                >
                  ASK SUPPORT
                </Button>
              </Space>
            </Col>
            <Col>
              <Space>
                <Button
                  type="secondary"
                  htmlType="submit"
                  size="large"
                  onClick={this.props.onClickBack}
                >
                  BACK
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={this.props.onClickSubmit}
                >
                  SUBMIT
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default withApollo(DocumentForm);

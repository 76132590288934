import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout } from "antd";
import { Auth } from "aws-amplify";

import {
  AppstoreOutlined,
  SettingOutlined,
  EditOutlined,
  PlusSquareOutlined,
  ShoppingOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
  FilePdfOutlined
} from "@ant-design/icons";

const { Sider } = Layout;
class Sidebar extends Component {
  state = {
    authUser: "",
    currentRoute: "",
  };

  async componentDidMount() {
    const tokens = await Auth.currentSession();
    const userData = tokens.getIdToken().payload;
    this.setState({
      authUser: userData,
      currentRoute: this.props.location.pathname,
    });
  }

  render() {
    const { name } = this.state.authUser;
    const { currentRoute } = this.state;

    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width="250"
        onBreakpoint={(broken) => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          //console.log(collapsed, type);
        }}
      >
        <div className="profilebar">{name}</div>
        <div className="sidebar_menu">
          <ul className="navigate">
            <li
              className={currentRoute === "/seller-dashboard" ? "active" : ""}
            >
              <Link to="/seller-dashboard">
                <i className="menu-icon">
                  <AppstoreOutlined />
                </i>
                Dashboard
              </Link>
            </li>
            <li
              className={currentRoute === "/seller-add-product" ? "active" : ""}
            >
              <Link to="/seller-add-product">
                {" "}
                <i className="menu-icon">
                  <ShoppingOutlined />
                </i>
                Add Product
              </Link>
            </li>
            <li
              className={
                currentRoute === "/seller-manage-product" ? "active" : ""
              }
            >
              <Link to="/seller-manage-product">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Product
              </Link>
            </li>
            <li
              className={
                currentRoute === "/seller-manage-supplychain" ? "active" : ""
              }
            >
              <Link to="/seller-manage-supplychain">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Supplychain
              </Link>
            </li>
            <li
              className={
                currentRoute === "/seller-report-media" ? "active" : ""
              }
            >
              <Link to="/seller-report-media">
                {" "}
                <i className="menu-icon">
                  <FileTextOutlined />
                </i>
                Report & Media
              </Link>
            </li>
            <li
              className={
                currentRoute === "/seller-request-quote" ? "active" : ""
              }
            >
              <Link to="/seller-request-quote">
                {" "}
                <i className="menu-icon">
                  <EditOutlined />
                </i>
                Request Quote
              </Link>
            </li>
            <li
              className={currentRoute === "/seller-get-support" ? "active" : ""}
            >
              <Link to="/seller-get-support">
                {" "}
                <i className="menu-icon">
                  <SettingOutlined />
                </i>
                Get Suppport
              </Link>
            </li>
            <li
              className={currentRoute === "/seller-store-help" ? "active" : ""}
            >
              <Link to="/seller-store-help">
                {" "}
                <i className="menu-icon">
                <FilePdfOutlined />
                </i>
                Setup API Connection
              </Link>
            </li>
            <li>
              <a
                href="https://faq.truecostlabel.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <i className="menu-icon">
                  <QuestionCircleOutlined />
                </i>
                Help
              </a>
            </li>
          </ul>
        </div>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);

import gql from "graphql-tag";

export const GET_MANUFACTURER_DETAIL_QUERY = gql`
  query allProducermanufacturer($deleted: Int, $supplyChainId: Int) {
    allProducermanufacturer(deleted: $deleted, supplyChainId: $supplyChainId) {
      edges {
        node {
          id
          producerName
          process
          city
          country
          producermanufacturerId
        }
      }
    }
  }
`;

export const UPDATE_MANUFACTURER_QUERY = gql`
  mutation updateProducermanufacturer(
    $manufacturers: [ProducerManufacturerInput]!
  ) {
    updateProducermanufacturer(input: { data: $manufacturers }) {
      producermanufacturer {
        producermanufacturerId
        city
        country
        producerName
      }
    }
  }
`;

export const DELETE_MANUFACTURER_QUERY = gql`
  mutation deleteProducermanufacturer($producermanufacturerId: Int!) {
    deleteProducermanufacturer(
      input: { producermanufacturerId: $producermanufacturerId }
    ) {
      producermanufacturerId
    }
  }
`;

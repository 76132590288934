import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Row, Col, Tooltip, Button } from "antd";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";

// import cloudBlack from "../../../images/black-icons/cloud-b.png";
// import recycleBlack from "../../../images/black-icons/recycle-b.png";
// import bioBlack from "../../../images/black-icons/bio-b.png";
// import timeBlack from "../../../images/black-icons/time-b.png";
// import waterBlack from "../../../images/black-icons/water-b.png";
// import worldBlack from "../../../images/black-icons/world-b.png";
// import serviceBlack from "../../../images/black-icons/service-b.png";

import { GET_IMPACT_INDICATOR_QUERY } from "../../admin/impactIndicator/ImpactIndicatorQuery";
import { impacts } from "../../../utils/constants";

// const createMarkup = (data) => {
//   return { __html: data };
// };

// const CommonContent = (props) => {
//   const history = useHistory();
//   return (
//     <React.Fragment>
//       {props.title !== "" ? (
//         <div className="water_usages">
//           <div className="water_title">
//             <div className="iconbox">
//               <img className="cardicon" alt="icon" src={props.tabIcon}></img>
//             </div>
//             <div>
//               <h3>{props.title}</h3>
//             </div>
//           </div>
//           <Row>
//             <Col flex="auto" className="l_col">
//               <span
//                 dangerouslySetInnerHTML={createMarkup(
//                   ShowTextByCharLength(props.description, 460)
//                 )}
//               ></span>
//               <div className="headerTabButtons">
//                 <div>
//                   <Space>
//                     <Button
//                       type="primary"
//                       htmlType="button"
//                       className="blkbtn"
//                       onClick={() =>
//                         history.push("/impact-indicator", {
//                           impactId: props.impactIndicatorId,
//                         })
//                       }
//                     >
//                       Keep Reading
//                     </Button>
//                     <Button
//                       type="primary"
//                       htmlType="button"
//                       className="blkbtn"
//                       onClick={() => props.onsetTab("0")}
//                     >
//                       Close
//                     </Button>
//                   </Space>
//                 </div>
//                 <Button
//                   type="primary"
//                   htmlType="button"
//                   className="blkbtn"
//                   onClick={() =>
//                     history.push(props.shopNowUrl, {
//                       impactId: props.impactIndicatorId,
//                     })
//                   }
//                 >
//                   Start Shopping
//                 </Button>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       ) : (
//         ""
//       )}
//     </React.Fragment>
//   );
// };

// const DefaultCommonContent = (props) => {
//   const history = useHistory();
//   return (
//     <React.Fragment>
//       <div className="normal_text">
//         Use the guide above to discover the indicators we use to calculate the
//         impact of the products you want to wear
//       </div>
//       <div className="btn_row_1">
//         <Button
//           type="primary"
//           htmlType="submit"
//           className="blkbtn"
//           onClick={() => history.push("/products")}
//         >
//           Start Shopping
//         </Button>
//       </div>
//     </React.Fragment>
//   );
// };

export class HeaderTab extends Component {
  state = {
    apiLoading: false,
    activeTabKey: "0",
    isLogoClick: false,
    data: [],
    excludeRoute: [
      "/login",
      "/brand-register",
      "/forgot-password",
      "/verify-account",
      "/about-us",
    ],
  };

  componentDidMount() {
    this.getImpactIndicator({
      deleted: 0,
      orderBy: "impact_indicator_id",
      impactIndicatorId: this.props.impactId,
    });
  }

  componentDidUpdate(prevProps) {
    let currentRoute = this.props.location.pathname;
    if (currentRoute !== this.state.currentRoute) {
      this.setState({
        currentRoute,
      });
    }

    if (this.props.isLogoClick !== this.state.isLogoClick) {
      this.setState({
        activeTabKey: "0",
        isLogoClick: this.props.isLogoClick,
      });
    }
  }

  getImpactIndicator = (params) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: GET_IMPACT_INDICATOR_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          apiLoading: false,
          data: result.data.allImpactIndicator.edges,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleUrlChange = (url, impactIndicatorId) => {
    // // let currentRoute = this.props.location.pathname;
    // let queryString = qs.parse(this.props.history.location.search);
    // let cleanUrl = url.split("?");
    // let updateUrl = {};

    // Object.keys(queryString).forEach((key) => {
    //   Object.assign(updateUrl, { [key]: queryString[key] });
    // });

    // if (cleanUrl && cleanUrl.length > 1) {
    //   let cleanUrlFilter = cleanUrl[1].split("=");
    //   Object.assign(updateUrl, { [cleanUrlFilter[0]]: cleanUrlFilter[1] });
    // }

    // let queryStringUrl = Object.keys(updateUrl)
    //   .map((key) => key + "=" + updateUrl[key])
    //   .join("&");

    this.props.history.push(url, {
      impactId: impactIndicatorId,
    });
  };

  // handleTabCallback = (tabKey) => {
  //   this.setState(
  //     { activeTabKey: tabKey, isLogoClick: false },
  //     this.props.resetIsLogoClick()
  //   );
  // };

  // setTab = (tabKey) => {
  //   this.setState({ activeTabKey: tabKey });
  // };

  render() {
    const { data, currentRoute, excludeRoute } = this.state;
    if (excludeRoute.includes(currentRoute)) return null;

    return (
      <section className="tabsection">
        {data.length > 0 && (
          <div className="container container_small">
            <Row>
              <Col
                flex="auto"
                xs={{ order: 3 }}
                lg={{ order: 2 }}
                className="tabcol"
              >
                <div className="ipact_ind">
                  <div className="tabhead">Impact Indicators</div>
                  {/* <Tabs
                    activeKey={this.state.activeTabKey}
                    onChange={this.handleTabCallback}
                  >
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={cloud}
                            title="AAAAAa"
                          ></img>
                        </span>
                      }
                      key="0"
                    >
                      {currentRoute !== "/products" && <DefaultCommonContent />}
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[0].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={cloud}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="1"
                    >
                      <CommonContent
                        {...data[0].node}
                        tabIcon={cloudBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[1].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={recycle}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="2"
                    >
                      <CommonContent
                        {...data[1].node}
                        tabIcon={recycleBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[2].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={bio}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="3"
                    >
                      <CommonContent
                        {...data[2].node}
                        tabIcon={bioBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[3].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={time}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="4"
                    >
                      <CommonContent
                        {...data[3].node}
                        tabIcon={timeBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[4].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={water}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="5"
                    >
                      <CommonContent
                        {...data[4].node}
                        tabIcon={waterBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[5].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={world}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="6"
                    >
                      <CommonContent
                        {...data[5].node}
                        tabIcon={worldBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <Tooltip
                            placement="topLeft"
                            title={data[6].node.title}
                          >
                            <img
                              className="cardicon"
                              alt="icon"
                              src={service}
                            ></img>
                          </Tooltip>
                        </span>
                      }
                      key="7"
                    >
                      <CommonContent
                        {...data[6].node}
                        tabIcon={serviceBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                  </Tabs> */}
                  <div className="icon-wrapper">
                    <ul>
                      {data.map((ele, index) => {
                        const impactIcon = impacts.find(
                          (res) => res.slug === ele.node.slug
                        );

                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this.handleUrlChange(
                                ele.node.shopNowUrl,
                                ele.node.impactIndicatorId
                              )
                            }
                          >
                            <span>
                              <Tooltip
                                placement="topLeft"
                                title={ele.node.title}
                              >
                                <img
                                  className="cardicon"
                                  alt="icon"
                                  src={impactIcon ? impactIcon?.icon : ""}
                                ></img>
                              </Tooltip>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col
                flex="auto"
                xs={{ order: 3 }}
                lg={{ order: 2 }}
                className="tabcol"
              >
                <div className="ipact_ind">
                  <Button
                    type="primary"
                    htmlType="button"
                    className="bg-black"
                    onClick={() =>
                      this.props.history.push(data[0].node.shopNowUrl, {
                        impactId: data[0].node.impactIndicatorId,
                      })
                    }
                  >
                    Start Shopping
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </section>
    );
  }
}

export default withApollo(withRouter(HeaderTab));

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Button, Modal, Form, Input } from "antd";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import Config from "../../../Config";

export const CREATE_REQUEST_QUOTE_QUERY = gql`
  mutation createRequestquote($email: String!) {
    createRequestquote(input: { email: $email }) {
      requestquote {
        requestQuoteId
        quoteContent
      }
    }
  }
`;

export class Subscribe extends Component {
  state = {
    subscribeVisible: false,
    btnLoading: false,
  };
  formRef = React.createRef();

  componentDidMount() {
    this.OpenModalOnFirstVisit();
  }

  OpenModalOnFirstVisit = () => {
    if (localStorage.getItem("websiteVisited")) {
      this.setState({ subscribeVisible: false });
    } else {
      localStorage.setItem("websiteVisited", true);
      this.setState({ subscribeVisible: true });
    }
  };

  handleSubscribeModel(subscribeVisible) {
    this.setState({ subscribeVisible });
  }

  onFinish = (values) => {
    this.setState({ btnLoading: true });

    let formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("firstName", this.state.firstName);
    formData.append("lastName", this.state.lastName);

    fetch(Config.API_URL + "/subscribe/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "subscribed") {
          SuccessNotificationMsg(
            "Thank you!",
            "You have subscribe successfully."
          );
          this.formRef.current.resetFields();
        } else {
          if (data.status === 400 && data.title === "Member Exists") {
            ErrorNotificationMsg(
              "Error",
              "You have already subscribed with us."
            );
          } else {
            ErrorNotificationMsg("Error", data.title);
          }
        }
        this.setState({ btnLoading: false });
      })
      .catch((error) => {
        ErrorNotificationMsg("Error", error.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const { btnLoading } = this.state;
    return (
      <React.Fragment>
        <Link
          to="#"
          className="subscribe"
          onClick={() => this.handleSubscribeModel(true)}
        >
          Subscribe here
        </Link>
        <Modal
          className="scbscribe_modal"
          title="Subscribe to our newsletter*"
          centered
          visible={this.state.subscribeVisible}
          onCancel={() => this.handleSubscribeModel(false)}
          footer={false}
        >
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Form.Item name="firstName">
              <Input
                placeholder="First name"
                onChange={this.handleInputChange("firstName")}
              />
            </Form.Item>
            <Form.Item name="lastName">
              <Input
                placeholder="Last name"
                onChange={this.handleInputChange("lastName")}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid e-mail address!",
                },
                {
                  required: true,
                  message: "Please input e-mail address!",
                },
              ]}
            >
              <Input
                placeholder="Email:"
                onChange={this.handleInputChange("email")}
              />
            </Form.Item>

            <div className="note">
              * We promise not to mail you more than 2x per month
            </div>
            <div align="center">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={btnLoading}
              >
                Send
              </Button>
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withApollo(Subscribe);

import React, { Component } from "react";
import footerlogo from "../images/footer-logo.png";

class LogoSmall extends Component {

    render() {
        return (
            <img alt="logo" src={footerlogo} ></img>
        );
    }
}

export default LogoSmall;
import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form } from "antd";
import Password from "antd/lib/input/Password";
import profileImg from "../../images/profile-noimg.png";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { redirectIfLoggedIn } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import {
  GET_BRAND_USER_DETAIL_QUERY,
  GET_BRAND_ONBOARDING_QUERY,
} from "../brand/common/ProfileQuery";
class Login extends React.Component {
  state = {
    btnLoading: false,
  };

  componentDidMount() {
    redirectIfLoggedIn();
  }

  onFinish = (values) => {
    let { username, password } = this.state;
    this.setState({ btnLoading: true });

    let appUserResponse = null;
    let cognitoResponse = null;

    Auth.signIn(username, password)
      .then((user) => {
        cognitoResponse = user;
        return this.getBrandUserDetail(user.username);
      })
      .then((response) => {
        appUserResponse = response;
        let appUserData = appUserResponse.data.allAppuser.edges[0].node;

        if (
          appUserData.userType === "admin" ||
          appUserData.userType === "consumer"
        ) {
          if (appUserData.profilepicturemodelSet.edges.length > 0) {
            appUserData.profilePic =
              appUserData.profilepicturemodelSet.edges[0].node.awsFileUrl;
          } else {
            appUserData.profilePic = profileImg;
          }

          localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            cognitoResponse.signInUserSession.accessToken.jwtToken
          );
          localStorage.setItem("auth_data", JSON.stringify(appUserData));

          SuccessNotificationMsg(
            "Succesfully logged in!",
            "Redirecting you in a few!"
          );

          if (appUserData.userType === "admin") {
            window.location.href = "/admin-dashboard";
          } else if (appUserData.userType === "consumer") {
            window.location.href = "/";
          }
          return false;
        } else {
          return this.getBrandOnboardingDetail(
            response.data.allAppuser.edges[0].node.appusersId
          );
        }
      })
      .then((onboardingResponse) => {
        let appUserData = appUserResponse.data.allAppuser.edges[0].node;

        if (appUserData.userType === "brand") {
          appUserData.onboardingId =
            onboardingResponse.data.allVendors.edges[0].node.onboardingId;

          if (appUserData.profilepicturemodelSet.edges.length > 0) {
            appUserData.profilePic =
              appUserData.profilepicturemodelSet.edges[0].node.awsFileUrl;
          } else {
            appUserData.profilePic = profileImg;
          }

          localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            cognitoResponse.signInUserSession.accessToken.jwtToken
          );
          localStorage.setItem("auth_data", JSON.stringify(appUserData));

          SuccessNotificationMsg(
            "Succesfully logged in!",
            "Redirecting you in a few!"
          );
          window.location.href = "/seller-dashboard";
          this.setState({ btnLoading: false });
        }
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  getBrandUserDetail = (cognitoId) => {
    const { client } = this.props;
    return client.query({
      query: GET_BRAND_USER_DETAIL_QUERY,
      variables: { deleted: 0, cognitoId: cognitoId },
      fetchPolicy: "network-only",
    });
  };

  getBrandOnboardingDetail = (appusersId) => {
    const { client } = this.props;
    return client.query({
      query: GET_BRAND_ONBOARDING_QUERY,
      variables: { deleted: 0, userId: appusersId },
      fetchPolicy: "network-only",
    });
  };

  render() {
    return (
      <div>
        <div className="pagename">Login</div>
        <p>Welcome! Log in to your brand dashboard</p>
        <Form onFinish={this.onFinish}>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="username"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid e-mail!",
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="Email Address *"
                  onChange={this.handleInputChange("username")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input Password!",
                  },
                ]}
              >
                <Password
                  placeholder="Password *"
                  onChange={this.handleInputChange("password")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Button
                type="primary"
                htmlType="submit"
                className="loginbtn"
                loading={this.state.btnLoading}
              >
                LOGIN
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <div className="btnparent">
                <Link to="/brand-register" className="button-link cracbtn">
                  Register a Brand
                </Link>
              </div>
              <div className="btnparent">
                <Link to="/verify-account" className="button-link">
                  Verify your account?
                </Link>
                <Link to="/forgot-password" className="button-link">
                  Forgot your password?
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withApollo(Login);

import gql from "graphql-tag";

export const GET_COMPANY_DETAIL_QUERY = gql`
  query allVendors($deleted: Int, $onboardingPk: Int, $userId: Int) {
    allVendors(
      deleted: $deleted
      onboardingPk: $onboardingPk
      userId: $userId
    ) {
      totalCount
      edges {
        node {
          id
          onboardingId
          userId {
            appusersId
          }
          company
          website
          aboutBrand
          remarks
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_DETAIL_QUERY = gql`
  mutation updateVendor(
    $authonboardingId: Int!
    $authUserId: Int
    $company: String
    $website: String
    $aboutBrand: String
    $remarks: String
  ) {
    updateVendor(
      input: {
        onboardingId: $authonboardingId
        userId: $authUserId
        company: $company
        website: $website
        aboutBrand: $aboutBrand
        remarks: $remarks
      }
    ) {
      vendor {
        onboardingId
      }
    }
  }
`;

export const GET_BRAND_USER_DETAIL_QUERY = gql`
  query allAppuser($deleted: Int, $cognitoId: String, $appuserPk: Int) {
    allAppuser(
      deleted: $deleted
      appuserPk: $appuserPk
      cognitoId: $cognitoId
    ) {
      edges {
        node {
          appusersId
          id
          firstName
          lastName
          email
          phone
          newsletter
          city
          country
          userType
          profilepicturemodelSet {
            edges {
              node {
                awsFileUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BRAND_USER_DETAIL_QUERY = gql`
  mutation updateAppUser(
    $authUserId: Int!
    $firstName: String
    $lastName: String
    $phone: String
    $city: String
    $country: String
    $email: String
  ) {
    updateAppUser(
      input: {
        appusersId: $authUserId
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        city: $city
        country: $country
        email: $email
      }
    ) {
      appuser {
        appusersId
      }
    }
  }
`;

export const CREATE_BRAND_USER_QUERY = gql`
  mutation createAppUser(
    $cognitoId: String
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $city: String
    $country: String
    $userType: String
    $newsletter: Boolean
  ) {
    createAppUser(
      input: {
        cognitoId: $cognitoId
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        city: $city
        country: $country
        userType: $userType
        newsletter: $newsletter
      }
    ) {
      appuser {
        appusersId
        id
        firstName
        lastName
        email
        phone
        newsletter
        city
        country
      }
    }
  }
`;

export const CREATE_BRAND_ONBOARDING_QUERY = gql`
  mutation createVendor(
    $userId: Int!
    $company: String!
    $website: String
    $aboutBrand: String!
    $remarks: String
  ) {
    createVendor(
      input: {
        userId: $userId
        company: $company
        website: $website
        aboutBrand: $aboutBrand
        remarks: $remarks
      }
    ) {
      vendor {
        id
        onboardingId
        company
        website
        aboutBrand
        remarks
      }
    }
  }
`;

export const GET_BRAND_ONBOARDING_QUERY = gql`
  query allVendors($deleted: Int, $userId: Int) {
    allVendors(deleted: $deleted, userId: $userId) {
      edges {
        node {
          onboardingId
        }
      }
    }
  }
`;

export const UPLOAD_USER_PROFILE_PICTURE = gql`
  mutation($pictureFile: Upload!, $userId: Int!) {
    uploadProfilePicture(pictureFile: $pictureFile, userId: $userId) {
      success
      presignedUrl
    }
  }
`;

export const GET_BRAND_USER_PROFILE_PIC = gql`
  query allProfilepictures($deleted: Int, $userId: Int) {
    allProfilepictures(deleted: $deleted, userId: $userId) {
      edges {
        node {
          fileType
          awsFileUrl
          thumbAwsurl
          profilepictureName
          profilepicturesId
        }
      }
    }
  }
`;

export const UPLOAD_COMPANY_LOGO = gql`
  mutation($brandLogoFile: Upload!, $userId: Int!) {
    uploadBrandLogo(brandLogoFile: $brandLogoFile, userId: $userId) {
      success
      presignedUrl
    }
  }
`;

export const GET_COMPANY_LOGO = gql`
  query allBrandlogos($deleted: Int, $userId: Int, $company: String) {
    allBrandlogos(deleted: $deleted, userId: $userId, company: $company) {
      edges {
        node {
          fileType
          awsFileUrl
          brandlogoId
          thumbAwsurl
          thumbName
          userId {
            onboardingmodelSet {
              edges {
                node {
                  company
                  website
                }
              }
            }
          }
        }
      }
    }
  }
`;

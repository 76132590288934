import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Checkbox, Form } from "antd";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { GET_ATTRIBUTES_WITH_VALUES } from "../../common/AttributeQuery";
import { cleanEdgesNode } from "../../../utils/Helpers";

export class FilterSizeCategoryList extends Component {
  state = {
    loading: false,
    productTypeArr: [],
    sizeArr: [],
  };

  componentDidMount() {
    this.getAttributes();
  }

  getAttributes = () => {
    this.props.client
      .query({
        query: GET_ATTRIBUTES_WITH_VALUES,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let sizeArr = "";
        let productTypeArr = "";

        if (response.data.allAttribute.edges.length > 0) {
          response.data.allAttribute.edges.map((attributes) => {
            if (attributes.node.attributeId === "1") {
              sizeArr = cleanEdgesNode(
                attributes.node.attributevaluemodelSet,
                "sortCol",
                "asc"
              ).content;
            }
            if (attributes.node.attributeId === "2") {
              productTypeArr = cleanEdgesNode(
                attributes.node.attributevaluemodelSet
              ).content;
            }
            return null;
          });

          this.setState({ productTypeArr, sizeArr });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    const { sizeArr, productTypeArr } = this.state;

    const sizeOptions = sizeArr.map((ele) => {
      return { label: ele.value, value: ele.slug };
    });

    const productOptions = productTypeArr.map((ele) => {
      return { label: ele.value, value: ele.slug };
    });

    return (
      <React.Fragment>
        <div className="category_one">
          <h3>CATEGORIES</h3>
          <Form>
            <Form.Item>
              <Checkbox.Group
                options={productOptions}
                onChange={this.props.handleCategoryFilter}
                value={this.props.stateValue.categoryList}
              />
            </Form.Item>
            {/* {productTypeArr.edges && productTypeArr.edges.length > 0
              ? productTypeArr.edges.map((attributes, index) => {
                  return (
                    <Form.Item key={index}>
                      <Checkbox
                        onChange={this.props.handleCategoryFilter(
                          attributes.node.slug
                        )}
                      >
                        {attributes.node.value}
                      </Checkbox>
                    </Form.Item>
                  );
                })
              : ""} */}
          </Form>
        </div>
        <div className="category_one">
          <h3>SIZES</h3>
          <Form>
            <Form.Item>
              <Checkbox.Group
                options={sizeOptions}
                onChange={this.props.handleSizeFilter}
                value={this.props.stateValue.sizeList}
              />
            </Form.Item>
            {/* {sizeArr && sizeArr.length > 0
              ? sizeArr.map((attributes, index) => {
                  return (
                    <Form.Item key={index}>
                      <Checkbox
                        onChange={this.props.handleSizeFilter(attributes.slug)}
                      >
                        {attributes.value}
                      </Checkbox>
                    </Form.Item>
                  );
                })
              : ""} */}
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(FilterSizeCategoryList);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";

export class LatestContent extends Component {

    render() {

        return (
            <React.Fragment>
                <div className="hithere">
                    <h3>Hi There,</h3>
                    <br />
                    <p>If you are reading this the chances are you have been looking for sustainable fashion and
                        sustainable fashion brands for a while now. So have we! At the start of True Cost Label we
                        were endlessly looking for sustainable fashion items that don’t put an unnecessary toll on the
                        environment. This however turned out to be a lot harder than simply using google. You can
                        probably relate to the frustration of endless sustainability claims about products without there
                        being any real evidence to back this up.</p>
                    <p> First of all, the definition of a sustainable fashion item is extremely arbitrary. What one might
                        consider sustainable can go completely against the vision of someone else. Additionally
                        sustainability is such a wide topic it should be looked on from many different perspectives at
                        the same time. For example, how much C02 emissions are involved in the production of a
                        garment, how much litres of water, etc. All this is affected by what a product is made from(for
                        example organic cotton, recycled fibres, etc.) and how a garment is made, and lastly how far
                        a product has to travel and by what means before it reaches you.</p>
                    <p> At True Cost Label we look at all these dimensions to calculate how much C02, and litres of
                        water are involved in the production of a garment. We have executed a Life Cycle Analysis
                        (LCA)’s)for all the products on our website. This is possible because the brands on our
                        platform have given us insights into their supply chains. LCA’s are widely accepted by the
                        industry as a way to evaluate the environmental impact of products. You can read more on
                        how we do these calculations on our  <Link to="/how-we-do-it"><u>how we do it</u></Link> page.</p>
                    <p> By calculating the environmental impact of products and comparing them to the current
                        industry average we can confidently say what products are more sustainable than others,
                        without having to define a static definition for sustainable fashion. We consider sustainable
                        fashion brands simply as brands that are already significantly using less environmental
                        resources as the industry average. However, brands will have to keep pushing themselves
                        to create newer better scoring sustainable fashion garments. The insights provided on our
                        platform are a starting point for in what direction to look. We pride ourselves on doing these
                        calculations ethically and with a high level of transparency. We show you the environmental
                        impact of your potential choices.</p>
                    <p> Finding a new Fashion Favourite is already tricky. Let sustainability be the easy part.</p>

                </div>
            </React.Fragment>
        );
    }
}

export default withApollo(LatestContent);


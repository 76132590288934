/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:cf88a24f-126f-4c62-8bf9-697680b9072f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_3kztdpexI",
    "aws_user_pools_web_client_id": "2tnfnhsk9vrht7gunu6tmdoa9p",
    "oauth": {}
};


export default awsmobile;

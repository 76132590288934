import gql from "graphql-tag";

export const SUPPORT_LIST_QUERY = gql`
  query allGetsupport(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $search: String
    $orderBy: [String]
  ) {
    allGetsupport(
      deleted: $deleted
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      orderBy: $orderBy
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          getsupportId
          supportContent
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_SUPPORT_QUERY = gql`
  mutation deleteGetsupport($getsupportId: Int!) {
    deleteGetsupport(input: { getsupportId: $getsupportId }) {
      getsupportId
    }
  }
`;

export const CHANGE_SUPPORT_STATUS_QUERY = gql`
  mutation updateGetsupport(
    $getsupportId: Int!
    $status: String
    $userId: Int
  ) {
    updateGetsupport(
      input: { getsupportId: $getsupportId, status: $status, userId: $userId }
    ) {
      getsupport {
        getsupportId
        status
      }
    }
  }
`;

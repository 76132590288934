import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Radio, Row, Space, Button, Spin } from "antd";
import { withApollo } from "react-apollo";
import ImageGallery from "react-image-gallery";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import {
  authUserData,
  cleanEdgesNode,
  GetClientOriginDetails,
} from "../../utils/Helpers";

import noImgProduct from "../../images/noimg-product.webp";
import recycledMaterial from "../../images/recycledMaterial.png";
import bioMaterial from "../../images/bioMaterial.png";
import refurMaterial from "../../images/refurMaterial.png";
import organicFiber from "../../images/organicFiber.png";

import co2 from "../../images/co2.png";
import litreWater from "../../images/litreWater.png";
import arroundWorld from "../../images/arroundWorld.png";

import {
  INCREAMENT_PRODUCT_COUNT_QUERY,
  GET_PRODUCT_DETAIL_QUERY,
} from "../brand/product/ProductQuery";
import { ADD_LOG_QUERY } from "../admin/log/LogQuery";
export class ProductDetail extends Component {
  state = {
    productDetail: {},
    loading: true,
    btnLoading: false,
    galleryProductImages: [
      {
        original: noImgProduct,
        thumbnail: noImgProduct,
      },
    ],
    defaultImpactSizeData: {},
    defaultSize: "M",
  };

  componentDidMount() {
    let productDetail = this.props.location.state
      ? this.props.location.state.productDetail
      : {};

    console.log(productDetail);

    let slug = this.props.match.params.slug ? this.props.match.params.slug : "";

    this.getProductDetail(productDetail, slug);
    this.showProductImages();
    this.storeLog("PRODUCT_DETAIL");
  }

  storeLog = (logType) => {
    GetClientOriginDetails().then((result) => {
      this.callSaveLogAPI(result, logType);
    });
  };

  callSaveLogAPI = (result, logType) => {
    this.props.client.mutate({
      mutation: ADD_LOG_QUERY,
      variables: {
        ipAddress: result.ip.ip,
        country: result.ip.country,
        url: window.location.href,
        updatedBy: 1,
        logType,
      },
    });
  };

  getProductDetail = (productDetail, slug) => {
    if (productDetail.slug === null || productDetail.slug === undefined) {
      const { client } = this.props;
      client
        .query({
          query: GET_PRODUCT_DETAIL_QUERY,
          variables: { slugfield: slug },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          this.setState({
            loading: false,
            productDetail: response.data.allProduct.edges[0].node,
          });
          this.showProductImages();
          this.prepareImpactSizeData(response.data.allProduct.edges[0].node);
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
        });
    } else {
      this.prepareImpactSizeData(productDetail);
      this.setState({
        loading: false,
        productDetail: this.props.location.state
          ? this.props.location.state.productDetail
          : {},
      });
    }
  };

  showProductImages() {
    let imagesArr = this.props.location.state
      ? this.props.location.state.productDetail.productfilesmodelSet.edges
      : [];

    if (
      imagesArr.length === 0 &&
      this.state.productDetail.productfilesmodelSet !== undefined
    ) {
      imagesArr = this.state.productDetail.productfilesmodelSet.edges;
    }

    let gallerImages = [];
    if (imagesArr.length > 0) {
      imagesArr.map((img) => {
        if (img.node.fileType === "pic" && img.node.deleted === 0) {
          let objectValue = {
            original: img.node.awsFileUrl,
            thumbnail: img.node.awsFileUrl,
          };
          gallerImages.push(objectValue);
        }
        return null;
      });

      if (gallerImages.length > 0) {
        this.setState({ galleryProductImages: gallerImages });
      } else {
        this.setState({
          galleryProductImages: this.state.galleryProductImages,
        });
      }
    }
  }

  handleBuyNow = (productDetail) => {
    if (
      authUserData() &&
      authUserData().appusersId !== "" &&
      authUserData().appusersId !== undefined
    ) {
      window.open(productDetail.productLink);
      return false;
    }

    window.open(productDetail.productLink, "_blank");

    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: INCREAMENT_PRODUCT_COUNT_QUERY,
        variables: { productId: productDetail.productId },
      })
      .then(() => {
        this.storeLog("PRODUCT_BUY");
      })
      .then(() => {
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        this.setState({ btnLoading: false });
      });
  };

  prepareImpactSizeData(productData) {
    let impactData = [];

    if (
      productData.impactsizemodelSet &&
      productData.impactsizemodelSet.edges.length > 0
    ) {
      cleanEdgesNode(
        productData.impactsizemodelSet,
        "attributeValue.sortCol",
        "asc"
      ).content.map((item) => {
        impactData.push({
          weight: item.weight,
          kgco2: item.kgco2,
          liter: item.liter,
          kmtravel: item.kmtravel,
          size: item.attributeValue ? item.attributeValue.value : "",
          impactsizeId: item.impactsizeId,
        });
        return null;
      });

      let defaultImpactSizeData = impactData.filter(function (e) {
        return e.size === "M";
      });

      let defaultSize = this.state.defaultSize;

      if (!defaultImpactSizeData[0]) {
        defaultImpactSizeData[0] = impactData[0];
        defaultSize = impactData[0].size;
      }

      this.setState({
        impactSizeData: impactData,
        defaultImpactSizeData,
        defaultSize,
      });
    }
  }

  onSizeChange = (e) => {
    let selectedSize = e.target.value;
    let defaultImpactSizeData = this.state.impactSizeData.filter(function (e) {
      return e.size === selectedSize;
    });

    this.setState({
      defaultImpactSizeData,
      defaultSize: selectedSize,
    });
  };

  render() {
    const {
      productDetail,
      loading,
      btnLoading,
      impactSizeData,
      defaultImpactSizeData,
      defaultSize,
    } = this.state;
    return (
      <div className="container">
        <div className="prod-detail">
          <Spin spinning={loading}>
            <Row gutter={{ xs: 0, sm: 0 }}>
              <Col xs={24} md={12} className="left_clm">
                <ImageGallery
                  items={this.state.galleryProductImages}
                  showThumbnails={true}
                  showFullscreenButton={false}
                  showBullets={false}
                  showPlayButton={false}
                  thumbnailPosition={"left"}
                  showNav={false}
                  useTranslate3D={true}
                />

                <div className="prodtl pre-text">
                  <h3>Product Detail</h3>
                  <p>
                    {productDetail.detailDescription
                      ? productDetail.detailDescription
                      : ""}
                  </p>
                </div>
              </Col>
              <Col xs={24} md={12} className="right_clm">
                <div className="row1">
                  <h3>{productDetail.productName}</h3>
                  <p>
                    {productDetail.shortDescription
                      ? productDetail.shortDescription
                      : ""}
                  </p>
                  <span className="price">
                    € {productDetail.price !== "" ? productDetail.price : 0}
                  </span>
                </div>
                <div className="sizediv">
                  <h3>Available Size </h3>
                  <div className="sizeselect">
                    <Radio.Group
                      buttonStyle="solid"
                      value={defaultSize}
                      onChange={this.onSizeChange}
                    >
                      {impactSizeData &&
                        impactSizeData.map((item) => (
                          <Radio.Button value={item.size} key={item.size}>
                            {item.size}
                          </Radio.Button>
                        ))}
                    </Radio.Group>
                  </div>
                </div>
                <div className="btnrow">
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      size="large"
                      loading={btnLoading}
                      onClick={() => this.handleBuyNow(productDetail)}
                    >
                      Buy Now
                    </Button>
                    <Link to="/products">
                      <Button type="primary" htmlType="submit" size="large">
                        Continue Shopping
                      </Button>
                    </Link>
                  </Space>
                </div>
                <div className="impactdtl">
                  <Row gutter={[15]}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={co2}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {defaultImpactSizeData[0]
                              ? defaultImpactSizeData[0].kgco2
                              : 0}
                          </h5>
                          Kilogram CO2
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={litreWater}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {defaultImpactSizeData[0]
                              ? defaultImpactSizeData[0].liter
                              : 0}
                          </h5>
                          Liter Water
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={arroundWorld}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {defaultImpactSizeData[0]
                              ? defaultImpactSizeData[0].kmtravel
                              : 0}
                          </h5>
                          Total Distance (km)
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={recycledMaterial}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {productDetail.recycledMaterial ? "Yes" : "No"}
                          </h5>
                          Recycled Material
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={bioMaterial}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {productDetail.biobasedMaterial ? "Yes" : "No"}
                          </h5>
                          Biobased
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={refurMaterial}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {productDetail.refurbishedMaterial ? "Yes" : "No"}
                          </h5>
                          Long Lifetime Material
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={refurMaterial}></img>
                        </div>
                        <div className="lbld">
                          <h5>{productDetail.reusedMaterial ? "Yes" : "No"}</h5>
                          Refurbished Material
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={organicFiber}></img>
                        </div>
                        <div className="lbld">
                          <h5>{productDetail.vegan ? "Yes" : "No"}</h5>
                          Vegan
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={organicFiber}></img>
                        </div>
                        <div className="lbld">
                          <h5>{productDetail.organicFiber ? "Yes" : "No"}</h5>
                          Organic Fiber
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="impwrap">
                        <div className="icod">
                          <img alt="no-img" src={recycledMaterial}></img>
                        </div>
                        <div className="lbld">
                          <h5>
                            {productDetail.secondLifePossible ? "Yes" : "No"}
                          </h5>
                          Second Life Possible
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="sizediv">
                  <h3>Social Impact </h3>
                  <div className="sizeselect">
                    <p className="pre-text">{productDetail.socialImpact}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

export default withApollo(ProductDetail);

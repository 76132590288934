import React, { Component } from "react";
import { Col, Row, Carousel } from "antd";
import LatestProductList from "./LatestProductList";
import LatestBlogPost from "./LatestBlogPost";
import "../Home.scss";
import LatestContent from "./LatestContent";

export class Home extends Component {
  render() {
    return (
      <>
        <div className="landing_content">
          <div className="container container_small">
            <Row gutter={{ xs: 8, sm: 8 }} className="coursol_row">
              <Col xs={24} sm={24} md={16} lg={16}>
                <Carousel autoplay>
                  <div>
                    <h3>
                      FINDING A NEW FASHION FAVOURITE IS ALREADY A CHALLENGE.
                      LET SUSTAINABILITY BE THE EASY PART.
                    </h3>
                  </div>
                </Carousel>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <LatestBlogPost />
              </Col>
            </Row>
            <div className="collection">
              <LatestProductList history={this.props.history} />
            </div>
            <div className="collection">
              <LatestContent />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;

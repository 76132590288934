import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form, Popconfirm, Select } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

class AdminAddWeightSize extends Component {
  render() {
    return (
      <>
        <label>Product weight per size *</label>
        <Form.Item>
          {this.props.weightSizeArr.map((x, index) => {
            return (
              <Row gutter={[10]} className="addrow" key={index}>
                <Col xs={12} sm={12} lg={12}>
                  <Form.Item name={`attributeValueId` + index}>
                    <Select
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Please select available sizes"
                      onChange={(value) => {
                        this.props.handleCloneSelectChange(value, index);
                      }}
                      value={
                        x.attributeValue
                          ? x.attributeValue.attributeValueId
                          : ""
                      }
                    >
                      {this.props.renderSizeOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={6} sm={6} lg={6}>
                  <Form.Item
                    name={`sizeWeight` + index}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input weight!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="grams"
                      name="sizeWeight"
                      onChange={(e) => this.props.handleWeightSize(e, index)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6} sm={6} lg={6}>
                  {index !== 0 ? (
                    <Popconfirm
                      className="action"
                      title="Are you sure delete ?"
                      onConfirm={(id) => this.props.removeWeightSize(index, x)}
                      okText="Yes"
                      placement="left"
                    >
                      <Button className="close" size="small">
                        <CloseCircleOutlined />
                      </Button>
                    </Popconfirm>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            );
          })}

          <Button size="small" onClick={(e) => this.props.addWeightSize(e)}>
            <PlusCircleOutlined /> Add Fields
          </Button>
        </Form.Item>
      </>
    );
  }
}

export default withApollo(AdminAddWeightSize);

import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import BrandLayout from "./BrandLayout";

import { validateToken, redirectIfNotBrand } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

const BrandRoute = ({
  component: Component,
  ...rest
}: any & { component: any }) => {

  const brandAuthCheck = () => {
    redirectIfNotBrand();
    return validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
  }

  return (
    <BrandLayout>
      <Route
        {...rest}
        render={(props) => {
          return brandAuthCheck() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      />
    </BrandLayout>
  );
};

export default BrandRoute;

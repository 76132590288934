import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Row, Col, Input, Button, Form, Divider } from "antd";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

class ForgotPassword extends React.Component {
  state = {
    btnLoading: false,
  };

  onFinish = async (values) => {
    this.setState({ btnLoading: true });

    Auth.forgotPassword(this.state.email)
      .then((user) => {
        SuccessNotificationMsg(
          "Verification Mail Sent Successfully!",
          "Please check your mail inbox, for verification code!"
        );

        this.setState({ btnLoading: false });
        this.props.history.push("/forgot-password-verification");
      })
      .catch((err) => {
        if (err.code !== undefined && err.code === "UserNotFoundException") {
          err.message = "Email id not registered with us.";
        }
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="authPage">
        <div className="container">
          <div className="login_wraper">
            <div className="login-box">
              <h1 className="text-center">Forgot your password?</h1>
              <Divider />
              <p className="text-center">
                Forgot your password? No worries! Enter your email below and
                we'll send you a link to reset your password.
              </p>

              <Form
                autoComplete="off"
                onFinish={this.onFinish}
                initialValues={
                  {
                    //email: props.stateValues.email,
                  }
                }
              >
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 10]}>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid e-mail!",
                        },
                        {
                          required: true,
                          message: "Please input E-mail!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email address *"
                        onChange={this.handleInputChange("email")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 30]}>
                  <Col className="gutter-row text-right" span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w100"
                      loading={this.state.btnLoading}
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Row>

                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 0]}>
                  <Col className="gutter-row" span={24}>
                    <p className="text-center">
                      <Link to="/login" className="button-link">
                        Return to Login
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;

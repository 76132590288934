import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Col, Row, Layout, Button, Breadcrumb, Space } from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import ProductClickCountChart from "./ProductClickCountChart";
import ProductCountSummary from "./ProductCountSummary";

import Sidebar from "../common/Sidebar";
import { authUserData } from "../../../utils/Helpers";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { PRODUCT_COUNT_QUERY } from "../product/ProductQuery";

import "../dashboard/Dashboard.scss";
import logoWithLine from "../../../images/logo-with-line.svg";

const { Content } = Layout;
class Dashboard extends Component {
  state = {
    authUserId: authUserData().appusersId,
    brandProductCount: 0,
    chartParams: {
      deleted: 0,
      status: true,
      orderBy: "-click_count",
      first: 5,
      userId: authUserData().appusersId,
    },
    filterParamObj: {
      brandId: authUserData().onboardingId,
    },
  };

  componentDidMount() {
    this.getBrandProductCount();
  }

  getBrandProductCount = async () => {
    const { client } = this.props;
    await client
      .query({
        query: PRODUCT_COUNT_QUERY,
        variables: { userId: this.state.authUserId, deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        let brandProductCount = result.data.allProduct.filterCount;
        this.setState({
          brandProductCount: brandProductCount,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Dashboard
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Row gutter={[0]} className="dashboard-welcome-box">
                  <Col sm={24} md={24}>
                    <div className="left_content">
                      <img
                        className="linelogo"
                        alt="icon"
                        src={logoWithLine}
                      ></img>
                      <h3>
                        Welcome to your very <br />
                        own product dashboard!
                      </h3>
                    </div>
                  </Col>
                </Row>

                <div className="productList">
                  <div className="noProduct">
                    {this.state.brandProductCount > 0 ? (
                      <Row
                        gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                        align="middle"
                      >
                        <Col flex="auto">
                          You have {this.state.brandProductCount} products
                          currently
                        </Col>
                        <Col flex="auto" align="end">
                          <Space>
                            <Link to="/seller-manage-product">
                              <Button
                                type="primary"
                                htmlType="submit"
                                icon={<UnorderedListOutlined />}
                              >
                                MANAGE PRODUCT
                              </Button>
                            </Link>
                            <Link to="/seller-add-product">
                              <Button
                                type="primary"
                                htmlType="submit"
                                icon={<PlusOutlined />}
                              >
                                ADD PRODUCT
                              </Button>
                            </Link>
                          </Space>
                        </Col>
                      </Row>
                    ) : (
                      <Row
                        gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                        align="middle"
                      >
                        <Col flex="auto">
                          <h3>Actions Needed</h3>
                        </Col>
                        <Col flex="auto" align="end">
                          <Link to="/seller-store-help">
                            <Button
                              type="primary"
                              htmlType="submit"
                              icon={<SettingOutlined />}
                            >
                              Setup Shop Integration
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
                <br />

                <ProductCountSummary
                  filterParamObj={this.state.filterParamObj}
                />

                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={24}>
                    <ProductClickCountChart
                      chartParams={this.state.chartParams}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(Dashboard);

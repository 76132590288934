import React, { Component } from "react";
import { Col, Skeleton } from "antd";
export class LatestProductListSkeleton extends Component {
  state = {
    active: false,
    size: "default",
    buttonShape: "default",
    avatarShape: "circle",
    colArr: ["1", "2", "3"],
  };

  render() {
    const { buttonShape, colArr } = this.state;
    return (
      <React.Fragment>
        {colArr.length > 0 &&
          colArr.map((book) => (
            <Col xs={24} sm={12} md={8} lg={8} key={book}>
              <div className="prowrap">
                <Skeleton.Image className="skeleton-img" />
                <Skeleton.Button
                  active
                  className="skeleton20"
                  shape={buttonShape}
                ></Skeleton.Button>
              </div>
            </Col>
          ))}
      </React.Fragment>
    );
  }
}

export default LatestProductListSkeleton;

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Spin,
  Button,
  Form,
  Divider,
  Space,
  Layout,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";
import AdminSidebar from "../common/AdminSidebar";

import {
  ADD_INDICATOR_QUERY,
  UPDATE_INDICATOR_QUERY,
  INDICATOR_LIST_QUERY,
} from "./ImpactIndicatorQuery";

const editorConfiguration = {
  removePlugins: ["ImageUpload"],
  //toolbar: ["bold", "italic", "bulletedList", "numberedList", "blockQuote"],
};
const { Content } = Layout;

class AddImpactIndicator extends Component {
  state = {
    btnLoading: false,
    IndicatorRecordId: 0,
    apiLoading: false,
    authUserId: authUserData().appusersId,
  };

  formRef = React.createRef();

  componentDidMount() {
    let IndicatorRecordId = this.props.match.params.Id
      ? atob(this.props.match.params.Id)
      : 0;

    if (IndicatorRecordId > 0) {
      this.getIndicatorDetail(IndicatorRecordId);
      this.setState({ IndicatorRecordId });
    }
  }

  onFinish = () => {
    if (this.state.IndicatorRecordId === 0) {
      this.saveIndicator();
    } else {
      this.updateIndicator();
    }
  };

  saveIndicator = () => {
    this.setState({ btnLoading: true });

    this.props.client
      .mutate({
        mutation: ADD_INDICATOR_QUERY,
        variables: this.state,
      })
      .then(() => {
        SuccessNotificationMsg(
          "Success!",
          "Impact Indicator added successfully."
        );
        this.formRef.current.resetFields();
        this.props.history.push("/admin-manage-impact-indicator");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getIndicatorDetail = (IndicatorRecordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .query({
        query: INDICATOR_LIST_QUERY,
        variables: { impactIndicatorId: IndicatorRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allImpactIndicator.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allImpactIndicator.edges[0].node
        );
        this.setState({ apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  updateIndicator = () => {
    if (this.state.blogDescription === "" || this.state.Description) {
      ErrorNotificationMsg("Please input description and blog description!");
      return false;
    }

    this.props.client
      .mutate({
        mutation: UPDATE_INDICATOR_QUERY,
        variables: this.state,
      })
      .then((response) => {
        SuccessNotificationMsg(
          "Success!",
          "Impact Indicator updated successfully."
        );
        this.props.history.push("/admin-manage-impact-indicator");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {this.state.IndicatorRecordId > 0
                  ? "Edit Impact Indicator"
                  : "Add Impact Indicator"}
              </div>
              <div className="content_wraper">
                <div className="stepWraper">
                  <div className="onboarding-form-body">
                    <Spin spinning={this.state.apiLoading}>
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        autoComplete="off"
                      >
                        <Row gutter={[15]}>
                          <Col xs={24} sm={12} lg={12}>
                            <label>Title*</label>
                            <Form.Item
                              name="title"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input title!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter title"
                                onChange={this.handleInputChange("title")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={12}>
                            <label>URL*</label>
                            <Form.Item
                              name="url"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input URL!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter URL"
                                onChange={this.handleInputChange("url")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} lg={24}>
                            <label>Description*</label>
                            <Form.Item name="description">
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={this.state.description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({ description: data });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} lg={12}>
                            <label>Shop Now URL*</label>
                            <Form.Item
                              name="shopNowUrl"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input shop now URL!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Shop Now URL"
                                onChange={this.handleInputChange("shopNowUrl")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />

                        <Row gutter={[15]}>
                          <Col xs={24} sm={12} lg={12}>
                            <label>Blog Title*</label>
                            <Form.Item
                              name="blogTitle"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input blog title!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Blog Title"
                                onChange={this.handleInputChange("blogTitle")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={12}>
                            <label>Blog Subject*</label>
                            <Form.Item
                              name="blogSubject"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input blog subject!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Blog Subject"
                                onChange={this.handleInputChange("blogSubject")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} lg={24}>
                            <label>Blog Description*</label>
                            <Form.Item name="blogDescription">
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={this.state.blogDescription}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({ blogDescription: data });
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <Row gutter={[15]} className="bottomButtons">
                          <Col xs={24} align="end">
                            <Space>
                              <Button
                                type="secondary"
                                htmlType="button"
                                onClick={this.props.history.goBack}
                              >
                                BACK
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                              >
                                SUBMIT
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddImpactIndicator);

import React, { Component } from "react";
import { Row, Col } from "antd";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import jsp from "../../../images/jasper.webp";
import infographics from "../../../images/infographics.webp";
import CO2big from "../../../images/CO2big.webp";
import waterbig from "../../../images/waterbig.webp";
import distancebig from "../../../images/distancebig.webp";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="container">
        <div className="howwedo">
          <h1>HOW WE CALCULATE THE TRUE COSTS OF YOUR PRODUCT</h1>

          <div className="infographic">
            <div className="infographicsimg">
              <LazyLoadImage
                src={infographics} 
                alt="infographics"
              />
            </div>

            <div className="infographics-content">
              <p>
                At True Cost Label, we analyse the impact of products using a
                technique called Life Cycle Assessment or LCA. We reveal the
                invisible costs of a brand’s fashion piece by calculating CO2
                emissions, Water use and Distance travelled along the
                supplychain to the brand. We use the icons below to communicate
                this environmental impact. To calculate these numbers we apply
                the True Cost Generator, an in-house developed software
                application complying with EU recommended standards for Life
                Cycle Analysis. A technical explanation follows.
              </p>
              <div className="info-icons">
                <div>
                  <div className="icons">
                   <LazyLoadImage src={CO2big} alt="CO2big"/>
                  </div>
                  <h3>Kilogram CO2</h3>
                </div>
                <div>
                  <div className="icons">
                   <LazyLoadImage src={waterbig} alt="waterbig"/>
                  </div>
                  <h3>Water use (L)</h3>
                </div>
                <div>
                  <div className="icons">
                   <LazyLoadImage src={distancebig} alt="distancebig"/>
                  </div>
                  <h3>Distance (km)</h3>
                </div>
              </div>
            </div>
          </div>
          <h1>Benchmarking</h1>
          <div className="normal_div">
            <p>
              All products on the True Cost Label platform have their impact
              benchmarked to a product of the same make and build, but then
              following conventional production and manufacturing standards
              which is most common and average for the industry. Our
              conventional t-shirt, is a t-shirt made with commodity trademix
              cotton originating in an average Asian production country (i.e.
              China, India, Bangladesh). The t-shirt is produced according to
              the manufacturing standards of fast fashion production, using
              average spinning, knitting or weaving, dyeing, embroidering,
              printing and other techniques based on an average energy grid
              representative for those countries and industries.
            </p>
            <p>
              The benchmark with scores will look like this for each product:{" "}
            </p>
            <Row justify="space-between" gutter={[18]} align="middle">
              <Col xs={24} md={3}>
                <div className="img-wrapper">
                  <LazyLoadImage src={CO2big} alt="CO2big" style={{ width: 80 }}/>
                  <div className="img-text">
                    <span>1,50 kg</span>
                    <span>-60%</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={18}>
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    A product with the indicator on the left has a{" "}
                    <strong>total</strong> climate change impact of{" "}
                    <strong>1,50 kg CO2-equivalent</strong> per piece.
                  </li>
                  <li>
                    Compared to a conventional item of the same make and build{" "}
                    <strong>you will save 60% of the total impact.</strong>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <h1>Technical Explanation</h1>
          <div className="normal_div">
            All LCAs made by True Cost Label B.V. including the data and methods
            contained within are calculated using our own developed tool, the
            ‘True Cost Generator’. A custom LCA tool built by True Cost Label,
            specifically for Fashion LCAs and the detailed supply chains of the
            fashion industry.
          </div>
          <ul className="mainul">
            <li>
              <div className="count">1</div>
              <h3>Software, Databases and Methodology applied. </h3>
              <p>
                We apply OpenLCA[1] software to access input data for the True
                Cost Generator, with data mainly but not exclusively deriving
                from the following databases:
                <br />
                Ecoinvent 3.6 [2];
                <br />
                Idemat 2021 by TU Delft [3];.
              </p>
            </li>
            <li>
              <div className="count">2</div>
              <h3>
                We apply the following Impact Assessment Methods for data
                retrieved and used in our LCAs
              </h3>
              <p>
                A) Carbon footprint: IPCC 2013 GWP 100a [4] as recommended by
                the European Platform on Life Cycle Assessment: ILCD [5]
                (International Reference Life Cycle Data System and the
                Greenhouse Gas Protocol
                <br />
                B) Water Depletion: ILCD 2011; Resource depletion - water;
                midpoint; freshwater scarcity; Swiss Ecoscarcity 2006. <br />
                C) Total distance in kilometer and mode of transport: Supply
                chain data provided by the customer in combination with Google
                maps and Sea Distances. <br />
                <br />
                In addition, LCA data is included from carefully selected LCAs
                from peer reviewed scientific papers. This is mostly done for
                innovative textile production processes or processes poorly
                modelled in existing databases. Assumptions made for these
                additions are stated in detail in each report
              </p>
            </li>
            <li>
              <div className="count">3</div>
              <h3>Goal and scope</h3>
              <p>
                We calculate our LCAs with a functional unit of total impact per
                kg of product from the raw materials to the manufacturing of the
                product with all transport processes included. (Cradle-to-Gate).
              </p>
            </li>
            <li>
              <div className="count">4</div>
              <h3>Standardization </h3>
              <p>
                True Cost Label Applies the ILCD method for its impact numbers,
                which is standardized according to EU-PEF method: European
                Product Environmental Footprint (EC, 2018. Product environmental
                footprint category rules, version 6.3). Our LCAs, LCA Reports
                and advise given based on LCA results follow the general
                principles of the ISO14044 quality standard for Life Cycle
                Assessment
              </p>
            </li>
            <li>
              <div className="count">5</div>
              <h3>Benchmarking </h3>
              <p>
                All products on the True Cost Label platform have their impact
                benchmarked to a product of the same make and build, but then
                following conventional production and manufacturing standards
                which is most common and average for the industry. Our
                conventional t-shirt, is a t-shirt made with commodity trademix
                cotton originating in an average Asian production country (i.e.
                China, India, Bangladesh). The t-shirt is produced according to
                the manufacturing standards of fast fashion production, using
                average spinning, knitting or weaving, dyeing, embroidering,
                printing and other techniques based on an average energy grid
                representative for those countries and industries.
              </p>
            </li>
            <li>
              <div className="count">6</div>
              <h3>References: </h3>
              <p>
                1.{" "}
                <a
                  href="https://www.openlca.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.openlca.org/
                </a>{" "}
                <br />
                2.
                <a
                  href="https://ecoinvent.org/the-ecoinvent-database/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ecoinvent.org/the-ecoinvent-database/
                </a>
                <br />
                3.{" "}
                <a
                  href="https://www.ecocostsvalue.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ecocostsvalue.com/
                </a>
                <br />
                4.{" "}
                <a
                  href="https://www.ipcc.ch/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ipcc.ch/
                </a>
                <br />
                5.
                <a
                  href="https://eplca.jrc.ec.europa.eu/uploads/ILCD-Recommendation-of-methods-for-LCIA-def.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://eplca.jrc.ec.europa.eu/uploads/ILCD-Recommendation-of-methods-for-LCIA-def.pdf
                </a>
                <br />
                6.{" "}
                <a
                  href="https://www.iso.org/standard/38498.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.iso.org/standard/38498.html
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="anyqa">
          <div className="lc">
            <h3>ANY QUESTIONS ABOUT THIS?</h3>
            <p>
              For questions related to our methods, calculations and numbers
              provided on the website, please take up contact with our LCA
              expert.
              <br />
              <br />
              <br />
              Tel: (+31) 6 31 69 73 59 <br />
              E-mail: Jasper@truecostlabel.com
            </p>
          </div>
          <div className="rc">
            <img src={jsp} alt="jsp"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;

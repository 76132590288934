import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Row, Col, Card } from "antd";

import { authUserData } from "../../../utils/Helpers";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { GET_PRODUCT_COUNT_SUMMARY_QUERY } from "../product/ProductQuery";
import "../dashboard/Dashboard.scss";

import visitorIcon from "../../../images/visitor.png";
import revenueIcon from "../../../images/revenue.png";
import tbdIcon from "../../../images/tbd.png";

class ProductCountSummary extends Component {
  state = {
    authUserId: authUserData().appusersId,
    filterParamObj: this.props.filterParamObj ? this.props.filterParamObj : {},
    apiLoading: false,
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_PRODUCT_COUNT_SUMMARY_QUERY,
        variables: this.state.filterParamObj,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({ data: response.data.productCnt, apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  render() {
    const { data } = this.state;
    return (
      <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} className="card_wrap">
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Row>
              <Col>
                <img className="cardicon" alt="icon" src={visitorIcon}></img>
              </Col>
              <Col flex="auto">
                <h3>{data[0] ? data[0].activePrdCnt : 0}</h3>
                <h5>Active Products</h5>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Row>
              <Col>
                <img className="cardicon" alt="icon" src={revenueIcon}></img>
              </Col>
              <Col flex="auto">
                <h3>{data[0] ? data[0].totalPrdClick : 0}</h3>
                <h5>Product Clicks This Month</h5>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card bordered={false}>
            <Row>
              <Col>
                <img className="cardicon" alt="icon" src={tbdIcon}></img>
              </Col>
              <Col flex="auto">
                <h3>{data[0] ? data[0].overPrdClick : 0}</h3>
                <h5>Total Clicks</h5>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withApollo(ProductCountSummary);

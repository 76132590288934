import gql from "graphql-tag";

export const UPLOAD_PRODUCT_DOCUMENTS = gql`
  mutation($productFiles: Upload!, $productId: Int!, $fileType: String) {
    uploadProductFile(
      productFile: $productFiles
      productId: $productId
      fileType: $fileType
    ) {
      success
      presignedUrl
      productfile {
        productfilesId
        productfileName
        fileType
        fileExtension
        awsFileUrl
        fileSize
        id
      }
    }
  }
`;

export const GET_PRODUCT_DOCUMENT_QUERY = gql`
  query allProductfiles($deleted: Int, $productId: Int) {
    allProductfiles(deleted: $deleted, productId: $productId) {
      totalCount
      edges {
        node {
          id
          fileExtension
          awsFileUrl
          fileType
          productfilesId
          productfileName
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_DOCUMENT_QUERY = gql`
  mutation deleteProductFile($productfilesId: Int!, $productFile: String) {
    deleteProductFile(
      data: { productfilesId: $productfilesId, productFile: $productFile }
    ) {
      productfilesId
    }
  }
`;

export const DOWNLOAD_PRODUCT_DOCUMENT_QUERY = gql`
  mutation downloadProductFile($productFile: String) {
    downloadProductFile(productFile: $productFile) {
      success
      presignedUrl
    }
  }
`;

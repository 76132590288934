import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Form,
  Checkbox,
  Divider,
  Popconfirm,
  Space,
  AutoComplete,
  Select,
} from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import AdminSidebar from "../common/AdminSidebar";

import { GET_COMPANY_DETAIL_QUERY } from "../../brand/common/ProfileQuery";
import { GET_SUPPLYCHAIN_DETAIL_QUERY } from "../../brand/supplychain/SupplychainQuery";

import {
  ADD_PRODUCT_QUERY,
  UPDATE_PRODUCT_QUERY,
  GET_PRODUCT_DETAIL_QUERY,
} from "../../brand/product/ProductQuery";

import {
  UPDATE_COMPOSITION_QUERY,
  GET_COMPOSITION_DETAIL_QUERY,
  DELETE_COMPOSITION_QUERY,
} from "../../brand/product/CompositionQuery";

import {
  UPDATE_IMPACT_SIZE_QUERY,
  GET_IMPACT_SIZE_DETAIL_QUERY,
  DELETE_IMPACT_SIZE_QUERY,
} from "../../brand/product/ImpactSizeQuery";

import {
  UPDATE_WEIGHT_SIZE_QUERY,
  GET_WEIGHT_SIZE_DETAIL_QUERY,
  DELETE_WEIGHT_SIZE_QUERY,
} from "../../brand/product/WeightSizeQuery";

import { GET_ATTRIBUTES_WITH_VALUES } from "../../common/AttributeQuery";
import AdminAddImpactSize from "./AdminAddImpactSize";
import AdminAddWeightSize from "./AdminAddWeightSize";

const { TextArea } = Input;
const { Option } = Select;
class AdminAddProduct extends Component {
  state = {
    btnLoading: false,
    authUserId: 0,
    productRecordId: 0,
    supplyChainId: 0,
    compositions: [{ materialName: "", percentage: "" }],
    weightSizeArr: [{ attributeValueId: "", sizeWeight: "" }],
    impactSizeArr: [
      { attributeValueId: "", weight: "", kgco2: "", liter: "", kmtravel: "" },
    ],
    currentRoute: this.props.location.pathname,
    endOfLifeOthers: "",
    sizes: [],
    productType: [],
    endOfLifeBiodegradable: false,
    endOfLifeRecyclable: false,
    endOfLifeProductAsAService: false,
    endOfLifeRepairServiceBrand: false,
    gender: "Male",
    price: 0,
    productName: "",
    detailDescription: "",
    productLink: "",
    linkToBrand: "",
    biobasedMaterial: false,
    refurbishedMaterial: false,
    reusedMaterial: false,
    recycledMaterial: false,
    vegan: false,
    secondLifePossible: false,
    socialImpact: "",
    organicFiber: false,
    colors: "",
    sizeArr: [],
    productTypeArr: [],
  };

  productFormRef = React.createRef();

  componentDidMount() {
    let productRecordId = this.props.match.params.productId
      ? atob(this.props.match.params.productId)
      : 0;

    if (productRecordId > 0) {
      this.getProductDetail(productRecordId);
      this.getComposition(productRecordId);
      this.getImpactSize(productRecordId);
      this.getWeightSize(productRecordId);
      this.setState({ productRecordId });
    }
    this.getAttributes();
  }

  onFinish = () => {
    if (this.state.productRecordId === 0) {
      this.saveProduct();
    } else {
      this.updateProduct();
    }
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleMultiSelectChange = (input) => (value) => {
    this.setState({ [input]: value });
  };

  handleSelectChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState({ [input]: event.target.checked });
  };

  onSubmitStepButton = () => {
    SuccessNotificationMsg("Success!", "Product updated successfully.");
    this.state.history.push("/admin-manage-product");
  };

  saveProduct = async () => {
    if (this.state.authUserId === 0 || this.state.supplyChainId === 0) {
      ErrorNotificationMsg(
        "Error",
        "Please select first brand and supplychain."
      );
      return false;
    }

    const { client } = this.props;
    this.setState({ btnLoading: true });
    await client
      .mutate({
        mutation: ADD_PRODUCT_QUERY,
        variables: this.state,
      })
      .then((response) => {
        this.setState({
          productRecordId: response.data.createProduct.product.productId,
        });
        return this.updateComposition(response.data.createProduct);
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Product added successfully.");
        setTimeout(() => {
          window.location.href = "/admin-manage-product";
        }, 1000);
      })
      .catch((e) => {
        ErrorNotificationMsg(
          "Error 1",
          e.message.replace("GraphQL error:", "")
        );
        this.setState({ btnLoading: false });
      });
  };

  updateProduct = async () => {
    if (this.state.authUserId === 0 || this.state.supplyChainId === 0) {
      ErrorNotificationMsg(
        "Error 2",
        "Please select first brand and supplychain."
      );
      return false;
    }

    const { client } = this.props;
    this.setState({ btnLoading: true });

    await client
      .mutate({
        mutation: UPDATE_PRODUCT_QUERY,
        variables: this.state,
      })
      .then((response) => {
        return this.updateComposition(response.data.updateProduct);
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Product updated successfully.");
        setTimeout(() => {
          window.location.href = "/admin-manage-product";
        }, 1000);
      })
      .catch((e) => {
        ErrorNotificationMsg(
          "Error 3",
          e.message.replace("GraphQL error:", "")
        );
        this.setState({ btnLoading: false });
      });
  };

  updateComposition = async (response) => {
    const newCompositionArr = this.state.compositions.map((objItems) => {
      delete objItems.__typename;
      delete objItems.id;
      return { ...objItems, productId: response.product.productId };
    });

    this.setState({ compositions: newCompositionArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_COMPOSITION_QUERY,
        variables: {
          compositions: this.state.compositions,
        },
      })
      .then((result) => {
        this.updateWeightSize(response.product.productId);
        return this.updateImpactSize(response.product.productId);
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  getSupplychainDetail = (supplychainId) => {
    if (
      supplychainId === null ||
      supplychainId === undefined ||
      supplychainId === 0
    ) {
      return false;
    }

    const { client } = this.props;
    let searchObj = {
      supplychainId,
    };

    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: searchObj,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({
          selectedSupplychainTitle:
            response.data.allSupplychain.edges[0].node.supplyChainName,
        });
        this.productFormRef.current.setFieldsValue({
          supplyChainSelect:
            response.data.allSupplychain.edges[0].node.supplyChainName,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  getBrandDetail = (userId) => {
    const { client } = this.props;
    let searchObj = {
      userId,
    };

    client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: searchObj,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({
          selectedBrandTitle: response.data.allVendors.edges[0].node.company,
          authUserId: userId,
        });
        this.productFormRef.current.setFieldsValue({
          brandSelect: response.data.allVendors.edges[0].node.company,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  getProductDetail = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_PRODUCT_DETAIL_QUERY,
        variables: { productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let { sizes, productType } = this.prepareProductAttributeArr(
          response.data.allProduct.edges[0].node
        );

        delete response.data.allProduct.edges[0].node.productattributemodelSet;

        this.setState({
          sizes,
          productType,
          ...response.data.allProduct.edges[0].node,
        });

        this.productFormRef.current.setFieldsValue(
          response.data.allProduct.edges[0].node
        );

        this.getSupplychainDetail(
          response.data.allProduct.edges[0].node.supplyChainId
        );

        this.getBrandDetail(
          response.data.allProduct.edges[0].node.userId.appusersId
        );
      })
      .catch((e) => {
        console.log(e);
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  prepareProductAttributeArr = (productData) => {
    let response = {
      sizes: [],
      productType: [],
    };

    if (
      productData.productattributemodelSet &&
      productData.productattributemodelSet.edges.length > 0
    ) {
      productData.productattributemodelSet.edges.map((attrObj, index) => {
        if (attrObj.node.attributeValue.attribute.attributeId === "1") {
          response.sizes.push(attrObj.node.attributeValue.attributeValueId);
        }
        if (attrObj.node.attributeValue.attribute.attributeId === "2") {
          response.productType.push(
            attrObj.node.attributeValue.attributeValueId
          );
        }
        return null;
      });
    }

    return response;
  };

  getComposition = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_COMPOSITION_DETAIL_QUERY,
        variables: { deleted: 0, productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        let mcFieldObject = {};

        if (response.data.allMaterialcomposition.edges.length > 0) {
          response.data.allMaterialcomposition.edges.map(
            (objectValue, objectKey) => {
              mcStatArr.push(objectValue.node);
              mcFieldObject["materialName" + objectKey] =
                objectValue.node.materialName;
              mcFieldObject["percentage" + objectKey] =
                objectValue.node.percentage;
              return null;
            }
          );
        }
        this.setState({ compositions: mcStatArr });
        this.productFormRef.current.setFieldsValue(mcFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  addComposition = () => {
    this.setState(
      {
        compositions: [
          ...this.state.compositions,
          { materialName: "", percentage: "" },
        ],
      },
      () => {
        this.setFieldValue(this.state.compositions);
      }
    );
  };

  handleComposition = (e, index) => {
    const newCompositionList = [...this.state.compositions];
    newCompositionList[index][e.target.name] = e.target.value;
    this.setState({ compositions: newCompositionList });
  };

  removeComposition = async (index, compositionObject) => {
    if ("materialcompositionId" in compositionObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_COMPOSITION_QUERY,
          variables: {
            materialcompositionId: compositionObject.materialcompositionId,
          },
        })
        .then((result) => {
          this.setState(
            {
              compositions: this.state.compositions.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setFieldValue(this.state.compositions);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.state.compositions.splice(index, 1);
      this.setState({ compositions: this.state.compositions }, () => {
        this.setFieldValue(this.state.compositions);
      });
    }
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["materialName" + objectKey] = objectValue.materialName;
      mcFieldObject["percentage" + objectKey] = objectValue.percentage;
      return null;
    });
    this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  onBrandSelect = (value, option) => {
    this.productFormRef.current.setFieldsValue({
      supplyChainSelect: "",
    });
    this.setState({ authUserId: option.id, supplyChainId: 0 });
  };

  onBrandSearch = (searchText) => {
    if (searchText.length < 3) {
      return true;
    }

    const { client } = this.props;
    client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allVendors.totalCount > 0) {
          response.data.allVendors.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["label"] = objectValue.node.company;
            mcFieldObject["value"] = objectValue.node.company;
            mcFieldObject["id"] = objectValue.node.userId.appusersId;
            mcStatArr.push(mcFieldObject);
            return null;
          });
        }
        this.setState({ brandSearchOptions: mcStatArr });
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  onSupplychainSelect = (value, option) => {
    this.setState({ supplyChainId: option.id });
  };

  onSupplychainSearch = (searchText) => {
    if (this.state.authUserId === undefined || this.state.authUserId === 0) {
      ErrorNotificationMsg("Error", "Please select first brand.");
      return false;
    }

    if (searchText.length < 3) {
      return true;
    }

    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: {
          first: 10,
          deleted: 0,
          userId: this.state.authUserId,
          supplyChainName_Icontains: searchText,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allSupplychain.totalCount > 0) {
          response.data.allSupplychain.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["label"] = objectValue.node.supplyChainName;
            mcFieldObject["value"] = objectValue.node.supplyChainName;
            mcFieldObject["id"] = objectValue.node.supplychainId;
            mcStatArr.push(mcFieldObject);
            return null;
          });
        }

        this.setState({ supplychainSearchOptions: mcStatArr });
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  handleCloneSelectChange = (input, index) => {
    let selected = this.state.weightSizeArr;
    if (selected[index]) {
      selected[index].attributeValueId = input;
      this.setState({ weightSizeArr: selected }, () => {});
    }
  };

  handleImpactSizeSelectChange = (input, index) => {
    let selected = this.state.impactSizeArr;
    if (selected[index]) {
      selected[index].attributeValueId = input;
      this.setState({ impactSizeArr: selected }, () => {});
    }
  };

  getAttributes = () => {
    this.props.client
      .query({
        query: GET_ATTRIBUTES_WITH_VALUES,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let sizeArr = "";
        let productTypeArr = "";

        if (response.data.allAttribute.edges.length > 0) {
          response.data.allAttribute.edges.map((attributes) => {
            if (attributes.node.attributeId === "1") {
              sizeArr = attributes.node.attributevaluemodelSet;
            }
            if (attributes.node.attributeId === "2") {
              productTypeArr = attributes.node.attributevaluemodelSet;
            }
            return null;
          });
          this.setState({ productTypeArr, sizeArr });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  renderSizeOptions = () => {
    return (
      <>
        {this.state.sizeArr.edges &&
          this.state.sizeArr.edges.map((attributes) => {
            return (
              <Option key={attributes.node.attributeValueId}>
                {attributes.node.value}
              </Option>
            );
          })}
      </>
    );
  };

  renderProductTypeOptions = () => {
    return (
      <>
        {this.state.productTypeArr.edges &&
          this.state.productTypeArr.edges.map((attributes) => {
            console.log("1");
            return (
              <Option key={attributes.node.attributeValueId}>
                {attributes.node.value}
              </Option>
            );
          })}
      </>
    );
  };

  // ====================== Manage Impact Size ================
  getImpactSize = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_IMPACT_SIZE_DETAIL_QUERY,
        variables: { deleted: 0, productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mrStatArr = [];
        let mrFieldObject = {};

        if (response.data.allImpactSize.edges.length > 0) {
          response.data.allImpactSize.edges.map((objectValue, objectKey) => {
            mrStatArr.push({
              attributeValueId: objectValue.node.attributeValue
                ? objectValue.node.attributeValue.attributeValueId
                : "",
              weight: objectValue.node.weight,
              kgco2: objectValue.node.kgco2,
              liter: objectValue.node.liter,
              kmtravel: objectValue.node.kmtravel,
              impactsizeId: objectValue.node.impactsizeId,
            });

            mrFieldObject["size_attributeValueId" + objectKey] = objectValue
              .node.attributeValue
              ? objectValue.node.attributeValue.attributeValueId
              : "";
            mrFieldObject["weight" + objectKey] = objectValue.node.weight;
            mrFieldObject["kgco2" + objectKey] = objectValue.node.kgco2;
            mrFieldObject["liter" + objectKey] = objectValue.node.liter;
            mrFieldObject["kmtravel" + objectKey] = objectValue.node.kmtravel;
            return null;
          });
        }
        this.setState({ impactSizeArr: mrStatArr });
        this.productFormRef.current.setFieldsValue(mrFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  addImpactSize = () => {
    this.setState(
      {
        impactSizeArr: [
          ...this.state.impactSizeArr,
          {
            attributeValueId: "",
            weight: "",
            kgco2: "",
            liter: "",
            kmtravel: "",
          },
        ],
      },
      () => {
        this.setImpactSizeFieldValue(this.state.impactSizeArr);
      }
    );
  };

  setImpactSizeFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["size_attributeValueId" + objectKey] =
        objectValue.attributeValueId;
      mcFieldObject["weight" + objectKey] = objectValue.weight;
      mcFieldObject["kgco2" + objectKey] = objectValue.kgco2;
      mcFieldObject["liter" + objectKey] = objectValue.liter;
      mcFieldObject["kmtravel" + objectKey] = objectValue.kmtravel;
      return null;
    });

    this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  handleImpactSize = (e, index) => {
    const newImpactSizeList = [...this.state.impactSizeArr];
    newImpactSizeList[index][e.target.name] = e.target.value;
    this.setState({ impactSizeArr: newImpactSizeList });
  };

  removeImpactSize = async (index, impactSizeObject) => {
    if ("impactsizeId" in impactSizeObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_IMPACT_SIZE_QUERY,
          variables: {
            impactsizeId: impactSizeObject.impactsizeId,
          },
        })
        .then((result) => {
          this.setState(
            {
              impactSizeArr: this.state.impactSizeArr.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setImpactSizeFieldValue(this.state.impactSizeArr);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.state.impactSizeArr.splice(index, 1);
      this.setState({ impactSizeArr: this.state.impactSizeArr }, () => {
        this.setImpactSizeFieldValue(this.state.impactSizeArr);
      });
    }
  };

  updateImpactSize = async (productId) => {
    const newImpactSizeArr = this.state.impactSizeArr.map((objItems) => {
      delete objItems.__typename;
      delete objItems.id;
      return { ...objItems, productId: productId };
    });

    this.setState({ impactSizeArr: newImpactSizeArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_IMPACT_SIZE_QUERY,
        variables: {
          impactSizeArr: this.state.impactSizeArr,
        },
      })
      .then((result) => {
        return true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  // ====================== Manage Weight Size ================
  getWeightSize = (productRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_WEIGHT_SIZE_DETAIL_QUERY,
        variables: { deleted: 0, productId: productRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mrStatArr = [];
        let mrFieldObject = {};

        if (response.data.allProductWtPSize.edges.length > 0) {
          response.data.allProductWtPSize.edges.map(
            (objectValue, objectKey) => {
              mrStatArr.push({
                attributeValueId: objectValue.node.attributeValue
                  ? objectValue.node.attributeValue.attributeValueId
                  : "",
                sizeWeight: objectValue.node.sizeWeight,
                productwtpsizeId: objectValue.node.productwtpsizeId,
              });

              mrFieldObject["attributeValueId" + objectKey] = objectValue.node
                .attributeValue
                ? objectValue.node.attributeValue.attributeValueId
                : "";
              mrFieldObject["sizeWeight" + objectKey] =
                objectValue.node.sizeWeight;
              return null;
            }
          );
        }

        this.setState({ weightSizeArr: mrStatArr });
        this.productFormRef.current.setFieldsValue(mrFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  addWeightSize = () => {
    this.setState(
      {
        weightSizeArr: [
          ...this.state.weightSizeArr,
          { attributeValueId: "", sizeWeight: "" },
        ],
      },
      () => {
        this.setWeightSizeFieldValue(this.state.weightSizeArr);
      }
    );
  };

  setWeightSizeFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["attributeValueId" + objectKey] =
        objectValue.attributeValueId;
      mcFieldObject["sizeWeight" + objectKey] = objectValue.sizeWeight;
      return null;
    });

    this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  handleWeightSize = (e, index) => {
    const newWeightSizeList = [...this.state.weightSizeArr];
    newWeightSizeList[index][e.target.name] = e.target.value;
    this.setState({ weightSizeArr: newWeightSizeList });
  };

  removeWeightSize = async (index, weightSizeObject) => {
    if ("productwtpsizeId" in weightSizeObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_WEIGHT_SIZE_QUERY,
          variables: {
            productwtpsizeId: weightSizeObject.productwtpsizeId,
          },
        })
        .then((result) => {
          this.setState(
            {
              weightSizeArr: this.state.weightSizeArr.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setWeightSizeFieldValue(this.state.weightSizeArr);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.state.weightSizeArr.splice(index, 1);
      this.setState({ impactSizeArr: this.state.weightSizeArr }, () => {
        this.setWeightSizeFieldValue(this.state.weightSizeArr);
      });
    }
  };

  updateWeightSize = async (productId) => {
    const newWeightSizeArr = this.state.weightSizeArr.map((objItems) => {
      return {
        ...objItems,
        productId: productId,
      };
    });
    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_WEIGHT_SIZE_QUERY,
        variables: {
          weightSizeArr: newWeightSizeArr,
        },
      })
      .then((result) => {
        return true;
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    return (
      <div className="contentpart">
        <AdminSidebar />
        <div className="right_content_col">
          <div className="pagename">
            Product Registration
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/admin-dashboard">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {this.state.currentRoute === "/admin-add-product"
                  ? "Add"
                  : "Edit"}{" "}
                Product
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="content_wraper dashboardPage">
            <div className="quotewraper">
              <Form
                onFinish={this.onFinish}
                ref={this.productFormRef}
                initialValues={{
                  productName: this.state.productName,
                  productType: this.state.productType,
                  shortDescription: this.state.shortDescription,
                  detailDescription: this.state.detailDescription,
                  yearlyAmountOfItemsProcured:
                    this.state.yearlyAmountOfItemsProcured,
                  averageLifetimeOfYourProduct:
                    this.state.averageLifetimeOfYourProduct,
                  productAccessories: this.state.productAccessories,
                  colors: this.state.colors,
                  endOfLifeOthers: this.state.endOfLifeOthers,
                  gender: this.state.gender,
                  price: this.state.price,
                  productLink: this.state.productLink,
                }}
              >
                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Select Brand *</label>
                    <Form.Item name="brandSelect">
                      <AutoComplete
                        onSelect={this.onBrandSelect}
                        onSearch={this.onBrandSearch}
                        options={this.state.brandSearchOptions}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <Input
                          size="large"
                          placeholder="Search & Select Brand"
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} lg={12}>
                    <label>Select Supply Chain *</label>
                    <Form.Item name="supplyChainSelect">
                      <AutoComplete
                        onSelect={this.onSupplychainSelect}
                        onSearch={this.onSupplychainSearch}
                        options={this.state.supplychainSearchOptions}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <Input
                          size="large"
                          placeholder="Search & Select Supplychain"
                        />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Product Name * </label>
                    <Form.Item
                      name="productName"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input product name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Product name"
                        onChange={this.handleInputChange("productName")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} lg={12}>
                    <label>Type of Fashion Product</label>
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Please select type of product"
                      onChange={this.handleMultiSelectChange("productType")}
                      value={this.state.productType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderProductTypeOptions()}
                    </Select>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Product Accessories</label>
                    <Form.Item name="productAccessories">
                      <Input
                        placeholder="Example: 2 aluminium zippers (10 grams each), 1 cotton cord (5 grams)"
                        onChange={this.handleInputChange("productAccessories")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} lg={12}>
                    <label>Available colours/variations</label>
                    <Form.Item name="colors">
                      <Input
                        placeholder="red/yellow/blue/stripes"
                        onChange={this.handleInputChange("colors")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <AdminAddWeightSize
                      weightSizeArr={this.state.weightSizeArr}
                      handleCloneSelectChange={this.handleCloneSelectChange}
                      renderSizeOptions={this.renderSizeOptions}
                      handleWeightSize={this.handleWeightSize}
                      removeWeightSize={this.removeWeightSize}
                      addWeightSize={this.addWeightSize}
                    />
                  </Col>

                  <Col xs={24} sm={6} lg={6}>
                    <label>Product Packaging</label>
                    <Form.Item name="averageLifetimeOfYourProduct">
                      <Input
                        placeholder="Cardboard box 200 grams"
                        onChange={this.handleInputChange(
                          "averageLifetimeOfYourProduct"
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <label>Yearly amount of items produced *</label>
                    <Form.Item
                      name="yearlyAmountOfItemsProcured"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input yearly amount of items produced!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="1000 items"
                        onChange={this.handleInputChange(
                          "yearlyAmountOfItemsProcured"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}></Col>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Brand name *</label>
                    <Form.Item
                      name="shortDescription"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input brand name!",
                        },
                      ]}
                    >
                      <TextArea
                        onChange={this.handleInputChange("shortDescription")}
                        placeholder="Write brand name here"
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Material composition of your product *</label>
                    <Form.Item>
                      {this.state.compositions.map((x, index) => {
                        return (
                          <Row gutter={[10]} className="addrow" key={index}>
                            <Col xs={12} sm={12} lg={12}>
                              <Form.Item
                                name={`materialName` + index}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input material!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Material"
                                  name="materialName"
                                  onChange={(e) =>
                                    this.handleComposition(e, index)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={6} sm={6} lg={6}>
                              <Form.Item
                                name={`percentage` + index}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input percentage!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="%"
                                  name="percentage"
                                  onChange={(e) =>
                                    this.handleComposition(e, index)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={6} sm={6} lg={6}>
                              {index !== 0 ? (
                                <Popconfirm
                                  className="action"
                                  title="Are you sure delete ?"
                                  onConfirm={(id) =>
                                    this.removeComposition(index, x)
                                  }
                                  okText="Yes"
                                  placement="left"
                                >
                                  <Button className="close" size="small">
                                    <CloseCircleOutlined />
                                  </Button>
                                </Popconfirm>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        );
                      })}

                      <Button
                        size="large"
                        onClick={(e) => this.addComposition(e)}
                      >
                        <PlusCircleOutlined /> Add Fields
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Detail description of your product *</label>
                    <Form.Item
                      name="detailDescription"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input product detail description!",
                        },
                      ]}
                    >
                      <TextArea
                        onChange={this.handleInputChange("detailDescription")}
                        placeholder="Write description here"
                        autoSize={{ minRows: 4 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={6}>
                    <label>Gender *</label>
                    <Form.Item
                      name="gender"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please select gender!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => this.handleSelectChange("gender", e)}
                        style={{ width: "98%" }}
                        size="medium"
                      >
                        <Option value="Male">Men</Option>
                        <Option value="Female">Women</Option>
                        <Option value="Unisex">Unisex</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <label>Price *</label>
                    <Form.Item
                      name="price"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[0-9]?((,[0-9]+)|([0-9]+(,[0-9]+)?))$"
                          ),
                          message: "Please input valid price!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Price"
                        onChange={this.handleInputChange("price")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <label>Product Link</label>
                    <Form.Item
                      name="productLink"
                      rules={[
                        {
                          required: true,
                          type: "url",
                          message: "Please input valid product link!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Product Link"
                        onChange={this.handleInputChange("productLink")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={24} lg={24}>
                    <label className="tick">
                      Please tick which of the following boxes apply for the end
                      of life of your product
                    </label>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange(
                        "endOfLifeRecyclable"
                      )}
                      checked={this.state.endOfLifeRecyclable}
                    >
                      Recyclable
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange(
                        "endOfLifeBiodegradable"
                      )}
                      checked={this.state.endOfLifeBiodegradable}
                    >
                      Biodegradable
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange(
                        "endOfLifeProductAsAService"
                      )}
                      checked={this.state.endOfLifeProductAsAService}
                    >
                      Product as a service
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange(
                        "endOfLifeRepairServiceBrand"
                      )}
                      checked={this.state.endOfLifeRepairServiceBrand}
                    >
                      Repair service brand
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("biobasedMaterial")}
                      checked={this.state.biobasedMaterial}
                    >
                      Bio based material
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange(
                        "refurbishedMaterial"
                      )}
                      checked={this.state.refurbishedMaterial}
                    >
                      Long Lifetime material
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("reusedMaterial")}
                      checked={this.state.reusedMaterial}
                    >
                      Refurbished material
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("recycledMaterial")}
                      checked={this.state.recycledMaterial}
                    >
                      Recycled material
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("vegan")}
                      checked={this.state.vegan}
                    >
                      Vegan
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("secondLifePossible")}
                      checked={this.state.secondLifePossible}
                    >
                      Second life possible
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Checkbox
                      onChange={this.handleCheckboxChange("organicFiber")}
                      checked={this.state.organicFiber}
                    >
                      Organic fibre use
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={12} lg={10}>
                    <Form.Item name="endOfLifeOthers" className="other">
                      <Input
                        placeholder="other...type answer"
                        onChange={this.handleInputChange("endOfLifeOthers")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <div className="truheading">
                      <h3>TRUE COSTS </h3>
                      This section zooms in on the True Costs of your product
                      after the LCA has been completed. Please indicate the
                      social impact of your supply chain. Environmental impact
                      will be filled by True Cost Label after the LCA report has
                      been delivered.
                    </div>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={24} lg={24}>
                        <Row gutter={[15]}>
                          <Col xs={24} sm={24} lg={24}>
                            <label>Social Impact</label>
                            <Form.Item name="socialImpact">
                              <Input
                                placeholder="Social Impact"
                                onChange={this.handleInputChange(
                                  "socialImpact"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} lg={24}>
                            <Form.Item>
                              <AdminAddImpactSize
                                impactSizeArr={this.state.impactSizeArr}
                                handleImpactSize={this.handleImpactSize}
                                addImpactSize={this.addImpactSize}
                                removeImpactSize={this.removeImpactSize}
                                renderSizeOptions={this.renderSizeOptions}
                                handleImpactSizeSelectChange={
                                  this.handleImpactSizeSelectChange
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <div className="truheading">
                      <h3>BENCHMARK </h3>
                      Please Add the benchmark from the LCA report
                    </div>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={24} lg={24}>
                        <Row gutter={[15]}>
                          <Col xs={24} sm={24} lg={24}>
                            <label>Benchmark Climate Change (%)</label>
                            <Form.Item name="co2">
                              <Input
                                placeholder="%"
                                onChange={this.handleInputChange("co2")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} lg={24}>
                            <label>Benchmark Water Use (%)</label>
                            <Form.Item name="water">
                              <Input
                                placeholder="%"
                                onChange={this.handleInputChange("water")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} lg={24}>
                            <label>Benchmark Distance (%)</label>
                            <Form.Item name="distance">
                              <Input
                                placeholder="%"
                                onChange={this.handleInputChange("distance")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider />

                <Row
                  gutter={[15]}
                  justify="space-between"
                  className="bottomButtons"
                >
                  <Col></Col>
                  <Col>
                    <Space>
                      <Button
                        type="secondary"
                        htmlType="button"
                        onClick={this.props.history.goBack}
                      >
                        BACK
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.btnLoading}
                      >
                        SUBMIT
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(AdminAddProduct);

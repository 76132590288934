import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Menu, Dropdown, BackTop } from "antd";
import {
  InstagramOutlined,
  MenuOutlined,
  FacebookFilled,
  LinkedinFilled,
  HeartFilled,
  DoubleRightOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import HeaderTab from "../web/Home/HeaderTab";
import Subscribe from "../web/Home/Subscribe";
import FooterBlogPost from "../web/FooterBlogPost";

import { validateToken, logout, authUserData } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import "antd/dist/antd.css";
import "./Common.scss";
import LogoSmall from "../../styles/LogoSmall";
import Profilepic from "../../images/profile-noimg.png";

const { Header, Footer, Content } = Layout;
const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/seller-dashboard">Dashboard</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/seller-myaccount">My Account</Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        rel="preconnect"
        to={{ pathname: "https://blog.truecostlabel.com/blog" }}
        target="_blank"
      >
        Blog
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="#" onClick={() => logout()}>
        Logout
      </Link>
    </Menu.Item>
  </Menu>
);

const menu1 = (
  <Menu>
    <Menu.Item>
      <Link to="/login">Brand Login</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/how-we-do-it">How we do it</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/brands-partners">Brands</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/about-us">About</Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        rel="preconnect"
        to={{ pathname: "https://blog.truecostlabel.com/" }}
        target="_blank"
      >
        Blog
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/contact-us">Contact</Link>
    </Menu.Item>
  </Menu>
);
const menu2 = (
  <Menu>
    <Menu.Item key="men">
      <a href="/products?gender=Male">Men</a>
    </Menu.Item>
    <Menu.Item key="women">
      <a href="/products?gender=Female">Women</a>
    </Menu.Item>
    <Menu.Item key="brands">
      <Link to="/brands-partners">Brands</Link>
    </Menu.Item>
    <Menu.Item key="howWeDoIt">
      <Link to="/how-we-do-it">How we do it</Link>
    </Menu.Item>
    <Menu.Item key="about">
      <Link to="/about-us">About</Link>
    </Menu.Item>
    <Menu.Item key="contact">
      <button className="contact-btn">Contact</button>
    </Menu.Item>
  </Menu>
);
function SocialIcons() {
  return (
    <React.Fragment>
      <Link
        rel="preconnect"
        to={{
          pathname:
            "https://www.facebook.com/True-Cost-Label-105602684234884/?view_public_for=105602684234884",
        }}
        target="_blank"
      >
        <FacebookFilled />
      </Link>
      <Link
        rel="preconnect"
        to={{
          pathname: "https://www.linkedin.com/company/true-cost-label/about/",
        }}
        target="_blank"
      >
        <LinkedinFilled />
      </Link>
      <Link
        rel="preconnect"
        to={{
          pathname: "https://www.instagram.com/truecostlabel/",
        }}
        target="_blank"
      >
        <InstagramOutlined />
      </Link>
    </React.Fragment>
  );
}
export class FrontLayout extends Component {
  state = {
    loginStatus: false,
    visible: false,
    isLogoClick: false,
    current: "",
    profilepic:
      authUserData() &&
        authUserData().profilePic !== "" &&
        authUserData().profilePic !== undefined
        ? authUserData().profilePic
        : Profilepic,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onLogoChange = () => {
    this.setState({
      isLogoClick: true,
      current: "",
    });
  };

  resetIsLogoClick = () => {
    this.setState({
      isLogoClick: false,
    });
  };

  componentDidMount() {
    let current = this.state.current;
    let searchString = this.props.children.props
      ? this.props.children.props.location.search
      : "";

    if (searchString === "?gender=Male") {
      current = "men";
    } else if (searchString === "?gender=Female") {
      current = "women";
    }

    const loginStatus = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY)
    );

    const profilepic =
      authUserData() && authUserData().profilePic !== undefined
        ? authUserData().profilePic
        : Profilepic;

    this.setState({ loginStatus, profilepic, current });
  }

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  scrollBottom = () => {
    window.scrollTo({ behavior: "smooth", top: 55000 });
  };

  render() {
    const { current } = this.state;
    return (
      <Layout className="front">
        <Header>
          <div className="container container_small">
            <Row>
              <Col className="logowrp">
                <Link to="/" className="logo" onClick={this.onLogoChange}>
                  <LogoSmall />
                </Link>
                <div className="desktopmenu">
                  <Menu
                    onClick={this.handleClick}
                    selectedKeys={[current]}
                    mode="inline"
                  >
                    <Menu.Item key="men">
                      <a href="/products?gender=Male">Men</a>
                    </Menu.Item>
                    <Menu.Item key="women">
                      <a href="/products?gender=Female">Women</a>
                    </Menu.Item>
                    <Menu.Item key="brands">
                      <Link to="/brands-partners">Brands</Link>
                    </Menu.Item>
                    <Menu.Item key="howWeDoIt">
                      <Link to="/how-we-do-it">How we do it</Link>
                    </Menu.Item>
                    <Menu.Item key="about">
                      <Link to="/about-us">About</Link>
                    </Menu.Item>
                    <Menu.Item key="contact">
                      <button
                        className="contact-btn"
                        onClick={this.scrollBottom}
                      >
                        Contact
                      </button>
                    </Menu.Item>
                  </Menu>
                </div>
                <div className="mobmenu">
                  <Dropdown
                    overlay={menu2}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <EllipsisOutlined />
                  </Dropdown>
                </div>
              </Col>
              <Col
                align="end"
                className="rightside"
                xs={{ order: 2 }}
                lg={{ order: 3 }}
              >
                {!this.state.loginStatus ? (
                  <React.Fragment>
                    <div className="socialicon">
                      <SocialIcons />
                      <Link to="/favourite-products" className="wishlisticon">
                        <HeartFilled />
                      </Link>
                    </div>

                    <Dropdown
                      overlay={menu1}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <MenuOutlined />
                    </Dropdown>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="socialicon">
                      <SocialIcons />
                      <Link to="/favourite-products" className="wishlisticon">
                        <HeartFilled />
                      </Link>
                    </div>
                    <div className="profilebtn">
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <div className="imgd profile-placeholder">
                          <img
                            className="cardicon"
                            alt="icon"
                            src={this.state.profilepic}
                          ></img>
                        </div>
                      </Dropdown>
                    </div>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </div>
        </Header>
        <HeaderTab
          isLogoClick={this.state.isLogoClick}
          resetIsLogoClick={this.resetIsLogoClick}
        />
        <Content>{this.props.children}</Content>
        <Footer id="footer-section">
          <div className="footer_sec_1">
            <div className="container">
              <Row className="newsletter">
                <Col xs={24} sm={16} md={8} lg={8} className="lcol">
                  <h3>NEWSLETTER</h3>
                  <p>Sign up to receive environmental news and updates!</p>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} className="rcol">
                  <Subscribe />
                </Col>
                <Col xs={24} sm={0} md={8} lg={8}></Col>
              </Row>
            </div>
          </div>
          <div className="footer_sec_2">
            <div className="container">
              <Row gutter={[15]}>
                <Col xs={24} sm={24} md={8} lg={6}>
                  <h3>About</h3>
                  <p>
                    We are True Cost Label, a digital platform that makes it
                    simple to buy and sell sustainable and ethical fashion.
                    Piece by piece, we break down how each garment affects our
                    planet and its people
                    <br />
                    <br />
                    Finding a new favorite is already a challenge. Let
                    sustainability be the easy part. We bring together conscious
                    fashion brands with like-minded consumers. All in one spot.
                    United as one force. Love the planet. Love your fashion.
                  </p>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <h3>Menu</h3>
                  <ul className="footerlinks">
                    <li>
                      <Link to="/about-us">ABOUT US</Link>
                    </li>
                    <li>
                      <Link to="/team">THE TEAM</Link>
                    </li>
                    <li>
                      <Link to="/brands-partners">BRANDS & PARTNERS</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">PRIVACY POLICY</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                  <h3>Contact Info</h3>
                  <div className="cnctrow firstd">
                    <div className="cntdtl">
                      <strong>True Cost Label</strong>
                      B. amsterdam B.2, John M. <br />
                      Keynesplein 12-46, 1066 EP Amsterdam
                    </div>
                  </div>
                  <div className="cnctrow">
                    <div className="cntdtl">info@truecostlabel.com</div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                  <FooterBlogPost />
                </Col>
              </Row>
            </div>
          </div>
        </Footer>
        <BackTop>
          <DoubleRightOutlined />
        </BackTop>
      </Layout>
    );
  }
}

export default FrontLayout;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Button,
  Breadcrumb,
  Upload,
  Popconfirm,
  Spin,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import AdminSidebar from "../common/AdminSidebar";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import {
  UPLOAD_INDICATOR_FILES,
  GET_INDICATOR_FILE_QUERY,
  DELETE_INDICATOR_FILE_QUERY,
  DOWNLOAD_INDICATOR_FILE_QUERY,
} from "./ImpactIndicatorQuery";

import "../dashboard/Dashboard.scss";
const { Content } = Layout;

class ImpactIndicatorDocument extends Component {
  state = {
    uploadedFiles: [],
    apiLoading: false,
    rotate: true,
  };

  componentDidMount() {
    let impactIndicatorRecordId = this.props.match.params.Id
      ? atob(this.props.match.params.Id)
      : 0;

    if (impactIndicatorRecordId > 0) {
      this.getIndicatorFiles(impactIndicatorRecordId);
      this.setState({ impactIndicatorRecordId });
    }
  }

  uploadIndicatorFile = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    client
      .mutate({
        mutation: UPLOAD_INDICATOR_FILES,
        variables: {
          impactIndicatorId: this.state.impactIndicatorRecordId,
          impactindicatorFile: file,
        },
      })
      .then((response) => {
        let uploadedDocumentStat = this.state.uploadedFiles;
        uploadedDocumentStat.push({
          id:
            response.data.impactindicatorfile.impactindicatorFile
              .impactindicatorfilesId,
          name:
            response.data.impactindicatorfile.impactindicatorFile
              .impactindicatorfileName,
          url: response.data.impactindicatorfile.presignedUrl,
          status: "done",
        });

        this.setState({ uploadedFiles: uploadedDocumentStat });
        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
      });
  };

  getIndicatorFiles = (impactIndicatorRecordId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_INDICATOR_FILE_QUERY,
        variables: {
          deleted: 0,
          impactIndicator: impactIndicatorRecordId,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allImpactindicatorfiles.edges.length > 0) {
          response.data.allImpactindicatorfiles.edges.map((objectValue) => {
            let mcFieldObject = {};
            mcFieldObject["id"] = objectValue.node.impactindicatorfilesId;
            mcFieldObject["name"] = objectValue.node.impactindicatorfileName;
            mcFieldObject["url"] = objectValue.node.awsFileUrl;
            mcFieldObject["status"] = "done";
            mcStatArr.push(mcFieldObject);
            return null;
          });
          this.setState({ apiLoading: false, uploadedFiles: mcStatArr });
        } else {
          this.setState({ apiLoading: false, uploadedFiles: [] });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  confirmDelete = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DELETE_INDICATOR_FILE_QUERY,
        variables: {
          impactindicatorfilesId: documentObj.id,
          impactindicatorFile: documentObj.url,
        },
      })
      .then(() => {
        this.getIndicatorFiles(this.state.impactIndicatorRecordId);
        SuccessNotificationMsg("Success", "File deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  downloadFile = (documentObj) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: DOWNLOAD_INDICATOR_FILE_QUERY,
        variables: {
          impactindicatorFile: documentObj.url,
        },
      })
      .then((response) => {
        if (response.data.downloadImpactindicatorFile.presignedUrl !== "") {
          window.open(response.data.downloadImpactindicatorFile.presignedUrl);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "picture-card",
      showUploadList: false,
    };

    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Impact Indicator Files
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/admin-manage-impact-indicator">
                      Manage Impact Indicator
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    Manage Impact Indicator Files
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="documents supplychaindoc">
                  <Spin spinning={this.state.apiLoading}>
                    <Row gutter={[16]}>
                      {this.state.uploadedFiles.map((document, index) => {
                        return (
                          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                            <div className="imgdiv files">
                              <div className="img_wrap">
                                <img
                                  src={document.url}
                                  alt={document.productfilesId}
                                ></img>
                              </div>
                              <h5>{document.name}</h5>

                              <div className="btnwrp">
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  size="small"
                                  icon={<DownloadOutlined />}
                                  onClick={() => this.downloadFile(document)}
                                ></Button>

                                <Popconfirm
                                  className="action"
                                  title="Are you sure delete this file ?"
                                  onConfirm={() => this.confirmDelete(document)}
                                  okText="Yes"
                                  placement="left"
                                >
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Popconfirm>
                              </div>
                            </div>
                          </Col>
                        );
                      })}

                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <ImgCrop rotate={this.state.rotate} aspect={1 / 1}>
                          <Upload
                            {...uploadProps}
                            customRequest={this.uploadIndicatorFile}
                            accept=".jpg,.png,.jpeg"
                          >
                            <Button icon={<PlusCircleOutlined />}></Button>
                          </Upload>
                        </ImgCrop>
                      </Col>
                    </Row>
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ImpactIndicatorDocument);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Col, Row, Button, Input, Form } from "antd";
import { MailOutlined, PhoneOutlined, StarOutlined } from "@ant-design/icons";
import Config from "../../../Config";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import "../../../components/common/about.scss";
import tclLogo512 from "../../../images/tcl-logo-512.png";

export class ContactUs extends Component {
  state = {
    btnLoading: false,
  };
  formRef = React.createRef();

  onFinish = (values) => {
    this.setState({ btnLoading: true });

    let formData = new FormData();
    formData.append("firstname", this.state.firstname);
    formData.append("lastname", this.state.lastname);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("company", this.state.company);

    fetch(Config.API_URL + "/community/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "subscribed") {
          SuccessNotificationMsg(
            "Thank you for getting in touch!",
            "Our team will get back in touch with you soon!"
          );
          this.formRef.current.resetFields();
        } else {
          ErrorNotificationMsg("Error", "Failed to send contact mail.");
        }
        this.setState({ btnLoading: false });
      })
      .catch((error) => {
        ErrorNotificationMsg("Error", error.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const { btnLoading } = this.state;
    return (
      <>
        <div className="contact-container">
          <Row gutter={[16]} className="mainrow">
            <Col xs={24} md={12}>
              <div className="form-wrap">
                <h2 class="text-default">
                  Contact <span class="primary-color">Us</span>
                </h2>
                <p>
                  Thank you for registering, please fill out the following
                  information.
                </p>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                  <Row gutter={[30]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="firstname"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input first name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="First name *"
                          onChange={this.handleInputChange("firstname")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="lastname"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input last name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last name *"
                          onChange={this.handleInputChange("lastname")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid e-mail address!",
                          },
                          {
                            required: true,
                            message: "Please input e-mail address!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email *"
                          onChange={this.handleInputChange("email")}
                          addonAfter={<MailOutlined />}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="phone">
                        <Input
                          placeholder="Phone"
                          addonAfter={<PhoneOutlined />}
                          onChange={this.handleInputChange("phone")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="company">
                        <Input
                          placeholder="Company"
                          addonAfter={<StarOutlined />}
                          onChange={this.handleInputChange("company")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={btnLoading}
                      >
                        SEND
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="lgwrap">
                <div class="circle-wrap">
                  <img
                    width="512"
                    height="512"
                    src={tclLogo512}
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withApollo(ContactUs);

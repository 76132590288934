import gql from "graphql-tag";

export const ADD_LOG_QUERY = gql`
  mutation createHistorylog(
    $updatedBy: Int
    $ipAddress: String
    $country: String
    $url: String
    $logType: String
  ) {
    createHistorylog(
      input: {
        updatedBy: $updatedBy
        ipAddress: $ipAddress
        country: $country
        url: $url
        logType: $logType
      }
    ) {
      historylog {
        historyLogId
        ipAddress
        country
        url
        logType
        deleted
        createdAt
      }
    }
  }
`;

export const LOG_LIST_QUERY = gql`
  query allHistorylog(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $search: String
    $orderBy: [String]
  ) {
    allHistorylog(
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      search: $search
      orderBy: $orderBy
    ) {
      totalCount
      filterCount
      edges {
        node {
          historyLogId
          ipAddress
          country
          url
          logType
          createdAt
          updatedAt
          updatedBy
          deleted
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Col, Row, Checkbox, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import qs from "query-string";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { GetClientOriginDetails } from "../../../utils/Helpers";
import ProductLike from "../ProductLike";
import ProductListSkeleton from "./ProductListSkeleton";
import ProductFilter from "./ProductFilter";
import ProductListBlog from "./ProductListBlog";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { PRODUCT_LIST_PAGE_QUERY } from "../../brand/product/ProductQuery";
import { ADD_LOG_QUERY } from "../../admin/log/LogQuery";

import productDefaultImg from "../../../images/noimg-product.webp";
import CO21 from "../../../images/CO21.png";
import water1 from "../../../images/water1.png";
import distance1 from "../../../images/distance1.png";
import infographics from "../../../images/infographics.webp";
import CO2big from "../../../images/CO2big.webp";
import waterbig from "../../../images/waterbig.webp";
import distancebig from "../../../images/distancebig.webp";

export class ProductList extends Component {
  state = {
    loading: false,
    orderBy: "-product_id",
    data: [],
    compareProductArr: [],
    perPageRecord: 8,
    currentPageNo: 1,
    defaultFilterParameter: {
      deleted: 0,
      status: true,
      orderBy: "-product_id",
      first: 8,
      after: null,
    },
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    // rest of your code
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.history.listen((location) => {
      this.setState({ data: [], filterParameter: {} });
      this._isMounted && this.prepareFilterData();
      this._isMounted && this.filterProduct();
    });
    this._isMounted && this.filterProduct();
    this.setState({
      compareProductArr:
        this.props.location.state && this.props.location.state.compareProductArr
          ? this.props.location.state.compareProductArr
          : [],
    });
    this._isMounted && this.storeLog();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  storeLog = () => {
    GetClientOriginDetails().then((result) => {
      this.callSaveLogAPI(result, "PRODUCT_LIST");
    });
  };

  callSaveLogAPI = (result, logType) => {
    this.props.client.mutate({
      mutation: ADD_LOG_QUERY,
      variables: {
        ipAddress: result.ip.ip,
        country: result.ip.country,
        url: window.location.href,
        updatedBy: 1,
        logType,
      },
    });
  };

  fetchProductList = () => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_PAGE_QUERY,
        variables: this.state.filterParameter,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allProduct.edges)
              : result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  filterProduct = () => {
    let filterParams = this._isMounted && this.prepareFilterData();
    let filterParameter = {
      ...this.state.defaultFilterParameter,
      ...filterParams,
      after: null,
    };

    this.setState({ data: [], filterParameter, currentPageNo: 1 }, () => {
      this._isMounted && this.fetchProductList();
    });
  };

  prepareFilterData = () => {
    let queryString = qs.parse(this.props.history.location.search);
    let response = {};

    response.qs = this.props.history.location.search;

    if ("gender" in queryString && queryString.gender !== "") {
      let genderArr = queryString.gender.split(",");
      if (genderArr.length > 0) {
        response["genderlist"] = genderArr;
      }
    }

    if ("price" in queryString && queryString.price !== "") {
      response["priceFilter"] = queryString.price;
    }

    if ("category" in queryString && queryString.category !== "") {
      let categoryArr = queryString.category.split(",");
      if (categoryArr.length > 0) {
        response["categorylist"] = categoryArr;
      }
    }

    if ("size" in queryString && queryString.size !== "") {
      let sizeArr = queryString.size.split(",");
      if (sizeArr.length > 0) {
        response["sizelist"] = sizeArr;
      }
    }

    if ("material" in queryString && queryString.material !== "") {
      let materialArr = queryString.material.split(",");
      if (materialArr.length > 0) {
        materialArr.map((material) => {
          response[material] = true;
          return null;
        });
      }
    }

    if ("orderBy" in queryString && queryString.orderBy !== "") {
      let orderBy = queryString.orderBy;
      response["orderBy"] = orderBy;
    }

    if ("brand" in queryString && queryString.brand !== "") {
      let brandArr = queryString.brand.split(",");
      if (brandArr.length > 0) {
        response["companylist"] = brandArr;
      }
    }
    return response;
  };

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  goToProduct(productData) {
    this.props.history.push({
      pathname: "/product-detail/" + productData.node.slug,
    });
  }

  handeCompareProductData(e, product) {
    let compareProductArr = this.state.compareProductArr;

    if (e.target.checked) {
      compareProductArr.push(product.node.productId);
      this.setState({ compareProductArr: compareProductArr });
    } else {
      this.setState({
        compareProductArr: this.state.compareProductArr.filter(
          (s) => product.node.productId !== s
        ),
      });
    }
  }

  goToProductCompare = () => {
    if (this.state.compareProductArr.length === 0) {
      ErrorNotificationMsg("Please select product for compare.");
      return false;
    }

    this.props.history.push({
      pathname: "/compare-products",
      state: { products: this.state.compareProductArr },
    });
  };

  loadMoreProducts = () => {
    if (this.state.currentPageNo !== this.state.totalPage) {
      let filterParams = { after: this.state.nextPageId };
      let filterParameter = { ...this.state.filterParameter, ...filterParams };
      this.setState(
        { currentPageNo: this.state.currentPageNo + 1, filterParameter },
        () => {
          this._isMounted && this.fetchProductList();
        }
      );
    }
  };

  prepareImpactSizeData(productData) {
    let impactData = [];

    if (
      productData.impactsizemodelSet &&
      productData.impactsizemodelSet.edges.length > 0
    ) {
      productData.impactsizemodelSet.edges.map((item) => {
        impactData.push({
          weight: item.node.weight,
          kgco2: item.node.kgco2,
          liter: item.node.liter,
          kmtravel: item.node.kmtravel,
          size: item.node.attributeValue ? item.node.attributeValue.value : "",
          impactsizeId: item.node.impactsizeId,
        });
        return null;
      });

      let defaultImpactSizeData = impactData.filter(function (e) {
        return e.size === "M";
      });

      if (!defaultImpactSizeData[0]) {
        defaultImpactSizeData[0] = impactData[0];
      }
      return defaultImpactSizeData[0] ? defaultImpactSizeData[0] : {};
    }
  }

  render() {
    const { data, compareProductArr, loading, totalRecords } = this.state;
    return (
      <div className="container">
        <Row className="productPage">
          <ProductFilter onFilter={this.filterProduct} />
          <Col className="product_content">
            <ProductListBlog
              impactId={
                this.props.location.state
                  ? this.props.location.state.impactId
                  : {}
              }
            />

            <div className="proList">
              <div className="title second">
                Product list{" "}
                <div className="mark-icon">
                  <QuestionCircleOutlined />
                  <div className="howwedo mark-description">
                    <Row gutter={16}>
                      <Col xs={24} sm={12}>
                        <h1>HOW WE CALCULATE THE TRUE COSTS OF YOUR PRODUCT</h1>
                        <div className="infographic">
                          <div className="infographicsimg">
                            <LazyLoadImage
                              src={infographics}
                              alt="infographics"
                              fetchpriority="high" 
                            />
                          </div>

                          <div className="infographics-content">
                            <p>
                              At True Cost Label, we analyse the impact of
                              products using a technique called Life Cycle
                              Assessment or LCA. We reveal the invisible costs
                              of a brand’s fashion piece by calculating CO2
                              emissions, Water use and Distance travelled along
                              the supplychain to the brand. We use the icons
                              below to communicate this environmental impact. To
                              calculate these numbers we apply the True Cost
                              Generator, an in-house developed software
                              application complying with EU recommended
                              standards for Life Cycle Analysis. A technical
                              explanation follows.
                            </p>
                            <div className="info-icons">
                              <div>
                                <div className="icons">
                                  <img src={CO2big} alt="CO2big"></img>
                                </div>
                                <h3>Kilogram CO2</h3>
                              </div>
                              <div>
                                <div className="icons">
                                  <img src={waterbig} alt="waterbig"></img>
                                </div>
                                <h3>Water use (L)</h3>
                              </div>
                              <div>
                                <div className="icons">
                                  <img
                                    src={distancebig}
                                    alt="distancebig"
                                  ></img>
                                </div>
                                <h3>Distance (km)</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <h1>Benchmarking</h1>
                        <div className="normal_div">
                          <p>
                            All products on the True Cost Label platform have
                            their impact benchmarked to a product of the same
                            make and build, but then following conventional
                            production and manufacturing standards which is most
                            common and average for the industry. Our
                            conventional t-shirt, is a t-shirt made with
                            commodity trademix cotton originating in an average
                            Asian production country (i.e. China, India,
                            Bangladesh). The t-shirt is produced according to
                            the manufacturing standards of fast fashion
                            production, using average spinning, knitting or
                            weaving, dyeing, embroidering, printing and other
                            techniques based on an average energy grid
                            representative for those countries and industries.
                          </p>
                          <p>
                            The benchmark with scores will look like this for
                            each product:{" "}
                          </p>
                          <Row
                            justify="space-between"
                            gutter={[18]}
                            align="middle"
                          >
                            <Col xs={24} md={6}>
                              <div className="img-wrapper">
                                <img
                                  src={CO2big}
                                  alt="CO2big"
                                  style={{ width: 44 }}
                                ></img>
                                <div className="img-text">
                                  <span>1,50 kg</span>
                                  <span>-60%</span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} md={18}>
                              <ul style={{ listStyle: "disc" }}>
                                <li>
                                  A product with the indicator on the left has a{" "}
                                  <strong>total</strong> climate change impact
                                  of <strong>1,50 kg CO2-equivalent</strong> per
                                  piece.
                                </li>
                                <li>
                                  Compared to a conventional item of the same
                                  make and build{" "}
                                  <strong>
                                    you will save 60% of the total impact.
                                  </strong>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <InfiniteScroll
                dataLength={data.length}
                next={this.loadMoreProducts}
                hasMore={data.length === totalRecords ? false : true}
                loader={<ProductListSkeleton />}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  {data.map((product, index) => {
                    let impactData = this.prepareImpactSizeData(product.node);
                    return (
                      <Col xs={12} sm={12} md={8} lg={6} key={index}>
                        <div className="prowrap">
                          <div
                            className="imagdiv"
                            onClick={() => this.goToProduct(product)}
                          >
                            <LazyLoadImage
                              width="100%"
                              height="auto"
                              alt=""
                              src={this.getProductCoverImage(
                                product.node.productfilesmodelSet
                              )}
                            />
                          </div>
                          <div className="Description">
                            <h3>
                              <span onClick={() => this.goToProduct(product)}>
                                {product.node.productName}
                              </span>
                              <ProductLike productId={product.node.productId} />
                            </h3>
                            <p>{product.node.shortDescription}</p>
                          </div>
                          <ul>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={CO21}></img>
                              </div>
                              <h3>
                                {impactData ? impactData.kgco2 + " kg" : 0}
                              </h3>
                              <h4>
                                {product.node.co2 !== "0"
                                  ? "-" + product.node.co2 + "%"
                                  : "0%"}
                              </h4>
                            </li>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={water1}></img>
                              </div>
                              <h3>
                                {impactData ? impactData.liter + " l" : 0}
                              </h3>
                              <h4>
                                {product.node.water !== "0"
                                  ? "-" + product.node.water + "%"
                                  : "0%"}
                              </h4>
                            </li>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={distance1}></img>
                              </div>
                              <h3>
                                {impactData ? impactData.kmtravel + " km" : 0}
                              </h3>
                              <h4>
                                {product.node.distance !== "0"
                                  ? "-" + product.node.distance + "%"
                                  : "0%"}
                              </h4>
                            </li>
                          </ul>

                          <div className="price_compare_wrap">
                            <div
                              className="price"
                              onClick={() => this.goToProduct(product)}
                            >
                              € {product.node.price}
                            </div>

                            <div className="compare">
                              <Checkbox
                                onChange={(e) =>
                                  this.handeCompareProductData(e, product)
                                }
                              >
                                Add to Compare
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </InfiniteScroll>

              {data.length === 0 && loading === false && (
                <Col xs={24} sm={24} md={24}>
                  <div className="not-found">Not found product</div>
                </Col>
              )}

              {data.length > 0 ? (
                <div className="bottomrow">
                  {compareProductArr && compareProductArr.length > 0 && (
                    <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                      className="comparebtn"
                      onClick={this.goToProductCompare}
                    >
                      COMPARE{" "}
                      <span className="compare-count">
                        {compareProductArr.length}
                      </span>
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(withApollo(ProductList));

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Col, Row, Layout, Button, Table, Space, Breadcrumb } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import config from "../../../Config";
import Sidebar from "../common/AdminSidebar";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { ShowTextByCharLength } from "../../../utils/Helpers";

const { Content } = Layout;

class ImportAPIProduct extends Component {
  state = {
    data: [],
    postData: [],
    selectedRowKeys: [],
    loading: false,
    btnLoading: false,
    totalRecords: 0,
  };

  componentDidMount() {
    this.fetchApiProducts();
  }

  fetchApiProducts = () => {
    this.setState({ loading: true });
    let apiURLId = this.props.location.state.user_id;
    let apiURL = "";

    if (this.props.location.state.platform === "wordpress") {
      apiURL = config.API_URL + "/webhook/wordpress/get/" + apiURLId;
    } else {
      apiURL = config.API_URL + "/webhook/get/" + apiURLId;
    }

    fetch(apiURL)
      .then((response) => response.json())
      .then((data) => this.setState({ data: data, loading: false }))
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message);
        this.setState({ loading: false });
      });
  };

  handleImportProduct = async () => {
    this.setState({ btnLoading: true });
    let apiPostURLId = this.props.location.state.user_id;
    let apiPostURL = config.API_URL + "/webhook/post/" + apiPostURLId;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        platform: this.props.location.state.platform,
        data: this.state.postData,
      }),
    };

    await fetch(apiPostURL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        SuccessNotificationMsg("Success", "Product Imported Successfully.");
        setTimeout(() => {
          window.location.href = "/admin-manage-product";
        }, 2000);
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message);
        this.setState({ btnLoading: false });
      });
  };

  onSelectChange = (selectedRowKeys) => {
    let postData = [];
    postData = this.state.data.filter((item) =>
      selectedRowKeys.includes(item.shopify_product_id)
    );
    this.setState({ selectedRowKeys, postData });
  };

  render() {
    const { data, loading, selectedRowKeys, btnLoading } = this.state;
    const columns = [
      {
        title: "Product ID",
        key: "shopify_product_id",
        sorter: false,
        render: (node) => `${node.shopify_product_id}`,
        width: "10%",
      },
      {
        title: "Product Image",
        sorter: false,
        render: (node) =>
          node.product_files && node.product_files.length > 0 ? (
            <img
              src={node.product_files[0]["aws_file_url"]}
              width="100"
              alt="prod-pic"
            />
          ) : (
            ""
          ),
        width: "15%",
      },
      {
        title: "Product name",
        key: "product_name",
        sorter: false,
        render: (node) => `${node.product_name}`,
        width: "18%",
      },
      {
        title: "Product type",
        key: "product_type",
        sorter: false,
        render: (node) => `${node.product_type}`,
        width: "14%",
      },
      {
        title: "Short Desc",
        key: "short_description",
        sorter: false,
        render: (node) =>
          `${ShowTextByCharLength(node.short_description, 400)}`,
        width: "33%",
      },
      {
        title: "Price",
        key: "price",
        sorter: false,
        render: (node) => `${node.price}`,
        width: "10%",
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Import API Products
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Import API Products</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>API Products</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          Total Products : {data.length}
                          {data.length > 0 && (
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              disabled={
                                selectedRowKeys.length > 0 ? false : true
                              }
                              icon={<DownloadOutlined />}
                              onClick={this.handleImportProduct}
                              loading={btnLoading}
                            >
                              Import Product
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Table
                    className="table_grid"
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={(record) => record.shopify_product_id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                  ></Table>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ImportAPIProduct);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Progress,
  Col,
  Row,
  Layout,
  Button,
  Table,
  Input,
  Space,
  Select,
  Breadcrumb,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import Sidebar from "../common/Sidebar";
import "../dashboard/Dashboard.scss";
import "../common/SellerPages.scss";

import visitorIcon from "../../../images/visitor.png";
import revenueIcon from "../../../images/revenue.png";
import tbdIcon from "../../../images/tbd.png";

const { Option } = Select;
const { Content } = Layout;
const { Search } = Input;

const columns = [
  {
    title: "s.no",
    dataIndex: "sno",
    key: "s.no",
    width: 30,
  },
  {
    title: "Product name",
    dataIndex: "Productname",
    key: "Product name",
  },
  {
    title: "Clicks (sessions)",
    dataIndex: "Clicks",
    key: "Clicks (sessions)",
  },
  {
    title: "Conversions",
    dataIndex: "Conversions",
    key: "Conversions",
  },
  {
    title: "Bounce rate",
    dataIndex: "Bouncerate",
    key: "Bounce rate",
  },
  {
    title: "Avg. Duration",
    dataIndex: "AvgDuration",
    key: "Avg. Duration",
  },
  {
    title: "kg CO2",
    dataIndex: "kgCO2",
    key: "kg CO2",
  },
  {
    title: "Liter H2O",
    dataIndex: "LiterH2O",
    key: "Liter H2O",
  },
  {
    title: "Km Distance",
    dataIndex: "KmDistance",
    key: "Km Distance",
  },
  {
    title: "Price",
    dataIndex: "Price",
    key: "Price",
  },
  {
    width: 120,
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    render: (text) => (
      <Space size="middle">
        <Link to="#" className="action">
          <EditOutlined />
        </Link>
        <Link to="#" className="action">
          <DeleteOutlined />
        </Link>
      </Space>
    ),
  },
];

const data = [
  {
    sno: "1",
    key: 1,
    Productname: "Redfeet swimming shorts",
    Clicks: "1000",
    Conversions: "60%",
    Bouncerate: "40%",
    AvgDuration: "00:01:17",
    kgCO2: "4",
    LiterH2O: "4",
    KmDistance: "50000",
    Price: "€49.99",
    Action: "32",
  },
  {
    sno: "1",
    key: 1,
    Productname: "Redfeet swimming shorts",
    Clicks: "1000",
    Conversions: "60%",
    Bouncerate: "40%",
    AvgDuration: "00:01:17",
    kgCO2: "4",
    LiterH2O: "4",
    KmDistance: "50000",
    Price: "€49.99",
    Action: "32",
  },
  {
    sno: "1",
    key: 1,
    Productname: "Redfeet swimming shorts",
    Clicks: "1000",
    Conversions: "60%",
    Bouncerate: "40%",
    AvgDuration: "00:01:17",
    kgCO2: "4",
    LiterH2O: "4",
    KmDistance: "50000",
    Price: "€49.99",
    Action: "32",
  },
  {
    sno: "1",
    key: 1,
    Productname: "Redfeet swimming shorts",
    Clicks: "1000",
    Conversions: "60%",
    Bouncerate: "40%",
    AvgDuration: "00:01:17",
    kgCO2: "4",
    LiterH2O: "4",
    KmDistance: "50000",
    Price: "€49.99",
    Action: "32",
  },
  {
    sno: "1",
    key: 1,
    Productname: "Redfeet swimming shorts",
    Clicks: "1000",
    Conversions: "60%",
    Bouncerate: "40%",
    AvgDuration: "00:01:17",
    kgCO2: "4",
    LiterH2O: "4",
    KmDistance: "50000",
    Price: "€49.99",
    Action: "32",
  },
];

function handleChange(value) {}

export default class MyStats extends Component {
  state = {};

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                My Stats
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>My Stats</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList mystatsgrid">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>My Product</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <div className="statshead">
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onSearch={(value) => console.log(value)}
                            />
                          </div>
                          <div className="monthwrap">
                            <Select
                              defaultValue="Select Year"
                              style={{ width: 170 }}
                              onChange={handleChange}
                            >
                              <Option value="January 2020">January 2020</Option>
                              <Option value="February 2020">
                                February 2020
                              </Option>
                              <Option value="March 2020">March 2020</Option>
                              <Option value="April 2020">April 2020</Option>
                              <Option value="May 2020">May 2020</Option>
                            </Select>
                          </div>
                          <Link to="/seller-add-product">
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              icon={<DownloadOutlined />}
                            >
                              Download.csv
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 970 }}
                  />
                </div>

                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={visitorIcon}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>0</h3>
                          <h5>Total Visitors (Clicks)</h5>
                        </Col>
                      </Row>
                      <Progress percent={10} />
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={revenueIcon}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>0</h3>
                          <h5>Estimated revenue per click</h5>
                        </Col>
                      </Row>
                      <Progress percent={30} />
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={tbdIcon}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>TBD</h3>
                          <h5>Environmental performance</h5>
                        </Col>
                      </Row>
                      <Progress percent={70} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

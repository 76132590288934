import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import config from "../../Config";
import OpenRoute from "./OpenRoute";
import BrandRoute from "./BrandRoute";
import AdminRoute from "./AdminRoute";

/* open Route Components */
import Home from "../web/Home/Home";
import AboutUs from "../web/Page/AboutUs";
import ContactUs from "../web/Page/ContactUs";
import Faq from "../web/Page/Faq";
import Disclaimer from "../web/Page/Disclaimer";
import Legal from "../web/Page/Legal";
import HowWeDoIt from "../web/Page/HowWeDoIt";
import PrivacyPolicy from "../web/Page/PrivacyPolicy";
import Team from "../web/Page/Team";
import BrandPartner from "../web/Page/BrandPartner";
import ImpactIndicator from "../web/Page/ImpactIndicator";
import ProductList from "../web/ProductList/ProductList";
import ProductDetail from "../web/ProductDetail";
import ProductCompare from "../web/ProductCompare";
import FavouriteProduct from "../web/FavouriteProduct";

import Login from "../authentication";
import BrandRegister from "../authentication/BrandRegister";
// import ConsumerRegister from "../authentication/ConsumerRegister";
import AdminRegister from "../authentication/AdminRegister";
import VerifyAccount from "../authentication/VerifyAccount";
import ForgotPassword from "../authentication/ForgotPassword";
import ForgotPasswordVerification from "../authentication/ForgotPasswordVerification";

/* Auth Required Brand Route Components */
import { default as brandDashboard } from "../brand/dashboard/Dashboard";
import MyStats from "../brand/myStat/MyStats";
import AddProduct from "../brand/product/AddProduct";
import ManageProduct from "../brand/product/ManageProduct";
import ProductDocument from "../brand/product/ProductDocument";
import SupplyChainDocument from "../brand/supplychain/SupplyChainDocument";
import AddSupplyChain from "../brand/supplychain/AddSupplyChain";
import ManageSupplyChain from "../brand/supplychain/ManageSupplyChain";
import ReportMedia from "../brand/reportMedia/ReportMedia";
import RequestQuote from "../brand/requestQuote/RequestQuote";
import GetSupport from "../brand/getSupport/GetSupport";
import MyAccount from "../brand/common/MyAccount";
import StoreIntegration from "../brand/store/StoreIntegration";
import EditAPI from "../brand/store/EditAPI";

/* Auth Required Admin Route Components */
import { default as adminDashboard } from "../admin/dashboard/Dashboard";
import AdminMyAccount from "../admin/common/MyAccount";
import AdminManageProduct from "../admin/product/ManageProduct";
import AdminManageSupplyChain from "../admin/supplychain/ManageSupplyChain";

import AdminManageRequestQuote from "../admin/requestQuote/ManageRequestQuote";
import AdminManageGetSupport from "../admin/getSupport/ManageGetSupport";
import AdminManageBrand from "../admin/manageBrand/ManageBrand";
import AdminAddBrand from "../admin/manageBrand/AddBrand";

import AdminManageAPI from "../admin/manageAPI/ManageAPI";
import AdminImportAPIProduct from "../admin/manageAPI/ImportAPIProduct";
import AdminEditAPI from "../admin/manageAPI/EditAPI";

import AdminAddSupplyChain from "../admin/supplychain/AddSupplyChain";
import AdminSupplyChainDocument from "../admin/supplychain/SupplyChainDocument";

// import AdminManageConsumer from "../admin/manageConsumer/ManageConsumer";
// import AdminAddConsumer from "../admin/manageConsumer/AddConsumer";

import AdminProductDocument from "../admin/product/ProductDocument";
import AdminAddProduct from "../admin/product/AddProduct";
// import AdminProductLikeList from "../admin/product/ProductLikeList";

import AdminManageImpactIndicator from "../admin/impactIndicator/ManageImpactIndicator";
import AdminAddImpactIndicator from "../admin/impactIndicator/AddImpactIndicator";
import AdminImpactIndicatorDocument from "../admin/impactIndicator/ImpactIndicatorDocument";

import AdminManageFaq from "../admin/faq/ManageFaq";
import AdminAddFaq from "../admin/faq/AddFaq";

import AdminManageLog from "../admin/log/AdminManageLog";

/* Other Common Route Components */
import Logout from "../authentication/Logout";
import Maintenance from "../common/Maintenance";
import Notfound from "../common/Notfound";

export default class Routes extends Component {
  render() {
    if (config.IS_MAINTENANCE_MODE) {
      return <Route path="*" component={Maintenance} />;
    }

    return (
      <Switch>
        <OpenRoute exact path="/" component={Home} />
        <OpenRoute exact path="/about-us" component={AboutUs} />
        <OpenRoute exact path="/contact-us" component={ContactUs} />
        <OpenRoute exact path="/faq" component={Faq} />
        <OpenRoute exact path="/disclaimer" component={Disclaimer} />
        <OpenRoute exact path="/legal" component={Legal} />
        <OpenRoute exact path="/how-we-do-it" component={HowWeDoIt} />
        <OpenRoute exact path="/privacy-policy" component={PrivacyPolicy} />
        <OpenRoute exact path="/impact-indicator" component={ImpactIndicator} />
        <OpenRoute exact path="/team" component={Team} />
        <OpenRoute exact path="/brands-partners" component={BrandPartner} />

        <OpenRoute exact path="/products" component={ProductList} />
        <OpenRoute
          exact
          path="/product-detail/:slug"
          component={ProductDetail}
        />
        <OpenRoute exact path="/compare-products" component={ProductCompare} />
        <OpenRoute
          exact
          path="/favourite-products"
          component={FavouriteProduct}
        />

        <OpenRoute exact path="/login" component={Login} />
        {/* <OpenRoute exact path="/consumer-register" component={ConsumerRegister} /> */}
        <OpenRoute exact path="/create-admin-user" component={AdminRegister} />
        <OpenRoute exact path="/brand-register" component={BrandRegister} />
        <OpenRoute exact path="/verify-account" component={VerifyAccount} />
        <OpenRoute exact path="/forgot-password" component={ForgotPassword} />
        <OpenRoute
          exact
          path="/forgot-password-verification"
          component={ForgotPasswordVerification}
        />

        <BrandRoute exact path="/seller-dashboard" component={brandDashboard} />
        <BrandRoute exact path="/seller-my-stats" component={MyStats} />
        <BrandRoute exact path="/seller-add-product" component={AddProduct} />
        <BrandRoute
          exact
          path="/seller-manage-product"
          component={ManageProduct}
        />
        <BrandRoute
          exact
          path="/seller-product-document/:productId"
          component={ProductDocument}
        />
        <BrandRoute
          exact
          path="/seller-supplychain-document/:supplychainId"
          component={SupplyChainDocument}
        />
        <BrandRoute
          exact
          path="/seller-add-supplychain"
          component={AddSupplyChain}
        />
        <BrandRoute
          exact
          path="/seller-manage-supplychain"
          component={ManageSupplyChain}
        />
        <BrandRoute exact path="/seller-report-media" component={ReportMedia} />
        <BrandRoute
          exact
          path="/seller-request-quote"
          component={RequestQuote}
        />
        <BrandRoute exact path="/seller-get-support" component={GetSupport} />
        <BrandRoute exact path="/seller-myaccount" component={MyAccount} />
        <BrandRoute
          path="/seller-edit-supplychain/:supplychainId"
          component={AddSupplyChain}
        />
        <BrandRoute
          path="/seller-edit-product/:productId"
          component={AddProduct}
        />
        <BrandRoute
          exact
          path="/seller-store-help"
          component={StoreIntegration}
        />
        <BrandRoute exact path="/seller-edit-api" component={EditAPI} />

        <AdminRoute exact path="/admin-dashboard" component={adminDashboard} />
        <AdminRoute exact path="/admin-myaccount" component={AdminMyAccount} />

        <AdminRoute
          exact
          path="/admin-manage-brand"
          component={AdminManageBrand}
        />
        <AdminRoute exact path="/admin-add-brand" component={AdminAddBrand} />
        <AdminRoute
          path="/admin-edit-brand/:brandId"
          component={AdminAddBrand}
        />

        {/* <AdminRoute exact path="/admin-manage-consumer" component={AdminManageConsumer} />
        <AdminRoute exact path="/admin-add-consumer" component={AdminAddConsumer} />     
        <AdminRoute path="/admin-edit-consumer/:consumerId" component={AdminAddConsumer} /> */}

        <AdminRoute
          exact
          path="/admin-manage-product"
          component={AdminManageProduct}
        />
        <AdminRoute
          exact
          path="/admin-add-product"
          component={AdminAddProduct}
        />
        <AdminRoute
          path="/admin-edit-product/:productId"
          component={AdminAddProduct}
        />
        <AdminRoute
          exact
          path="/admin-product-document/:productId"
          component={AdminProductDocument}
        />
        {/* <AdminRoute exact path="/admin-product-like-list/:productId" component={AdminProductLikeList} /> */}

        <AdminRoute
          exact
          path="/admin-manage-supplychain"
          component={AdminManageSupplyChain}
        />
        <AdminRoute
          path="/admin-edit-supplychain/:supplychainId?"
          component={AdminAddSupplyChain}
        />
        <AdminRoute
          exact
          path="/admin-supplychain-document/:supplychainId"
          component={AdminSupplyChainDocument}
        />

        <AdminRoute
          exact
          path="/admin-manage-request-quote"
          component={AdminManageRequestQuote}
        />
        <AdminRoute
          exact
          path="/admin-manage-get-support"
          component={AdminManageGetSupport}
        />

        <AdminRoute
          path="/admin-edit-api/:apiconfigId?"
          component={AdminEditAPI}
        />
        <AdminRoute path="/admin-manage-api" component={AdminManageAPI} />
        <AdminRoute path="/admin-import-api-products" component={AdminImportAPIProduct} />

        <AdminRoute
          exact
          path="/admin-manage-impact-indicator"
          component={AdminManageImpactIndicator}
        />
        {/* <AdminRoute exact path="/admin-add-impact-indicator" component={AdminAddImpactIndicator} />      */}
        <AdminRoute
          path="/admin-edit-impact-indicator/:Id"
          component={AdminAddImpactIndicator}
        />
        <AdminRoute
          path="/admin-impact-indicator-document/:Id"
          component={AdminImpactIndicatorDocument}
        />

        <AdminRoute exact path="/admin-manage-faq" component={AdminManageFaq} />
        <AdminRoute exact path="/admin-add-faq" component={AdminAddFaq} />
        <AdminRoute path="/admin-edit-faq/:Id" component={AdminAddFaq} />

        <AdminRoute exact path="/admin-manage-log" component={AdminManageLog} />

        <OpenRoute exact path="/logout" component={Logout} />
        <OpenRoute path="*" component={Notfound} />
      </Switch>
    );
  }
}

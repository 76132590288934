import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Spin,
  Button,
  Form,
  Divider,
  Space,
  Upload,
  Layout,
} from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import Password from "antd/lib/input/Password";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import AdminSidebar from "../common/AdminSidebar";
import profileImg from "../../../images/company-noimg.png";

import {
  CREATE_BRAND_USER_QUERY,
  CREATE_BRAND_ONBOARDING_QUERY,
  UPDATE_BRAND_USER_DETAIL_QUERY,
  GET_BRAND_USER_DETAIL_QUERY,
  GET_COMPANY_DETAIL_QUERY,
  UPDATE_COMPANY_DETAIL_QUERY,
  GET_BRAND_USER_PROFILE_PIC,
  UPLOAD_COMPANY_LOGO,
} from "../../brand/common/ProfileQuery";

const { TextArea } = Input;
const { Content } = Layout;

class AddBrand extends Component {
  state = {
    btnLoading: false,
    appuserRecordId: 0,
    apiLoading: false,
    ImageLoading: false,
    userProfileImg: profileImg,
  };

  formRef = React.createRef();

  componentDidMount() {
    let appuserRecordId = this.props.match.params.brandId
      ? atob(this.props.match.params.brandId)
      : 0;

    if (appuserRecordId > 0) {
      this.getBrandDetail(appuserRecordId);
      this.getBrandCompany(appuserRecordId);
      this.getBrandUserProfilePicture(appuserRecordId);
      this.setState({ appuserRecordId, authUserId: appuserRecordId });
    }
  }

  onFinish = () => {
    if (this.state.appuserRecordId === 0) {
      this.saveBrand();
    } else {
      this.updateBrand();
    }
  };

  saveBrand = () => {
    let { firstName, lastName, password, email } = this.state;
    let phone =
      this.state.phone.startsWith("+") === true
        ? this.state.phone
        : "+" + this.state.phone;
    this.setState({ btnLoading: true });

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${firstName} ${lastName}`,
        phone_number: phone,
      },
    })
      .then((response) => {
        this.setState({
          cognitoId: response.userSub,
          userType: "brand",
          newsletter: false,
        });
        return this.saveBrandUser();
      })
      .then((response) => {
        return this.saveBrandCompany(
          response.data.createAppUser.appuser.appusersId
        );
      })
      .then((saveonBoardResponse) => {
        SuccessNotificationMsg(
          "Brand added successfully!",
          "Please check mail for verify brand account!"
        );

        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        this.props.history.push("/admin-manage-brand");
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({
          btnLoading: false,
        });
      });
  };

  saveBrandUser = () => {
    const { client } = this.props;
    return client.mutate({
      mutation: CREATE_BRAND_USER_QUERY,
      variables: this.state,
    });
  };

  saveBrandCompany = (appusersId) => {
    const { client } = this.props;
    this.setState({ userId: appusersId });
    return client.mutate({
      mutation: CREATE_BRAND_ONBOARDING_QUERY,
      variables: this.state,
    });
  };

  getBrandDetail = (appuserRecordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .query({
        query: GET_BRAND_USER_DETAIL_QUERY,
        variables: { appuserPk: appuserRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allAppuser.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allAppuser.edges[0].node
        );
        this.setState({ apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  getBrandCompany = (userId) => {
    const { client } = this.props;
    return client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: { userId: userId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allVendors.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allVendors.edges[0].node
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  updateBrand = () => {
    const { client } = this.props;
    client
      .mutate({
        mutation: UPDATE_BRAND_USER_DETAIL_QUERY,
        variables: this.state,
      })
      .then((response) => {
        this.updateBrandCompany();
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "Brand updated successfully.");
        this.props.history.push("/admin-manage-brand");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  updateBrandCompany = () => {
    const { client } = this.props;
    return client.mutate({
      mutation: UPDATE_COMPANY_DETAIL_QUERY,
      variables: {
        authonboardingId: this.state.onboardingId,
        authUserId: this.state.appusersId,
        company: this.state.company,
        website: this.state.website,
        aboutBrand: this.state.aboutBrand,
        remarks: this.state.remarks,
      },
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  uploadProfilePic = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }

    const { client } = this.props;
    this.setState({ ImageLoading: true });
    client
      .mutate({
        mutation: UPLOAD_COMPANY_LOGO,
        variables: { userId: this.state.authUserId, brandLogoFile: file },
      })
      .then((response) => {
        this.setState({
          ImageLoading: false,
          userProfileImg: response.data.uploadBrandLogo.presignedUrl,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message.replace("GraphQL error:", ""));
        this.setState({ ImageLoading: false });
      });
  };

  getBrandUserProfilePicture = (appusersId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_BRAND_USER_PROFILE_PIC,
        variables: { deleted: 0, userId: appusersId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data.allProfilepictures.edges.length > 0) {
          this.setState({
            userProfileImg:
              response.data.allProfilepictures.edges[0].node.thumbAwsurl,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    const { userProfileImg, ImageLoading } = this.state;
    const showPasswordFields = this.state.appuserRecordId > 0 ? false : true;
    const uploadProps = {
      accept: "images/*",
      showUploadList: false,
    };
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {this.state.appuserRecordId > 0 ? "Edit Brand" : "Add Brand"}
              </div>
              <div className="content_wraper">
                <div className="stepWraper">
                  <div className="onboarding-form-body">
                    <Spin spinning={this.state.apiLoading}>
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        autoComplete="off"
                      >
                        <Row gutter={0} className="brandwrprow">
                          <Col flex="auto" className="brandimgwrpimg">
                            <Form.Item>
                              <Spin
                                tip="Uploading.."
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 24 }}
                                    spin
                                  />
                                }
                                spinning={ImageLoading}
                              >
                                <div className="brandimgdiv">
                                  <img src={userProfileImg} alt="brand-pic" />
                                  <Upload
                                    {...uploadProps}
                                    customRequest={this.uploadProfilePic}
                                  >
                                    <Button icon={<EditOutlined />}></Button>
                                  </Upload>
                                </div>
                              </Spin>
                            </Form.Item>
                          </Col>
                          <Col flex="auto" className="brandform">
                            <Row gutter={[15]}>
                              <Col xs={24} sm={24} lg={24}></Col>

                              <Col xs={24} sm={12} lg={8}>
                                <label>First Name*</label>
                                <Form.Item
                                  name="firstName"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input first name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter first name"
                                    onChange={this.handleInputChange(
                                      "firstName"
                                    )}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12} lg={8}>
                                <label>Last Name*</label>
                                <Form.Item
                                  name="lastName"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input last name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter last name"
                                    onChange={this.handleInputChange(
                                      "lastName"
                                    )}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12} lg={8}>
                                <label>Email*</label>
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      type: "email",
                                      whitespace: true,
                                      message:
                                        "The input is not valid e-mail address!",
                                    },
                                    {
                                      required: true,
                                      message: "Please input e-mail address!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter e-mail"
                                    onChange={this.handleInputChange("email")}
                                    disabled={showPasswordFields ? false : true}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12} lg={8}>
                                <label>Company Name*</label>
                                <Form.Item
                                  name="company"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input company name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter company name"
                                    onChange={this.handleInputChange("company")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12} lg={8}>
                                <label>Phone No*</label>
                                <Form.Item
                                  name="phone"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input phone number!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter phone no"
                                    onChange={this.handleInputChange("phone")}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12} lg={8}>
                                <label>Website*</label>
                                <Form.Item
                                  name="website"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input website!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter website"
                                    onChange={this.handleInputChange("website")}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12} lg={8}>
                                <label>Country*</label>
                                <Form.Item
                                  name="country"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input country!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter country"
                                    onChange={this.handleInputChange("country")}
                                  />
                                </Form.Item>
                              </Col>

                              {showPasswordFields ? (
                                <React.Fragment>
                                  <Col xs={24} sm={12} lg={8}>
                                    <label>Password*</label>
                                    <Form.Item
                                      name="password"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input password!",
                                        },
                                        () => ({
                                          validator(rule, value) {
                                            if (
                                              !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!_%*#?&])[A-Za-z\d@$!_%*#?&]{6,}$/.test(
                                                value
                                              )
                                            ) {
                                              return Promise.reject(
                                                "Password should be minimum six characters, at least one letter and one number and one special character."
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ]}
                                    >
                                      <Password
                                        placeholder="Enter password"
                                        onChange={this.handleInputChange(
                                          "password"
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={12} lg={8}>
                                    <label>Confirm Password*</label>
                                    <Form.Item
                                      name="confirm_password"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input confirm password!",
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(rule, value) {
                                            if (
                                              !value ||
                                              getFieldValue("password") ===
                                                value
                                            ) {
                                              return Promise.resolve();
                                            }

                                            return Promise.reject(
                                              "The two passwords that you entered do not match!"
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Password
                                        placeholder="Enter confirm password"
                                        onChange={this.handleInputChange(
                                          "confirm_password"
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                              <Col xs={24} sm={12} lg={8}>
                                <label>City*</label>
                                <Form.Item
                                  name="city"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input city!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter city"
                                    onChange={this.handleInputChange("city")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={0} lg={16}></Col>

                              <Col xs={24} sm={12} lg={12}>
                                <label>About Brand*</label>
                                <Form.Item
                                  name="aboutBrand"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input about brand!",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="Enter about brand"
                                    onChange={this.handleInputChange(
                                      "aboutBrand"
                                    )}
                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12} lg={12}>
                                <label>Remarks</label>
                                <Form.Item name="remarks">
                                  <TextArea
                                    placeholder="Enter remarks"
                                    onChange={this.handleInputChange("remarks")}
                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Divider />
                        <Row gutter={[15]} className="bottomButtons">
                          <Col xs={24} align="end">
                            <Space>
                              <Button
                                type="secondary"
                                htmlType="button"
                                onClick={this.props.history.goBack}
                              >
                                BACK
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                              >
                                SUBMIT
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddBrand);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Menu, Dropdown } from "antd";
import { logout, authUserData } from "../../utils/Helpers";

import "antd/dist/antd.css";
import "./Common.scss";

import LogoSmall from "../../styles/LogoSmall";
import Profilepic from "../../images/profile-noimg.png";

const { Header, Content } = Layout;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/admin-dashboard">Dashboard</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/admin-myaccount">My Account</Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        rel="preconnect"
        to={{ pathname: "https://blog.truecostlabel.com/" }}
        target="_blank"
      >
        Blog
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="#" onClick={() => logout()}>
        Logout
      </Link>
    </Menu.Item>
  </Menu>
);

export class AdminLayout extends Component {
  state = {
    visible: false,
    authUserId: authUserData() ? authUserData().appusersId : "",
    profilepic:
      authUserData() &&
        authUserData().profilePic !== "" &&
        authUserData().profilePic !== undefined
        ? authUserData().profilePic
        : Profilepic,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Layout>
        <Header>
          <div className="container-fluid">
            <Row align="middle">
              <Col flex="auto">
                <Link to="/" className="logo">
                  <LogoSmall />
                </Link>
              </Col>
              <Col align="end" className="rightside">
                <div className="searchhwrap"></div>
                <div className="profilebtn">
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <div className="imgd profile-placeholder">
                      <img
                        className="cardicon"
                        alt="icon"
                        src={this.state.profilepic}
                      ></img>
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
        </Header>
        <Content>{this.props.children}</Content>
      </Layout>
    );
  }
}

export default AdminLayout;

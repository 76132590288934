import gql from "graphql-tag";

export const GET_ATTRIBUTES_WITH_VALUES = gql`
  query allAttribute($deleted: Int) {
    allAttribute(deleted: $deleted) {
      edges {
        node {
          attributeId
          attributeName
          deleted
          attributevaluemodelSet(deleted: 0) {
            edges {
              node {
                attributeValueId
                value
                slug
                sortCol
              }
            }
          }
        }
      }
    }
  }
`;

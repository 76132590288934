import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Col, Row, Button, Form, Space } from "antd";
import Password from "antd/lib/input/Password";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { logout } from "../../../utils/Helpers";

import "../dashboard/Dashboard.scss";
import "./SellerPages.scss";

export default class ChangePassword extends Component {
  state = { btnLoading: false };
  formRef = React.createRef();

  onFinish = async (values) => {
    this.setState({ btnLoading: true });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.current_password,
          this.state.confirm_password
        );
      })
      .then((data) => {
        SuccessNotificationMsg(
          "Success!",
          "Password changed successfully, You will logout shortly!"
        );
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        setTimeout(() => {
          logout();
        }, 4000);
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="changepassword">
        <div className="heading">
        <h3>Change Password</h3>
        </div>
        <Form onFinish={this.onFinish} ref={this.formRef}>
          <Row gutter={[15]}>
            <Col xs={24} sm={8} lg={8}>
              <Form.Item
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: "Please input current password!",
                  },
                ]}
              >
                <Password
                  name="current_password"
                  onChange={this.handleInputChange("current_password")}
                  placeholder="Current Password"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input new password!",
                  },
                ]}
              >
                <Password
                  name="new_password"
                  onChange={this.handleInputChange("new_password")}
                  placeholder="New Password"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <Form.Item
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please input confirm password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Password
                  name="confirm_password"
                  onChange={this.handleInputChange("confirm_password")}
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[15]}
            align="end"
            justify="space-between"
            className="bottomButtons"
          >
            <Col></Col>
            <Col>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={this.state.btnLoading}
                >
                  SAVE
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

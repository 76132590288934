import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Divider,
  Popconfirm,
  Spin,
  AutoComplete,
} from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { GET_COMPANY_DETAIL_QUERY } from "./../../../brand/common/ProfileQuery";

const { TextArea } = Input;

class SupplychainForm extends Component {
  state = {
    searchOptions: [],
  };
  onFinish = async (values) => {
    this.props.onClickNext();
  };

  onSearch = (searchText) => {
    if (searchText.length < 3) {
      return true;
    }

    const { client } = this.props;
    client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allVendors.totalCount > 0) {
          response.data.allVendors.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["label"] = objectValue.node.company;
            mcFieldObject["value"] = objectValue.node.company;
            mcFieldObject["id"] = objectValue.node.userId.appusersId;
            mcStatArr.push(mcFieldObject);
            return null;
          });
        }

        this.setState({ searchOptions: mcStatArr });
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    return (
      <Spin spinning={this.props.stateValues.apiLoading}>
        <Form
          onFinish={this.onFinish}
          ref={this.props.supplychainFormRef}
          initialValues={{
            supplyChainName: this.props.stateValues.supplyChainName,
            gatheringData: this.props.stateValues.gatheringData,
            shippingTransportationRoutesInformation: this.props.stateValues
              .shippingTransportationRoutesInformation,
            finalRemarks: this.props.stateValues.finalRemarks,
          }}
        >
          <Row gutter={[15]}>
            {this.props.stateValues.supplyChainRecordId > 0 ? (
              ""
            ) : (
              <Col xs={24} sm={12} lg={12}>
                <label>Select Brand *</label>
                <Form.Item name="userId">
                  <AutoComplete
                    onSelect={this.props.handleBrandSelect}
                    onSearch={this.onSearch}
                    options={this.state.searchOptions}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Input size="large" placeholder="Search & Select Brand" />
                  </AutoComplete>
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12} lg={12}>
              <label>Please give a name for this supplychain *</label>
              <Form.Item
                name="supplyChainName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input supplychain name!",
                  },
                ]}
              >
                <Input
                  onChange={this.props.handleInputChange("supplyChainName")}
                  placeholder="e.g. supplychain blue pants"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>Supply Chain Description</label>
              <Form.Item name="gatheringData">
                <TextArea
                  onChange={this.props.handleInputChange("gatheringData")}
                  placeholder="Please describe your supply chain briefly step by step"
                  autoSize={{ minRows: 4 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <label>
                Please elaborate on the transportation routes of your product *
              </label>

              <Form.Item
                name="shippingTransportationRoutesInformation"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input transportation routes!",
                  },
                ]}
              >
                <TextArea
                  onChange={this.props.handleInputChange(
                    "shippingTransportationRoutesInformation"
                  )}
                  placeholder="Example: Cotton fibres from Cairo, Egypt to Shanghai, China Wool Fabric from Bangkok, Thailand to Lisbon, Portugal Product from San Fransico, USA to Rotterdam, Netherlands"
                  autoSize={{ minRows: 4 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>
                Please list producers and manufacturers of your product *
              </label>
              <Form.Item>
                {this.props.stateValues.manufacturers.map((x, index) => {
                  return (
                    <Row gutter={[10]} className="addrow" key={index}>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          name={`producerName` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input producer name!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Producer/manuf."
                            name="producerName"
                            onChange={(e) =>
                              this.props.handleManufacturer(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={5} lg={5}>
                        <Form.Item
                          name={`process` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input process!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="process"
                            name="process"
                            onChange={(e) =>
                              this.props.handleManufacturer(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={5} lg={5}>
                        <Form.Item
                          name={`city` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input city!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="City"
                            name="city"
                            onChange={(e) =>
                              this.props.handleManufacturer(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={5} lg={5}>
                        <Form.Item
                          name={`country` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input country!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Country"
                            name="country"
                            onChange={(e) =>
                              this.props.handleManufacturer(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={3} lg={3}>
                        {index !== 0 ? (
                          <Popconfirm
                            className="action"
                            title="Are you sure delete ?"
                            onConfirm={(id) =>
                              this.props.removeManufacturer(index, x)
                            }
                            okText="Yes"
                            placement="left"
                          >
                            <Button className="close" size="small">
                              <CloseCircleOutlined />
                            </Button>
                          </Popconfirm>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  type="primary"
                  size="small"
                  onClick={(e) => this.props.addManufacturer(e)}
                >
                  <PlusCircleOutlined /> Add Fields
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <label>Final remarks or suggestions</label>
              <Form.Item name="finalRemarks">
                <TextArea
                  onChange={this.props.handleInputChange("finalRemarks")}
                  placeholder="Please write down any final remarks or provide your feedback here"
                  autoSize={{ minRows: 4 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row
            gutter={[15]}
            align="end"
            justify="space-between"
            className="bottomButtons"
          >
            <Col></Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={this.props.stateValues.btnLoading}
              >
                NEXT
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default withApollo(SupplychainForm);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { Col, Row, Layout, Breadcrumb, Divider, Space, Button } from "antd";
import Sidebar from "../common/Sidebar";

import "../dashboard/Dashboard.scss";
import "../common/SellerPages.scss";
import pdfIcon from "../../../images/pdf-icon.png";
import { DownloadOutlined } from "@ant-design/icons";

const { Content } = Layout;

class StoreIntegration extends Component {
  render() {
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Setup API Connection
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Setup API Connection</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="contectwraper">
                  <div className="heading">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>Documents</h3>
                      </Col>
                    </Row>
                  </div>

                  <div className="store_documents">
                    <Row gutter={[16]}>
                      <Col xs={24} sm={12} md={8} lg={6} xl={4} key="1">
                        <div className="imgdiv files">
                          <div className="img_wrap">
                            <img src={pdfIcon} alt=""></img>
                          </div>

                          <a
                            className="download_link"
                            href="https://tcl-artifact.s3.eu-central-1.amazonaws.com/ecommerce/wordpress_admin.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <DownloadOutlined />
                            Wordpres Store
                          </a>
                        </div>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={4} key="1">
                        <div className="imgdiv files">
                          <div className="img_wrap">
                            <img src={pdfIcon} alt=""></img>
                          </div>

                          <a
                            className="download_link"
                            href="https://tcl-artifact.s3.eu-central-1.amazonaws.com/ecommerce/shopify_admin.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <DownloadOutlined />
                            Shopify Store
                          </a>
                        </div>
                      </Col>
                    </Row>

                    <Divider />
                    <Row gutter={[15]} className="bottomButtons">
                      <Col xs={24} align="end">
                        <Space>
                          <Link to="/seller-edit-api">
                            <Button type="primary" htmlType="button">
                              Register API Connection
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(StoreIntegration);

import React, { Component } from "react";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="container">
        <div className="faqpage privacypage">
          <h1>Privacy Policy</h1>

          <p>
            This Privacy Policy explains what information is collected by{" "}
            <b>True Cost Label</b> when you visit the{" "}
            <a href="https://truecostlabel.com/">
              <b>www.truecostlabel.com</b>
            </a>
            website (hereinafter “the website”), visit any related websites
            (hereinafter “subdomains”), use any other services provided by{" "}
            <b>True Cost Label</b>, or communicate with us by any means
            including by email, phone, messenger, or any other medium. This
            Privacy Policy also explains how you can control the information we
            collect, how this information will be used, and how to contact us.
            This document describes how we protect your personal data.
          </p>
          <ol>
            <li>
              <h5>Who collects data about you</h5>
              <p>
                Your data is collected by True Cost Label and is not used by or
                shared with anyone not mentioned in this Privacy Policy. If you
                have any questions, comments, or requests related to data
                collection or this policy, please contact us at
                info@truecostlabel.com.
              </p>
            </li>

            <li>
              <h5>What data we collect about you</h5>
              <ol>
                <li>
                  <h6>When you contact us</h6>
                  <p>
                    We collect data that you submit through the contact form on
                    our website or that you disclose to us by other means such
                    as orally (over a call) or in writing (in an email or other
                    text message). We collect your full name, email address,
                    phone number, and any additional information you provide.
                  </p>
                </li>
                <li>
                  <h6>When you subscribe to our updates</h6>
                  <p>
                    When you subscribe to our updates via the blog, we collect
                    the email address you provide and your subscription
                    preferences.
                  </p>
                </li>
                <li>
                  <h6>When you apply for a job</h6>
                  <p>
                    When you apply to join our team, we collect information from
                    your CV and any additional information you provide us by any
                    written or oral means.
                  </p>
                </li>
                <li>
                  <h6>
                    Data that is automatically collected when you visit the
                    website and subdomains
                  </h6>
                  <ol>
                    <li>
                      <h6>Third-party services</h6>
                      <p>
                        Our website and subdomains use third-party services to
                        collect standard internet log information and details
                        about visitors’ behavior patterns. To collect this
                        information, we use Monster Insights Plugin. We do this
                        to improve your experience on our website and to provide
                        you with the most relevant information. Monster Insights
                        gives us information about user behavior such as
                        platforms and browsers used to access the website and
                        subdomains, pages visited on the website and subdomains,
                        the region from which a visitor is accessing the website
                        and subdomains, how a visitor accessed our website and
                        subdomains (e.g. via search engines, referring pages,
                        direct visits), and other behavioral information.
                      </p>
                      <p>
                        This information is processed strictly anonymously. You
                        can find more detailed information about how Monster
                        Insights uses information from sites or apps that use
                        its services here:
                        https://www.monsterinsights.com/privacy-policy.
                      </p>

                      <p>
                        We carefully investigate all third-party services that
                        we use to make sure they are compliant with all current
                        regulations.
                      </p>
                    </li>
                    <li>
                      <h6>Cookies</h6>
                      <p>
                        We use cookies to personalize our service and to improve
                        the experience on our website and subdomains.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h5>How we use your personal data</h5>
              <ol>
                <li>
                  <h6>For contacting you</h6>
                  <p>
                    We will use your email address, phone number, or other
                    contact information you provide us by written or oral means
                    for contacting you and providing you with the services and
                    information that you request; it also allows us to correctly
                    respond to your comments and questions.
                  </p>
                </li>
                <li>
                  <h6>For establishing business relations</h6>
                  <p>
                    If you are an existing customer of True Cost Label or we
                    reasonably believe that you might want to be our customer,
                    we will use your email address, phone number, and other
                    contact information you provide us by written or oral means
                    for establishing a business relationship, sending you
                    information about our services, and providing you with
                    updates that may be related to your personal and
                    professional interests.
                  </p>
                </li>
                <li>
                  <h6>For providing you with information</h6>
                  <p>
                    If you have subscribed to our updates, we will use the email
                    address you provided to send information about our services,
                    recent updates, latest products, case studies, and research.
                  </p>
                </li>
                <li>
                  <h6>For automated processing</h6>
                  <p>
                    If you visit the website, data about your behavior on the
                    site will be automatically processed by cookies and
                    third-party services to help us better understand your
                    preferences and improve your experience on the website.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h5>Third parties</h5>
              <p>
                To ensure the security of your sensitive information, we
                carefully select the third-party services that we use. We use
                exceptional services that claim they are compliant with GDPR and
                other data protection regulations. You can contact us at
                info@truecostlabel.com for more information about taking
                appropriate and suitable security measures.
              </p>
              <ol>
                <li>
                  <h6>Third-party services</h6>
                  <p>
                    Your data may be transferred to third-party service
                    including Monster Insights Analytics which is compliant with
                    GDPR and other regulations.
                  </p>

                  <p>Their privacy policies can be found here:</p>

                  <p>
                    Monster Insights Analytics –
                    https://www.monsterinsights.com/privacy-policy
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h5>
                How to withdraw your consent for collecting, processing, and
                using your data
              </h5>
              <p>
                You have the right to withdraw consent for collecting,
                processing, and using your data at any time by sending an email
                to info@truecostlabel.com. If you are receiving our emails, you
                can unsubscribe by clicking the Unsubscribe button in any email
                or by just replying with a request to unsubscribe.
              </p>
            </li>

            <li>
              <h5>How long your data is stored for</h5>
              <p>
                Your data will be stored until the moment you withdraw your
                consent or our business relationship is broken off, whichever
                happens later.
              </p>
            </li>

            <li>
              <h5>Your rights as a data subject</h5>
              <ol>
                <li>
                  <h6>Right to access</h6>
                  <p>
                    You have the right to find out what data is being collected
                    and processed, how it is being used, for how long it is
                    being stored, if your data has been transferred to any third
                    parties, and how you can withdraw your consent for data
                    collection, processing, and use.
                  </p>
                </li>
                <li>
                  <h6>Right to rectification</h6>
                  <p>
                    You have the right to ask that we rectify inaccurate
                    personal data. Taking into account the purposes of data
                    processing, you have the right to have incomplete personal
                    data completed, including by means of providing a
                    supplementary statement.
                  </p>
                </li>
                <li>
                  <h6>Right to erasure</h6>
                  <p>
                    You have the right to obtain from us the erasure of your
                    personal data when this personal data is no longer necessary
                    in relation to the purposes for which it was collected or
                    otherwise processed when you withdraw your consent on which
                    the processing was based, or when your data is processed
                    illegally.
                  </p>
                </li>
                <li>
                  <h6>Right to restrict processing</h6>
                  <p>
                    You have the right to restrict us from processing your data
                    if:
                  </p>
                  <p>
                    – you contest the accuracy of your personal data; in this
                    case, we shall not process your data for a period sufficient
                    for us to verify the accuracy of the personal data;
                  </p>
                  <p>
                    – the processing is unlawful and you oppose the erasure of
                    your personal data and request the restriction of its use
                    instead;
                  </p>
                  <p>
                    – your personal data is no longer needed for the purposes of
                    the processing but is still required by you for the
                    establishment, exercise, or defense of legal claims;
                  </p>
                  <p>
                    – you object to processing your personal data on grounds
                    related to your particular situation; in this case, we only
                    have the right to process your personal data if we provide
                    legitimate grounds for processing it that are more
                    compelling than your grounds for not processing.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h5>How you can raise a complaint</h5>
              <p>
                You can raise a complaint by sending an email to
                info@truecostlabel.com.
              </p>
            </li>
            <li>
              <h5>Privacy Policy updates</h5>
              <p>
                We will update this Privacy Policy from time to time. Updates
                may be in response to changes to existing law or changes to
                security standards and best practices for third-party services
                or tools that we use. We will update this Privacy Policy as we
                consider it necessary.
              </p>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;

import gql from "graphql-tag";

export const API_CONFIG_LIST_QUERY = gql`
  query allApiConfig(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $orderBy: [String]
    $deleted: Int
  ) {
    allApiConfig(
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      orderBy: $orderBy
      deleted: $deleted
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          apiconfigId
          apiKey
          apiendPoint
          secretKey
          platform
          pwd
          userId {
            appusersId
            onboardingmodelSet {
              edges {
                node {
                  company
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const CREATE_API_CONFIG_QUERY = gql`
  mutation createApiconfig(
    $userId: Int
    $apiKey: String!
    $secretKey: String!
    $pwd: String!
    $apiendPoint: String!
    $platform: String!
  ) {
    createApiconfig(
      input: {
        userId: $userId
        apiKey: $apiKey
        secretKey: $secretKey
        pwd: $pwd
        apiendPoint: $apiendPoint
        platform: $platform
      }
    ) {
      apiconfig {
        id
        apiconfigId
        apiKey
        secretKey
        apiendPoint
        platform
        pwd
      }
    }
  }
`;

export const UPDATE_API_CONFIG_QUERY = gql`
  mutation updateApiconfig(
    $apiconfigRecordId: Int!
    $apiKey: String!
    $secretKey: String!
    $pwd: String!
    $apiendPoint: String!
    $platform: String!
    $userId: Int
  ) {
    updateApiconfig(
      input: {
        apiconfigId: $apiconfigRecordId
        apiKey: $apiKey
        secretKey: $secretKey
        pwd: $pwd
        apiendPoint: $apiendPoint
        platform: $platform
        userId: $userId
      }
    ) {
      apiconfig {
        id
        apiconfigId
        apiKey
        secretKey
        apiendPoint
        platform
        pwd
      }
    }
  }
`;

export const GET_API_CONFIG_DETAIL_QUERY = gql`
  query allApiConfig($deleted: Int, $apiconfigId: Int, $userId: Int) {
    allApiConfig(deleted: $deleted, apiconfigPk: $apiconfigId, userId : $userId) {
      totalCount
      edges {
        node {
          id
          apiconfigId
          apiKey
          secretKey
          apiendPoint
          platform
          pwd
          userId {
            appusersId
            onboardingmodelSet {
              edges {
                node {
                  company
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_API_CONFIG_QUERY = gql`
  mutation deleteApiconfig($apiconfigId: Int!) {
    deleteApiconfig(input: { apiconfigId: $apiconfigId }) {
      apiconfigId
    }
  }
`;
import gql from "graphql-tag";

export const UPLOAD_SUPPLYCHAIN_DOCUMENTS = gql`
  mutation($supplychainDocuments: Upload!, $supplyChainId: Int!) {
    uploadSupplychainDocument(
      supplychainDocument: $supplychainDocuments
      supplyChainId: $supplyChainId
    ) {
      success
      presignedUrl
      supplyChainDocument {
        supplychainfilesId
        fileType
        fileExtension
        awsFileUrl
        fileSize
        id
        supplychainFilename
      }
    }
  }
`;

export const GET_SUPPLYCHAIN_DOCUMENT_QUERY = gql`
  query allSupplychainfiles($deleted: Int, $supplyChainId: Int) {
    allSupplychainfiles(deleted: $deleted, supplyChainId: $supplyChainId) {
      totalCount
      edges {
        node {          
          id
          fileExtension
          awsFileUrl
          supplychainfilesId
          supplychainFilename
        }
      }
    }
  }
`;

export const DELETE_SUPPLYCHAIN_DOCUMENT_QUERY = gql`
  mutation deleteSupplychainDocument(
    $supplychainfilesId: Int!
    $supplychainDocument: String
  ) {
    deleteSupplychainDocument(
      data: {
        supplychainfilesId: $supplychainfilesId
        supplychainDocument: $supplychainDocument
      }
    ) {
      supplychainfilesId
    }
  }
`;

export const DOWNLOAD_SUPPLYCHAIN_DOCUMENT_QUERY = gql`
  mutation downloadSupplychainDocument($supplychainDocument: String) {
    downloadSupplychainDocument(supplychainDocument: $supplychainDocument) {
      success
      presignedUrl
    }
  }
`;

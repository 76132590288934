import gql from "graphql-tag";

export const ADD_INDICATOR_QUERY = gql`
  mutation createImpactIndicator(
    $authUserId: Int
    $title: String
    $description: String
    $shopNowUrl: String
    $url: String
    $blogTitle: String
    $blogSubject: String
    $blogDescription: String
  ) {
    createImpactIndicator(
      input: {
        userId: $authUserId
        title: $title
        description: $description
        shopNowUrl: $shopNowUrl
        url: $url
        blogTitle: $blogTitle
        blogSubject: $blogSubject
        blogDescription: $blogDescription
      }
    ) {
      impactIndicator {
        impactIndicatorId
      }
    }
  }
`;

export const UPDATE_INDICATOR_QUERY = gql`
  mutation updateImpactIndicator(
    $authUserId: Int
    $impactIndicatorId: Int
    $title: String
    $description: String
    $shopNowUrl: String
    $url: String
    $blogTitle: String
    $blogSubject: String
    $blogDescription: String
  ) {
    updateImpactIndicator(
      input: {
        userId: $authUserId
        impactIndicatorId: $impactIndicatorId
        title: $title
        description: $description
        shopNowUrl: $shopNowUrl
        url: $url
        blogTitle: $blogTitle
        blogSubject: $blogSubject
        blogDescription: $blogDescription
      }
    ) {
      impactIndicatorUpdate {
        impactIndicatorId
      }
    }
  }
`;

export const INDICATOR_LIST_QUERY = gql`
  query allImpactIndicator(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $search: String
    $orderBy: [String]
    $impactIndicatorId: Int
  ) {
    allImpactIndicator(
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      search: $search
      orderBy: $orderBy
      impactIndicatorPk: $impactIndicatorId
    ) {
      totalCount
      filterCount
      edges {
        node {
          impactIndicatorId
          title
          description
          shopNowUrl
          url
          blogTitle
          blogSubject
          blogDescription
          slug
          createdAt
          updatedAt
          deleted
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_INDICATOR_QUERY = gql`
  mutation deleteImpactIndicator($impactIndicatorId: Int!) {
    deleteImpactIndicator(input: { impactIndicatorId: $impactIndicatorId }) {
      impactIndicatorId
    }
  }
`;

export const UPLOAD_INDICATOR_FILES = gql`
  mutation ($impactindicatorFile: Upload!, $impactIndicatorId: Int!) {
    uploadImpactindicatorFile(
      impactindicatorFile: $impactindicatorFile
      impactIndicatorId: $impactIndicatorId
    ) {
      success
      presignedUrl
      impactindicatorfile {
        id
        fileType
        fileExtension
        awsFileUrl
        fileSize
        impactindicatorfilesId
        impactindicatorfileName
      }
    }
  }
`;

export const GET_INDICATOR_FILE_QUERY = gql`
  query allImpactindicatorfiles($deleted: Int, $impactIndicator: Int) {
    allImpactindicatorfiles(
      deleted: $deleted
      impactIndicator: $impactIndicator
    ) {
      totalCount
      edges {
        node {
          id
          fileExtension
          awsFileUrl
          impactindicatorfilesId
          impactindicatorfileName
        }
      }
    }
  }
`;

export const DELETE_INDICATOR_FILE_QUERY = gql`
  mutation deleteImpactindicatorFile(
    $impactindicatorfilesId: Int!
    $impactindicatorFile: String
  ) {
    deleteImpactindicatorFile(
      data: {
        impactindicatorfilesId: $impactindicatorfilesId
        impactindicatorFile: $impactindicatorFile
      }
    ) {
      impactindicatorfilesId
    }
  }
`;

export const DOWNLOAD_INDICATOR_FILE_QUERY = gql`
  mutation downloadImpactindicatorFile($impactindicatorFile: String) {
    downloadImpactindicatorFile(impactindicatorFile: $impactindicatorFile) {
      success
      presignedUrl
    }
  }
`;

export const GET_IMPACT_INDICATOR_QUERY = gql`
  query allImpactIndicator(
    $deleted: Int = 0
    $impactIndicatorId: Int
    $orderBy: [String]
  ) {
    allImpactIndicator(
      deleted: $deleted
      impactIndicatorPk: $impactIndicatorId
      orderBy: $orderBy
    ) {
      edges {
        node {
          title
          impactIndicatorId
          description
          shopNowUrl
          url
          blogTitle
          blogSubject
          slug
          blogDescription
          deleted
          impactindicatorfilesmodelSet(deleted: $deleted) {
            edges {
              node {
                impactindicatorfilesId
                awsFileUrl
                deleted
              }
            }
          }
        }
      }
    }
  }
`;

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Button,
  Form,
  Space,
  Upload,
  Input,
  Divider,
  Spin,
} from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData, ImageUploadValidation } from "../../../utils/Helpers";

import {
  GET_BRAND_USER_DETAIL_QUERY,
  UPDATE_BRAND_USER_DETAIL_QUERY,
  GET_BRAND_USER_PROFILE_PIC,
  UPLOAD_USER_PROFILE_PICTURE,
} from "./ProfileQuery";

import "../dashboard/Dashboard.scss";
import "./SellerPages.scss";
import profileImg from "../../../images/profile-noimg.png";

class EditProfile extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    ImageLoading: false,
    isEditMode: false,
    authUserId: authUserData().appusersId,
    userProfileImg: profileImg,
  };
  formRef = React.createRef();

  componentDidMount() {
    let appusersId = this.state.authUserId;
    this.getBrandUserDetail(appusersId);
    this.getBrandUserProfilePicture(appusersId);
  }

  onFinish = async (values) => {
    this.setState({ btnLoading: true });

    let phoneNumber =
      this.state.phone.startsWith("+") === true
        ? this.state.phone
        : "+" + this.state.phone;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, {
          email: this.state.email,
          name: `${this.state.firstName} ${this.state.lastName}`,
          phone_number: phoneNumber,
        });
      })
      .then((data) => {
        return this.updateAppUser();
      })
      .then((data) => {
        SuccessNotificationMsg("Success!", "Profile updated successfully.");
        this.setState({ btnLoading: false, isEditMode: false });
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  updateAppUser = () => {
    const { client } = this.props;
    return client.mutate({
      mutation: UPDATE_BRAND_USER_DETAIL_QUERY,
      variables: this.state,
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  setPageMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  getBrandUserDetail = (appusersId) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;
    client
      .query({
        query: GET_BRAND_USER_DETAIL_QUERY,
        variables: { deleted: 0, appuserPk: appusersId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allAppuser.edges[0].node);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.allAppuser.edges[0].node
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  getBrandUserProfilePicture = (appusersId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_BRAND_USER_PROFILE_PIC,
        variables: { deleted: 0, userId: appusersId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data.allProfilepictures.edges.length > 0) {
          this.setState({
            userProfileImg:
              response.data.allProfilepictures.edges[0].node.thumbAwsurl,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  beforeUpload = (file) => {
    let errorMsg = ImageUploadValidation(file);
    if (errorMsg !== "") {
      ErrorNotificationMsg(errorMsg);
      return false;
    }
  };

  uploadProfilePic = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }

    const { client } = this.props;
    this.setState({ ImageLoading: true });
    client
      .mutate({
        mutation: UPLOAD_USER_PROFILE_PICTURE,
        variables: { userId: this.state.authUserId, pictureFile: file },
      })
      .then((response) => {
        this.setState({
          ImageLoading: false,
          userProfileImg: response.data.uploadProfilePicture.presignedUrl,
        });
        let lstorage = localStorage.getItem("auth_data");
        let authjson = JSON.parse(lstorage);
        authjson.profilePic = response.data.uploadProfilePicture.presignedUrl;
        localStorage.setItem("auth_data", JSON.stringify(authjson));
        window.location.reload();

        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
        this.setState({ ImageLoading: false });
      });
  };

  render() {
    const { isEditMode, userProfileImg, ImageLoading } = this.state;
    const showViewProfileClass = isEditMode === false ? "" : "hide";
    const showEditProfileClass = isEditMode === true ? "" : "hide";
    const uploadProps = {
      accept: "images/*",
      listType: "picture-card",
      showUploadList: false,
    };

    return (
      <div className="myprofile">
        <div className="heading">
          <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
            <Col flex="auto">
              <h3>Profile</h3>
            </Col>
            <Col flex="auto" align="end">
              <Space>
                <Button
                  type="primary"
                  htmlType="button"
                  size="small"
                  icon={
                    this.state.isEditMode ? (
                      <ArrowLeftOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  onClick={this.setPageMode}
                >
                  {this.state.isEditMode ? "Back" : "Edit"}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div className={showViewProfileClass}>
          <Spin spinning={this.state.apiLoading}>
            <Row gutter={0} className="prflrow">
              <Col xs={24} flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={userProfileImg} alt="profile-pic" />
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadProfilePic}
                    >
                      <Button icon={<EditOutlined />}></Button>
                    </Upload>
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>First Name</label>
                    <Form.Item>{this.state.firstName}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Last Name</label>
                    <Form.Item>{this.state.lastName}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>E-mail</label>
                    <Form.Item>
                      {" "}
                      <a href={"mailto:" + this.state.email}>
                        {this.state.email}
                      </a>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Phone number</label>
                    <Form.Item>{this.state.phone}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>City</label>
                    <Form.Item>{this.state.city}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Country</label>
                    <Form.Item>{this.state.country}</Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>

        <div className={showEditProfileClass}>
          <Form onFinish={this.onFinish} ref={this.formRef}>
            <Row gutter={0} className="prflrow">
              <Col flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={userProfileImg} alt="edit-profile-pic" />
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadProfilePic}
                    >
                      <Button icon={<EditOutlined />}></Button>
                    </Upload>
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>First Name *</label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("firstName")}
                        placeholder="Enter first name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Last Name *</label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("lastName")}
                        placeholder="Enter last name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>E-mail *</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          whitespace: true,
                          message: "The input is not valid e-mail address!",
                        },
                        {
                          required: true,
                          message: "Please input e-mail address!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("email")}
                        placeholder="Enter email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Phone number *</label>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp("^[0-9]{9,15}$"),
                          message: "Please input valid phone!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("phone")}
                        placeholder="Enter phone number"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>City</label>
                    <Form.Item name="city">
                      <Input
                        onChange={this.handleInputChange("city")}
                        placeholder="Enter city"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Country</label>
                    <Form.Item name="country">
                      <Input
                        onChange={this.handleInputChange("country")}
                        placeholder="Enter country"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row
              gutter={[15]}
              align="end"
              justify="space-between"
              className="bottomButtons"
            >
              <Col></Col>
              <Col>
                <Space>
                  {/* <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick={this.setPageMode}
                  >
                    BACK
                  </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={this.state.btnLoading}
                  >
                    SAVE
                  </Button>
                </Space>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </div>
    );
  }
}

export default withApollo(EditProfile);

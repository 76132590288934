import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Checkbox } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { SuccessNotificationMsg } from "../../utils/NotificationHelper";

export class ProductLike extends Component {
  state = {
    visible: false,
  };

  isFavouriteProduct() {
    let productId = parseInt(this.props.productId);
    let favProductsArr =
      localStorage.getItem("favouriteProducts") !== null
        ? JSON.parse(localStorage.getItem("favouriteProducts"))
        : [];
    return favProductsArr.includes(productId) ? true : false;
  }

  handleFavouriteProduct = () => {
    let productId = parseInt(this.props.productId);
    let favProductsArr =
      localStorage.getItem("favouriteProducts") !== null
        ? JSON.parse(localStorage.getItem("favouriteProducts"))
        : [];

    let productIdIndex = favProductsArr.indexOf(productId);
    if (productIdIndex !== -1) {
      favProductsArr.splice(productIdIndex, 1);
      SuccessNotificationMsg(
        "Success",
        "Product removed from your favourite list."
      );
    } else {
      favProductsArr.push(productId);
      SuccessNotificationMsg(
        "Success",
        "Product added to your favourite list."
      );
    }

    localStorage.setItem("favouriteProducts", JSON.stringify(favProductsArr));
    this.isFavouriteProduct();
  };

  render() {
    return (
      <React.Fragment>
        <Checkbox
          className="favrout"
          onChange={() => this.handleFavouriteProduct()}
          defaultChecked={this.isFavouriteProduct()}
        >
          <HeartOutlined className="nonfill" />
          <HeartFilled className="fill" />
        </Checkbox>
      </React.Fragment>
    );
  }
}

export default withApollo(ProductLike);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Spin,
  Button,
  Form,
  Divider,
  Space,
  Layout,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";
import AdminSidebar from "../common/AdminSidebar";

import { ADD_FAQ_QUERY, UPDATE_FAQ_QUERY, FAQ_LIST_QUERY } from "./FaqQuery";

const editorConfiguration = {
  removePlugins: ["ImageUpload"],
  //toolbar: ["bold", "italic", "bulletedList", "numberedList", "blockQuote"],
};

const { Content } = Layout;

class AddFaq extends Component {
  state = {
    btnLoading: false,
    faqRecordId: 0,
    apiLoading: false,
    authUserId: authUserData().appusersId,
  };

  formRef = React.createRef();

  componentDidMount() {
    let faqRecordId = this.props.match.params.Id
      ? atob(this.props.match.params.Id)
      : 0;

    if (faqRecordId > 0) {
      this.getFAQDetail(faqRecordId);
      this.setState({ faqRecordId });
    }
  }

  onFinish = () => {
    if (this.state.faqRecordId === 0) {
      this.saveFAQ();
    } else {
      this.updateFAQ();
    }
  };

  saveFAQ = () => {
    this.setState({ btnLoading: true });
    const { client } = this.props;

    client
      .mutate({
        mutation: ADD_FAQ_QUERY,
        variables: this.state,
      })
      .then(() => {
        SuccessNotificationMsg("Success!", "FAQ added successfully.");
        this.formRef.current.resetFields();
        this.setState({ answer: null, btnLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getFAQDetail = (faqRecordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .query({
        query: FAQ_LIST_QUERY,
        variables: { faqId: faqRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allFaq.edges[0].node);
        this.formRef.current.setFieldsValue(response.data.allFaq.edges[0].node);
        this.setState({ apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  updateFAQ = () => {
    const { client } = this.props;
    client
      .mutate({
        mutation: UPDATE_FAQ_QUERY,
        variables: this.state,
      })
      .then((response) => {
        SuccessNotificationMsg("Success!", "FAQ updated successfully.");
        this.props.history.push("/admin-manage-faq");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {this.state.faqRecordId > 0 ? "Edit FAQ" : "Add FAQ"}
              </div>
              <div className="content_wraper">
                <div className="stepWraper">
                  <div className="onboarding-form-body">
                    <Spin spinning={this.state.apiLoading}>
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        autoComplete="off"
                      >
                        <Row gutter={[15]}>
                          <Col xs={24} sm={24} lg={24}>
                            <label>Question*</label>
                            <Form.Item
                              name="question"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input question!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter question"
                                onChange={this.handleInputChange("question")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} lg={24}>
                            <label>Answer</label>
                            <Form.Item name="answer">
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={this.state.answer}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({ answer: data });
                                }}
                                onReady={() => {
                                  this.setState({ answer: "" });
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider />

                        <Row gutter={[15]} className="bottomButtons">
                          <Col xs={24} align="end">
                            <Space>
                              <Button
                                type="secondary"
                                htmlType="button"
                                onClick={this.props.history.goBack}
                              >
                                BACK
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                              >
                                SUBMIT
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddFaq);

import gql from "graphql-tag";

export const REQUEST_LIST_QUERY = gql`
  query allRequestQuotes(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $search: String
    $orderBy: [String]
  ) {
    allRequestQuotes(
      deleted: $deleted
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      orderBy: $orderBy
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          requestQuoteId
          quoteContent
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_REQUEST_QUERY = gql`
  mutation deleteRequestquote($requestQuoteId: Int!) {
    deleteRequestquote(input: { requestQuoteId: $requestQuoteId }) {
      requestQuoteId
    }
  }
`;

export const CHANGE_REQUEST_STATUS_QUERY = gql`
  mutation updateRequestquote(
    $requestQuoteId: Int!
    $status: String
    $userId: Int
  ) {
    updateRequestquote(
      input: {
        requestQuoteId: $requestQuoteId
        status: $status
        userId: $userId
      }
    ) {
      requestquote {
        requestQuoteId
        quoteContent
        status
      }
    }
  }
`;

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Divider,
  Space,
  Select,
  Popconfirm,
} from "antd";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ErrorNotificationMsg } from "../../../../utils/NotificationHelper";
import { authUserData } from "../../../../utils/Helpers";

import { PRODUCT_COUNT_QUERY } from "../ProductQuery";
import {
  ADD_SUPPLYCHAIN_QUERY,
  UPDATE_SUPPLYCHAIN_QUERY,
  GET_SUPPLYCHAIN_DETAIL_QUERY,
} from "./../../supplychain/SupplychainQuery";
import {
  GET_MANUFACTURER_DETAIL_QUERY,
  UPDATE_MANUFACTURER_QUERY,
  DELETE_MANUFACTURER_QUERY,
} from "./../../supplychain/ManufacturerQuery";

const { TextArea } = Input;

class SupplyChain extends Component {
  state = {
    brandProductCountLimit: 1,
    searchOptions: [],
    selectedSupplychain: "",
    selectedSupplychainTitle: "",
    authUserId: authUserData().appusersId,

    manufacturers: [{ producerName: "", process: "", city: "", country: "" }],
    supplyChainRecordId: 0,
    btnLoading: false,
  };

  supplychainFormRef = React.createRef();
  formRef = React.createRef();

  async componentDidMount() {
    let productSupplyChainId = this.props.stateValues.supplyChainId
      ? this.props.stateValues.supplyChainId
      : 0;

    await this.getBrandProductCount(productSupplyChainId);
    this.getSuppChainList();
  }

  onFinish = (values) => {
    if (
      this.state.selectedSupplychain === "" ||
      this.state.selectedSupplychain === null ||
      this.state.selectedSupplychain === undefined
    ) {
      ErrorNotificationMsg("Error", "Please select supply chain.");
      return false;
    } else {
      this.props.onClickNext();
    }
  };

  onSelect = (value, option) => {
    this.setState({ selectedSupplychain: option.id }, () => {
      this.props.mergeStat(this.state);
    });
  };

  onSearch = (searchText) => {
    if (searchText.length < 3) {
      return true;
    }
    this.getSuppChainList(searchText);
  };

  getSuppChainList = (searchText) => {
    const { client } = this.props;
    this.setState({ btnLoading: true });
    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: {
          first: 10,
          deleted: 0,
          userId: this.props.stateValues.authUserId,
          supplyChainName_Icontains: searchText ? searchText : "",
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allSupplychain.totalCount > 0) {
          response.data.allSupplychain.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["label"] = objectValue.node.supplyChainName;
            mcFieldObject["value"] = objectValue.node.supplyChainName;
            mcFieldObject["id"] = objectValue.node.supplychainId;
            mcStatArr.push(mcFieldObject);
            return null;
          });
        }

        this.setState({ searchOptions: mcStatArr, btnLoading: false });
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
        return false;
      });
  };

  getBrandProductCount = async (productSupplyChainId) => {
    const { client } = this.props;
    await client
      .query({
        query: PRODUCT_COUNT_QUERY,
        variables: { userId: this.state.authUserId },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        let brandProductCount = result.data.allProduct.filterCount;
        this.setState({
          brandProductCount: brandProductCount,
        });

        if (productSupplyChainId > 0) {
          if (brandProductCount <= this.state.brandProductCountLimit) {
            // IF brand has one product then full supplychain page
            this.getFirstSupplychainDetail(productSupplyChainId);
            this.getManufacturer(productSupplyChainId);
            this.setState({ supplyChainRecordId: productSupplyChainId });
          } else {
            this.getSupplychainDetail(productSupplyChainId);
          }
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  getSupplychainDetail = (supplychainId) => {
    const { client } = this.props;
    let searchObj = {
      deleted: 0,
      userId: this.props.stateValues.authUserId,
      supplychainId,
    };

    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: searchObj,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({
          selectedSupplychainTitle:
            response.data.allSupplychain.edges[0].node.supplyChainName,
          selectedSupplychain:
            response.data.allSupplychain.edges[0].node.supplychainId,
        });
        this.supplychainFormRef.current.setFieldsValue({
          supplyChainSelect:
            response.data.allSupplychain.edges[0].node.supplyChainName,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  //==================================

  onSupplychainFinish = async (values) => {
    let response = "";
    this.setState({ btnLoading: true });

    if (this.state.supplyChainRecordId === 0) {
      response = await this.saveSupplychain();
      this.props.onClickNext();
    } else {
      response = await this.updateSupplychain();
      this.props.onClickNext();
    }

    if (!response) {
      return false;
    }
    this.setState({ btnLoading: false });
  };

  getFirstSupplychainDetail = (supplyChainRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_SUPPLYCHAIN_DETAIL_QUERY,
        variables: { supplychainId: supplyChainRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allSupplychain.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allSupplychain.edges[0].node
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  saveSupplychain = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: ADD_SUPPLYCHAIN_QUERY,
        variables: this.state,
      })
      .then((response) => {
        this.props.mergeStat({
          selectedSupplychain:
            response.data.createSupplychain.supplychain.supplychainId,
        });
        return this.updateManufacturer(response.data.createSupplychain);
      })
      .then((response) => {
        //SuccessNotificationMsg("Success!", "Supplychain added successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });
    return apiStatus;
  };

  updateSupplychain = async () => {
    const { client } = this.props;
    let apiStatus = false;

    await client
      .mutate({
        mutation: UPDATE_SUPPLYCHAIN_QUERY,
        variables: this.state,
      })
      .then((response) => {
        return this.updateManufacturer(response.data.updateSupplychain);
      })
      .then((response) => {
        //SuccessNotificationMsg("Success!", "Supplychain updated successfully.");
        apiStatus = true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        apiStatus = false;
      });

    return apiStatus;
  };

  getManufacturer = (supplyChainRecordId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_MANUFACTURER_DETAIL_QUERY,
        variables: { deleted: 0, supplyChainId: supplyChainRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];
        let mcFieldObject = {};

        if (response.data.allProducermanufacturer.edges.length > 0) {
          response.data.allProducermanufacturer.edges.map(
            (objectValue, objectKey) => {
              mcStatArr.push(objectValue.node);
              mcFieldObject["producerName" + objectKey] =
                objectValue.node.producerName;
              mcFieldObject["process" + objectKey] = objectValue.node.process;
              mcFieldObject["city" + objectKey] = objectValue.node.city;
              mcFieldObject["country" + objectKey] = objectValue.node.country;
              return null;
            }
          );
        }
        this.setState({ manufacturers: mcStatArr });
        this.formRef.current.setFieldsValue(mcFieldObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  updateManufacturer = async (response) => {
    const newManufacturerArr = this.state.manufacturers.map((objItems) => {
      delete objItems.__typename;
      delete objItems.id;
      return { ...objItems, supplyChainId: response.supplychain.supplychainId };
    });

    this.setState({ manufacturers: newManufacturerArr });

    const { client } = this.props;
    await client
      .mutate({
        mutation: UPDATE_MANUFACTURER_QUERY,
        variables: {
          manufacturers: this.state.manufacturers,
        },
      })
      .then((result) => {
        return true;
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  addManufacturer() {
    this.setState(
      {
        manufacturers: [
          ...this.state.manufacturers,
          { producerName: "", process: "", city: "", country: "" },
        ],
      },
      () => {
        this.setFieldValue(this.state.manufacturers);
      }
    );
  }

  handleManufacturer(e, index) {
    const newManufactureList = [...this.state.manufacturers];
    newManufactureList[index][e.target.name] = e.target.value;
    this.setState({ manufacturers: newManufactureList });
  }

  removeManufacturer = async (index, manufacturerObject) => {
    if ("producermanufacturerId" in manufacturerObject) {
      const { client } = this.props;
      await client
        .mutate({
          mutation: DELETE_MANUFACTURER_QUERY,
          variables: {
            producermanufacturerId: manufacturerObject.producermanufacturerId,
          },
        })
        .then((result) => {
          this.setState(
            {
              manufacturers: this.state.manufacturers.filter(
                (s, sidx) => index !== sidx
              ),
            },
            () => {
              this.setFieldValue(this.state.manufacturers);
            }
          );
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          this.setState({ loading: false });
        });
    } else {
      this.setState(
        {
          manufacturers: this.state.manufacturers.filter(
            (s, sidx) => index !== sidx
          ),
        },
        () => {
          this.setFieldValue(this.state.manufacturers);
        }
      );
    }
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["producerName" + objectKey] = objectValue.producerName;
      mcFieldObject["process" + objectKey] = objectValue.process;
      mcFieldObject["city" + objectKey] = objectValue.city;
      mcFieldObject["country" + objectKey] = objectValue.country;
      return null;
    });
    this.formRef.current.setFieldsValue(mcFieldObject);
  };

  handleRefresh = () => {
    this.getSuppChainList();
  };

  handleAddNewSuppChain = () => {
    this.setState({ brandProductCountLimit: 10000 });
  };

  render() {
    const { brandProductCount } = this.state;
    const showAddSupplychainClass =
      brandProductCount > this.state.brandProductCountLimit ? "hide" : "";
    const showSelectSupplychainClass =
      brandProductCount > this.state.brandProductCountLimit ? "" : "hide";

    return (
      <React.Fragment>
        <div className={showSelectSupplychainClass}>
          <p>Please select an option below for supply chain details</p>

          <Form onFinish={this.onFinish} ref={this.supplychainFormRef}>
            <Row gutter={[15]}>
              <Col xs={20} sm={20} lg={20}>
                <label>Select Which Supply Chain Details To Apply </label>
                <Form.Item name="supplyChainSelect">
                  <Select
                    showSearch
                    onSelect={this.onSelect}
                    onSearch={this.onSearch}
                    options={this.state.searchOptions}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    placeholder="on clicking opens saved supply chain detail answers from previous products"
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={4} sm={4} lg={4}>
                <Button
                  size="middle"
                  style={{ marginTop: "25px", minHeight: "46px" }}
                  onClick={this.handleRefresh}
                  loading={this.state.btnLoading}
                >
                  <SyncOutlined /> Refresh
                </Button>
              </Col>
            </Row>

            <Row gutter={[15]}>
              <Col xs={4} sm={4} lg={4}>
                <Button size="small" onClick={this.handleAddNewSuppChain}>
                  <PlusCircleOutlined /> Add New
                </Button>
              </Col>
            </Row>
            <br />
            <Divider />
            <Row
              gutter={[15]}
              align="end"
              justify="space-between"
              className="bottomButtons"
            >
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick={() =>
                      this.props.history.push("/seller-get-support")
                    }
                  >
                    ASK SUPPORT
                  </Button>
                </Space>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="submit"
                    size="large"
                    onClick={this.props.onClickBack}
                  >
                    BACK
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={this.props.onNextStepButton}
                  >
                    SAVE & NEXT
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>

        <div className={showAddSupplychainClass}>
          <p>
            Please describe details of your supply chain.Based on your
            preferences, a Non-Disclosure Agreement can be signed for the
            supplychain details, please click request NDA using the button on
            the bottom
          </p>

          <Form onFinish={this.onSupplychainFinish} ref={this.formRef}>
            <Row gutter={[15]}>
              <Col xs={24} sm={12} lg={12}>
                <label>Please give a name for this supplychain</label>
                <Form.Item name="supplyChainName">
                  <Input
                    onChange={this.handleInputChange("supplyChainName")}
                    placeholder="e.g. supplychain blue pants"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}></Col>
            </Row>
            <Row gutter={[15]}>
              <Col xs={24} sm={12} lg={12}>
                <label>Supply Chain Description</label>
                <Form.Item name="gatheringData">
                  <TextArea
                    onChange={this.handleInputChange("gatheringData")}
                    placeholder="Please describe your supply chain briefly step by step"
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <label>
                  Please elaborate on the transportation routes of your product*
                </label>
                <Form.Item
                  name="shippingTransportationRoutesInformation"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input transportation routes!",
                    },
                  ]}
                >
                  <TextArea
                    onChange={this.handleInputChange(
                      "shippingTransportationRoutesInformation"
                    )}
                    placeholder="Example: Cotton fibres from Cairo, Egypt to Shanghai, China Wool Fabric from Bangkok, Thailand to Lisbon, Portugal Product from San Fransico, USA to Rotterdam, Netherlands"
                    autoSize={{ minRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15]}>
              <Col xs={24} sm={12} lg={12}>
                <label>
                  Please list producers and manufacturers of your product *
                </label>
                <Form.Item>
                  {this.state.manufacturers.map((x, index) => {
                    return (
                      <Row gutter={[10]} className="addrow" key={index}>
                        <Col xs={24} sm={6} lg={6}>
                          <Form.Item
                            name={`producerName` + index}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input producer name!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Producer/manuf."
                              name="producerName"
                              onChange={(e) =>
                                this.handleManufacturer(e, index)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} sm={5} lg={5}>
                          <Form.Item
                            name={`process` + index}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input process!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="process"
                              name="process"
                              onChange={(e) =>
                                this.handleManufacturer(e, index)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} sm={5} lg={5}>
                          <Form.Item
                            name={`city` + index}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input city!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="City"
                              name="city"
                              onChange={(e) =>
                                this.handleManufacturer(e, index)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} sm={5} lg={5}>
                          <Form.Item
                            name={`country` + index}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input country!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Country"
                              name="country"
                              onChange={(e) =>
                                this.handleManufacturer(e, index)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} sm={3} lg={3}>
                          {index !== 0 ? (
                            <Popconfirm
                              className="action"
                              title="Are you sure delete ?"
                              onConfirm={(id) =>
                                this.removeManufacturer(index, x)
                              }
                              okText="Yes"
                              placement="left"
                            >
                              <Button className="close" size="small">
                                <CloseCircleOutlined />
                              </Button>
                            </Popconfirm>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => this.addManufacturer(e)}
                  >
                    <PlusCircleOutlined /> Add Fields
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <label>Final remarks or suggestions</label>
                <Form.Item name="finalRemarks">
                  <TextArea
                    onChange={this.handleInputChange("finalRemarks")}
                    placeholder="Please write down any final remarks or provide your feedback here"
                    autoSize={{ minRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Row
              gutter={[15]}
              align="end"
              justify="space-between"
              className="bottomButtons"
            >
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick={() =>
                      this.props.history.push("/seller-get-support")
                    }
                  >
                    ASK SUPPORT
                  </Button>
                  <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick=""
                  >
                    REQUEST NDA
                  </Button>
                </Space>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="submit"
                    size="large"
                    onClick={this.props.onClickBack}
                  >
                    BACK
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={this.state.btnLoading}
                  >
                    SAVE & NEXT
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(SupplyChain);

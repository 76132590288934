import gql from "graphql-tag";

export const BRAND_LIST_QUERY = gql`
  query allAppuser(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $userType: String
    $search : String
    $orderBy: [String]
    $brandNa: Boolean
  ) {
    allAppuser(
      
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      userType: $userType
      search : $search
      orderBy: $orderBy 
      brandNa:$brandNa    
    ) {
      totalCount
      filterCount
      edges {
        node {
          appusersId
          id
          firstName
          lastName
          email
          phone
          newsletter
          city
          country
          userType
          onboardingmodelSet {
            edges {
              node {
                company
              }
            }
          }
          brandlogomodelSet {
            edges {
              node {
                awsFileUrl,
                brandlogoName
              }
            }
          }
          
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DELETE_BRAND_QUERY = gql`
  mutation deleteAppUser($appusersId: Int!) {
    deleteAppUser(input: { appusersId: $appusersId }) {
        appusersId
    }
  }
`;
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Button,
  Form,
  Space,
  Upload,
  Input,
  Divider,
  Spin,
} from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData, ImageUploadValidation } from "../../../utils/Helpers";
import {
  GET_COMPANY_DETAIL_QUERY,
  UPDATE_COMPANY_DETAIL_QUERY,
  GET_COMPANY_LOGO,
  UPLOAD_COMPANY_LOGO,
} from "./ProfileQuery";

import "../dashboard/Dashboard.scss";
import "./SellerPages.scss";
import companylogo from "../../../images/company-noimg.png";

const { TextArea } = Input;

class EditCompany extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    ImageLoading: false,
    isEditMode: false,
    authonboardingId: authUserData().onboardingId,
    authUserId: authUserData().appusersId,
    companyLogoImg: companylogo,
  };
  formRef = React.createRef();

  componentDidMount() {
    this.getCompanyDetail(authUserData().onboardingId);
    this.getCompanyLogo(authUserData().appusersId);
  }

  onFinish = async (values) => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    await client
      .mutate({
        mutation: UPDATE_COMPANY_DETAIL_QUERY,
        variables: this.state,
      })
      .then((response) => {
        SuccessNotificationMsg(
          "Success!",
          "Company detail updated successfully."
        );
        this.setState({ btnLoading: false, isEditMode: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  setPageMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  getCompanyDetail = (onboardingId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: { deleted: 0, onboardingPk: onboardingId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allVendors.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allVendors.edges[0].node
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  getCompanyLogo = (userId) => {
    const { client } = this.props;
    client
      .query({
        query: GET_COMPANY_LOGO,
        variables: { deleted: 0, userId: userId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data.allBrandlogos.edges.length > 0) {
          this.setState({
            companyLogoImg:
              response.data.allBrandlogos.edges[0].node.thumbAwsurl,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  beforeUpload = (file) => {
    let errorMsg = ImageUploadValidation(file);
    if (errorMsg !== "") {
      ErrorNotificationMsg(errorMsg);
      return false;
    }
  };

  uploadCompanyLogo = ({ file, onSuccess, onError }) => {
    if (file.size > 5242880) {
      ErrorNotificationMsg("Maximum size for file upload is 5MB.");
      return false;
    }
    const { client } = this.props;
    this.setState({ ImageLoading: true });
    client
      .mutate({
        mutation: UPLOAD_COMPANY_LOGO,
        variables: { userId: authUserData().appusersId, brandLogoFile: file },
      })
      .then((response) => {
        this.setState({
          ImageLoading: false,
          companyLogoImg: response.data.uploadBrandLogo.presignedUrl,
        });
        setTimeout(() => {
          onSuccess("done");
        }, 0);
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message.replace("GraphQL error:", ""));
        setTimeout(() => {
          onError(e.message);
        }, 0);
        this.setState({ ImageLoading: false });
      });
  };

  render() {
    const { isEditMode, companyLogoImg, ImageLoading } = this.state;
    const showViewCompanyClass = isEditMode === false ? "" : "hide";
    const showEditCompanyClass = isEditMode === true ? "" : "hide";
    const uploadProps = {
      accept: "images/*",
      listType: "picture-card",
      showUploadList: false,
    };

    return (
      <div className="myprofile">
        <div className="heading">
          <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
            <Col flex="auto">
              <h3>Company</h3>
            </Col>
            <Col flex="auto" align="end">
              <Space>
                <Button
                  type="primary"
                  htmlType="button"
                  size="small"
                  icon={
                    this.state.isEditMode ? (
                      <ArrowLeftOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  onClick={this.setPageMode}
                >
                  {this.state.isEditMode ? "Back" : "Edit"}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div className={showViewCompanyClass}>
          <Spin spinning={this.state.apiLoading}>
            <Row gutter={0} className="prflrow">
              <Col flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={companyLogoImg} alt="brand-logo" />
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadCompanyLogo}
                    >
                      <Button icon={<EditOutlined />}></Button>
                    </Upload>
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Company</label>
                    <Form.Item>{this.state.company}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Website</label>
                    <Form.Item>{this.state.website}</Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={24}>
                    <label>About Brand</label>
                    <Form.Item>{this.state.aboutBrand}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <label>Remarks</label>
                    <Form.Item>{this.state.remarks}</Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>

        <div className={showEditCompanyClass}>
          <Form onFinish={this.onFinish} ref={this.formRef}>
            <Row gutter={0} className="prflrow">
              <Col flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={companyLogoImg} alt="brand-logo" />
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadCompanyLogo}
                    >
                      <Button icon={<EditOutlined />}></Button>
                    </Upload>
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Company *</label>
                    <Form.Item
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: "Please input company!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("company")}
                        placeholder="Enter company"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Website</label>
                    <Form.Item name="website">
                      <Input
                        onChange={this.handleInputChange("website")}
                        placeholder="Enter website"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={24}>
                    <label>About Brand *</label>
                    <Form.Item
                      name="aboutBrand"
                      rules={[
                        {
                          required: true,
                          message: "Please input about brand!",
                        },
                      ]}
                    >
                      <TextArea
                        onChange={this.handleInputChange("aboutBrand")}
                        placeholder="Enter about brand"
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <label>Remarks</label>
                    <Form.Item name="remarks">
                      <TextArea
                        onChange={this.handleInputChange("remarks")}
                        placeholder="Enter remarks"
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row
              gutter={[15]}
              align="end"
              justify="space-between"
              className="bottomButtons"
            >
              <Col></Col>
              <Col>
                <Space>
                  {/* <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick={this.setPageMode}
                  >
                    BACK
                  </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={this.state.btnLoading}
                  >
                    SAVE
                  </Button>
                </Space>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </div>
    );
  }
}

export default withApollo(EditCompany);

import React, { Component } from "react";
import { Col, Row, Checkbox, Button, Collapse, Spin } from "antd";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  PlusCircleOutlined,
  HeartOutlined,
  HeartFilled,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ProductLike from "./ProductLike";
import {
  cleanEdgesNode,
  ShowTextByCharLength,
  GetClientOriginDetails,
} from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

import { ADD_LOG_QUERY } from "../admin/log/LogQuery";
import { PRODUCT_COMPARE_QUERY } from "../brand/product/ProductQuery";

import noimg from "../../images/noimg.png";
import productDefaultImg from "../../images/noimg-product.webp";
import CO21 from "../../images/CO21.png";
import water1 from "../../images/water1.png";
import distance1 from "../../images/distance1.png";

const { Panel } = Collapse;
export class ProductCompare extends Component {
  state = {
    loading: false,
    productIdArr: [],
    data: [],
  };

  componentDidMount() {
    let productIdArr = this.props.location.state
      ? this.props.location.state.products
      : [];

    if (productIdArr.length > 0) {
      this.fetchProducts(productIdArr);
    }
    this.storeLog();
  }

  fetchProducts = (productIdArr) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_COMPARE_QUERY,
        variables: { productlist: productIdArr },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          data: result.data.allProduct.edges,
          loading: false,
          productIdArr,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  storeLog = () => {
    GetClientOriginDetails().then((result) => {
      this.callSaveLogAPI(result, "PRODUCT_DETAIL");
    });
  };

  callSaveLogAPI = (result, logType) => {
    this.props.client.mutate({
      mutation: ADD_LOG_QUERY,
      variables: {
        ipAddress: result.ip.ip,
        country: result.ip.country,
        url: window.location.href,
        updatedBy: 1,
        logType,
      },
    });
  };

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  addProductToCompare = () => {
    this.props.history.push({
      pathname: "/products",
      state: { compareProductArr: this.state.productIdArr },
    });
  };

  removeProductToCompare = (productId) => {
    this.setState({
      data: this.state.data.filter((s) => productId !== s.node.productId),
      productIdArr: this.state.productIdArr.filter((s) => productId !== s),
    });
  };

  goToProduct(productData) {
    this.props.history.push({
      pathname: "/product-detail/" + productData.node.slug,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="compare-product">
          <Spin spinning={this.state.loading}>
            <Row gutter={{ xs: 8, sm: 8 }}>
              <Col xs={4} className="compare_panel">
                <div className="inside">
                  <div className="topdiv">
                    <h3>Compare</h3>
                    <span>(You can add upto 4 products to compare)</span>
                  </div>
                </div>
              </Col>

              {this.state.data.length > 0
                ? this.state.data.map((product, index) => {
                    return (
                      <Col xs={5} key={index}>
                        <div className="prowrap">
                          <div className="comp_wrp">
                            <div
                              className="imagdiv"
                              onClick={() => this.goToProduct(product)}
                            >
                              <LazyLoadImage
                        width="100%"
                        height="auto"
                                alt={"prod-img-" + index}
                                src={this.getProductCoverImage(
                                  product.node.productfilesmodelSet
                                )}
                              />
                            </div>
                            <Link
                              to="#"
                              onClick={() =>
                                this.removeProductToCompare(
                                  product.node.productId
                                )
                              }
                              className="closecompare"
                            >
                              <CloseCircleOutlined />
                            </Link>
                            <div className="comp_desp">
                              <div className="Description">
                                <h3>
                                  <span
                                    onClick={() => this.goToProduct(product)}
                                  >
                                    {product.node.productName}
                                  </span>
                                  <Checkbox className="favrout">
                                    <HeartOutlined className="nonfill" />
                                    <HeartFilled className="fill" />
                                  </Checkbox>
                                  <ProductLike
                                    productId={product.node.productId}
                                  />
                                </h3>
                              </div>
                              <div
                                className="price"
                                onClick={() => this.goToProduct(product)}
                              >
                                € {product.node.price}
                              </div>
                              <ul className="iconlist">
                                <li>
                                  <div className="icondiv">
                                    <img alt="icon" src={CO21}></img>
                                    <div className="icon_txt">kg CO2</div>
                                  </div>
                                </li>
                                <li>
                                  <div className="icondiv">
                                    <img alt="icon" src={water1}></img>
                                    <div className="icon_txt">Liter</div>
                                  </div>
                                </li>
                                <li>
                                  <div className="icondiv">
                                    <img alt="icon" src={distance1}></img>
                                    <div className="icon_txt">Km</div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="comp_size_wrp">
                            {product.node.impactsizemodelSet &&
                              product.node.impactsizemodelSet.edges.length >
                                0 &&
                              cleanEdgesNode(
                                product.node.impactsizemodelSet,
                                "attributeValue.sortCol",
                                "asc"
                              ).content.map((item, index) => (
                                <ul className="iconvalue" key={index}>
                                  <li className="size-label">
                                    {item.attributeValue.value}
                                  </li>
                                  <li>{item.kgco2}</li>
                                  <li>{item.liter}</li>
                                  <li>{item.kmtravel}</li>
                                </ul>
                              ))}
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : ""}

              {this.state.data.length < 4 ? (
                <Col xs={5}>
                  <div className="prowrap">
                    <div className="noimage">
                      <img alt="no-img" src={noimg}></img>
                      <Button
                        type="link"
                        onClick={() => this.addProductToCompare()}
                      >
                        <PlusCircleOutlined />
                      </Button>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col xs={5}></Col>
            </Row>

            <Collapse collapsible="header" defaultActiveKey={["1"]}>
              <Panel header="Impact Details" key="1">
                <Row gutter={{ xs: 8, sm: 8 }}>
                  <Col xs={4} className="compare_panel">
                    <div className="inside">
                      <div className="bottomdiv">
                        <ul>
                          <li className="green">Short Description</li>
                          <li className="green">Bio Based Material</li>
                          <li className="green">Long Lifetime Material</li>
                          <li className="green">Refurbished Material</li>
                          <li className="green">Recycled Material</li>
                          <li className="green">Vegan</li>
                          <li className="green">Second Life Possible</li>
                          <li className="green">Organic Fiber</li>
                          <li className="green">Social Impact</li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  {this.state.data.length > 0
                    ? this.state.data.map((product, index) => {
                        return (
                          <Col xs={5} key={index}>
                            <div className="prowrap">
                              <ul className="tablelist">
                                <li className="descp">
                                  {product.node.shortDescription}
                                </li>

                                <li
                                  className={
                                    product.node.biobasedMaterial
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.biobasedMaterial ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.refurbishedMaterial
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.refurbishedMaterial ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.reusedMaterial
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.reusedMaterial ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.recycledMaterial
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.recycledMaterial ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.vegan ? "green" : "orange"
                                  }
                                >
                                  {product.node.vegan ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.secondLifePossible
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.secondLifePossible ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li
                                  className={
                                    product.node.organicFiber
                                      ? "green"
                                      : "orange"
                                  }
                                >
                                  {product.node.organicFiber ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CloseOutlined />
                                  )}
                                </li>
                                <li>
                                  {ShowTextByCharLength(
                                    product.node.socialImpact,
                                    200
                                  )}
                                </li>
                              </ul>
                            </div>
                          </Col>
                        );
                      })
                    : ""}

                  {this.state.data.length < 4 ? (
                    <Col xs={5}>
                      <div className="prowrap"></div>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col xs={5}></Col>
                </Row>
              </Panel>
            </Collapse>
          </Spin>
        </div>
      </div>
    );
  }
}

export default withApollo(ProductCompare);

import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Row, Col, Skeleton, Input, Spin } from "antd";
import { debounce } from "lodash";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { GET_COMPANY_LOGO } from "../../brand/common/ProfileQuery";

const BrandLogoSkeleton = () => {
  return (
    <Col xs={24} sm={12} md={8} lg={6}>
      <Skeleton.Image className="skeleton-img" />
    </Col>
  );
};
export class BrandPartner extends Component {
  state = {
    data: [],
    apiLoading: false,
    filter: { deleted: 0 },
  };

  componentDidMount() {
    this.fetchBrandList(this.state.filter);
    this.searchCallback = debounce(function (e) {
      this.searchBrand(e.target.value);
    }, 500);
  }

  fetchBrandList = (params) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: GET_COMPANY_LOGO,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          apiLoading: false,
          data: result.data.allBrandlogos.edges,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchBrand = (searchText) => {
    if (searchText !== "") {
      this.fetchBrandList({ company: searchText, deleted: 0 });
    } else {
      this.fetchBrandList({ deleted: 0 });
    }
    this.setState({ searchText });
  };

  gotoBrandWebsite = (node) => {
    if (
      node.userId.onboardingmodelSet.edges[0].node.website &&
      node.userId.onboardingmodelSet.edges[0].node.website !== "" &&
      node.userId.onboardingmodelSet.edges[0].node.website !== undefined
    ) {
      let website = node.userId.onboardingmodelSet.edges[0].node.website;
      if (!website.match(/^https?:\/\//i)) {
        website = "http://" + website;
      }
      window.open(website, "_blank");
    }
  };

  render() {
    const { data, apiLoading } = this.state;
    return (
      <div className="container">
        <div className="brandpage">
          <div className="pagename">Brands & Partners</div>
          <div className="brand-search-wrap">
            <div className="inside">
              <Input
                placeholder="Search Brand..."
                onChange={this.searchCallbackDelayed}
              />
            </div>
          </div>
          <Spin spinning={this.state.apiLoading}>
            <Row gutter={16}>
              {data.length > 0 ? (
                data.map((brand, index) => {
                  return (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                      <div className="brandwrap">
                        <div
                          className="imgdiv"
                          onClick={() => this.gotoBrandWebsite(brand.node)}
                        >
                          <img alt="redfeet" src={brand.node.awsFileUrl}></img>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : data.length === 0 && apiLoading === false ? (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="not-found">Not found brands & partners</div>
                </Col>
              ) : (
                <BrandLogoSkeleton />
              )}
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

export default withApollo(BrandPartner);

import React, { Component } from "react";
import "./Common.scss";

const titleStyle = {
  fontSize: "150px",
  color: "#A1C9A4",
};

const title2Style = {
  fontSize: "35px",
  color: "#A1C9A4",
  margin: "90px 0 30px 0",
};

export class Notfound extends Component {
  render() {
    return (
      <div className="message-box">
        <h1 style={titleStyle}>404</h1>
        <h1 style={title2Style}>Page not found</h1>
      </div>
    );
  }
}

export default Notfound;
